
import {throwError as observableThrowError, from as fromPromise, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Logger} from "@app/core/logger.service";
import {BrowserService} from '@app/shared/service/browser.service';

const logger = new Logger('App');

export enum CacheState {
    Waiting = "WAITING",
    Caching = "CACHING",
    Cached = "CACHED",
    NoContentToCache = "NO_CONTENT",
    NotCompatible = "NOT_COMPATIBLE",
    Failed = "FAILED"
}

@Injectable()
export class ContentCacheService {

    serviceWorker: ServiceWorkerRegistration;
    serviceWorkerCompatible : boolean = false;

    constructor(
        private http: HttpClient,
        private _browserService: BrowserService
    ) {

       // if (caches == undefined || !navigator.serviceWorker) {
        if (typeof caches == 'undefined' || !navigator.serviceWorker || this._browserService.isIos() || this._browserService.isIE()) {
            logger.info('Cache Storage not found ! Caching workflow will be delegated to the browser.');
            return;
        }

        logger.info('Cache Storage is compatible.');

        this.serviceWorkerCompatible = true;

        navigator.serviceWorker.register('/assets/sw.js').then((serviceWorkerRegistration: ServiceWorkerRegistration) => {
            this.serviceWorker = serviceWorkerRegistration;

        }).catch((err) => {
            console.error(err)
        });
    }

    public cacheRessources(contentId: number, ressources: string[]): Observable<any> {

      if (!this.serviceWorkerCompatible) {
            return observableThrowError('Cache Storage not compatible with your browser.');
        }

        return fromPromise(
            new Promise((resolve: any, reject: any) => {
                // Creating a hidden iframe in order to cache content through it
                // Without passing by an iframe, there is a failure because Angular replaces browser's global Promise implementation by it's own ZoneAwarePromise to keep track of promises.
                // But, CacheStorage somehow expects a native Promise, not a Angular's ZoneAwarePromise
                // Plus, the former cache implementation that uses service worker to cache, sometimes fails when no active serviceWorker was not found (obviously due to the service Worker lifecycle)
                const hiddenFrame = document.createElement('iframe');
                hiddenFrame.style.display = 'none';
                document.documentElement.appendChild(hiddenFrame);
                hiddenFrame.contentWindow.caches.open('kis_content_' + contentId)
                    .then(cache => cache.addAll(ressources))
                    .then((data) => {
                        document.documentElement.removeChild(hiddenFrame);
                        resolve(data);
                    })
                    .catch(err => {
                        console.log('error while caching', err);
                        reject(err);
                    });
                /*let msg_chan = new MessageChannel();

                msg_chan.port1.onmessage = (event)=>{
                    if(event.data.error){
                        reject(event.data.error);
                    }else{
                        resolve(event.data);
                    }
                };

                this.serviceWorker.active.postMessage({id: 'cache_content', contentId: contentId, documents: ressources}, [msg_chan.port2])*/
            })
        );

    }

    public isCached(contentId: number): Observable<any> {
        const cache_key = 'kis_content_';
        return fromPromise(
            new Promise((resolve: any, reject: any) => {
                caches
                    .has(cache_key + contentId)
                    .then(
                        (hasCache: boolean) => {
                            resolve(hasCache);
                        },
                        (error: any) => {
                            reject('cache error : ' + error);
                        }
                    );
            })
        );
    }

}