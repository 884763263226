import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {filter} from 'rxjs/operators';
import {ChatMessage} from '@app/core/messaging/chat-message';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {MessageInterface} from '@app/core/messaging/message';
import {SessionService} from '@app/shared/service/session.service';
import {AttendeesService} from '@app/shared/service/attendees.service';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from "@app/home/session/presentation/presentation-events.service";
import {Subscription} from "rxjs";
import {UtilService} from '@app/shared/service/util.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

    @Output() onClose = new EventEmitter();
    chatMessage: string;
    chatMessages: ChatMessage[] = [];
    username: string;
    showChat: boolean = false;
    subscriptions: Subscription[] = [];
    @ViewChild('chatScroll') private scrollContainer: ElementRef;

    /**
     * @function constructor
     * @param {MessagingService} messagingService
     * @param {AuthenticationService} authService
     * @param {SessionService} _sessionService
     * @param {AttendeesService} _attendeesService
     */
    constructor(
        private messagingService: MessagingService,
        private authService: AuthenticationService,
        private _presentationActionsService: PresentationEventsService,
        private _sessionService: SessionService,
        private _attendeesService: AttendeesService,
        private _utilService: UtilService
    ) {

        //subscribe to messaging system
        this.subscriptions.push(
            this.messagingService.Messages
                .pipe(filter((message: MessageInterface) => message instanceof ChatMessage))
                .subscribe((message: ChatMessage) => {
                    message.user = _attendeesService.getAttendee(message.username);
                    this._utilService.setUserChatNickName(message).subscribe( (data: string) => {
                      message.nickname = data;
                    });
                    this.chatMessages.push(message);
                    this.scrollToBottom();
                })
        );

        this.subscriptions.push(
            this._presentationActionsService.actionRequests.subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.ChatClose:
                            this.showChat = false;
                            this._presentationActionsService.notifyChatVisibiltyChanged(false);
                            break;
                        case PresentationEvent.ChatDisplay:
                            this.showChat = true;
                            this._presentationActionsService.notifyChatVisibiltyChanged();
                            break;
                    }
                }
            )
        );

        this.username = this.authService.credentials.username;
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
    }

    ngOnDestroy() {
        this.subscriptions.forEach(
            (subscription: Subscription) => subscription.unsubscribe()
        );
    }

    /**
     * @function scrollToBottom
     * @description scroll To Bottom
     * @public
     * @returns {void}
     */
    public scrollToBottom(): void {
        if (!this.showChat)
            return;

        try {
            setTimeout(() => {
                this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
            }, 500);
        } catch (err) {
        }
    }

    /**
     * @function sendMessage
     * @description send message
     * @private
     * @returns {void}
     */
    public sendMessage(): void {
        if (!this.chatMessage) {
            return;
        }

        let message = {
            ...new ChatMessage(),
            type: 'groupchat',
            body: `{ "ChatMessage": { "content": "${this.chatMessage}",
        "senderJID": "${this.authService.credentials.username}"}}`
        };

        this.messagingService.sendMessage(message);
        this.chatMessage = null;
    }

    /**
     * @function closeChat
     * @description close chat
     * @public
     * @returns {void}
     */
    public closeChat(): void {
        this.showChat = false;
        this._presentationActionsService.notifyChatVisibiltyChanged(false);
    }

    public isVertical() {
        return window.innerHeight > window.innerWidth;
    }

    /**
     * @function chatTextTop
     * @description
     * @public
     * @returns {void}
     */
    public chatTextTop(): void {
        document.getElementsByClassName('chat-container')[0].classList.add('chat-text-top');
        // get back the window to the top. (the iphone move the content when keyboard is open)
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
    }

    /**
     * @function chatTextNormal
     * @description
     * @public
     * @param {any} $event
     * @returns {void}
     */
    public chatTextNormal($event: any): void {
        document.getElementsByClassName('chat-container')[0].classList.remove('chat-text-top');
    }

}
