import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {User} from '@app/shared/models/user';
import {HeaderService} from '@app/core/shell/header/header.service';
import {FooterService} from '@app/core/shell/footer/footer.service';
import {UserService} from '@app/shared/service/user.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    @Output() backToSettingEvent = new EventEmitter();
    public user: User;
    public currentCountry: string;
    public contactBySms: boolean;
    private _translations: string[] = [];

    /**
     * @function constructor
     * @param {AuthenticationService} _authService
     * @param {HeaderService} _headerService
     * @param {FooterService} _footerService
     * @param {UserService} _userService
     */
    constructor(
        private _authService: AuthenticationService,
        private _headerService: HeaderService,
        private _footerService: FooterService,
        private _userService: UserService,
        private _translateService: TranslateService
    ) {
        this._initTranslations();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        let currentLanguage = this._translateService.currentLang.replace(/^.*-/, '');
        let defaultLanguage = this._translateService.defaultLang.replace(/^.*-/, '');
        currentLanguage = currentLanguage.toLowerCase();
        defaultLanguage = defaultLanguage.toLowerCase();

        this._headerService.changeTitle(this._translations['my_profile']);
        this._footerService.changeVisibility(false);

        this._userService.me().subscribe((user: any) => {
            this.user = user;
            this._userService.getCountryByCode(this.user.phone_country).subscribe(country => {
                // get the country name following the current language
                let selectedCountry = this._findCountryByLanguage(country[0].names, currentLanguage);
                if (selectedCountry) {
                    this.currentCountry = selectedCountry[currentLanguage];
                } else {
                    selectedCountry = this._findCountryByLanguage(country[0].names, defaultLanguage);
                    this.currentCountry = selectedCountry[defaultLanguage];
                }
            });
        });

        this._userService.getUserPreferences().subscribe((preference: any) => {
            if (!preference.length) {
                this._createSMSPreference().subscribe((data: any) => {
                    this._userService.getUserPreferences().subscribe((pref: any) => {
                        // Should be false, as the SMS preference did not exist right before, and just set to false
                        this.contactBySms = pref[0].value === '1';
                    });
                });
            } else {
                this.contactBySms = preference[0].value === '1';
            }
        }, (error) => console.log(error));
    }

    /**
     * @function _createSMSPreference
     * @description
     * @private
     * @param {boolean} smsValue
     * @returns {void}
     */
    private _createSMSPreference() {
        const smsPreference: any = { key: 'use.sms', scope: 'isharing.notification', value: 0, uid: this._authService.credentials.username };
        return this._userService.createSMSPreferences(smsPreference);
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('My profile')
            .subscribe((trans: string) => this._translations['my_profile'] = trans);
    }

    /**
     * @function _findCountryByLanguage
     * @description get the country name by language
     * @private
     * @param {Array<object>} countryNames
     * @param {string} language
     * @returns {object}
     */
    private _findCountryByLanguage(countryNames: Array<object>, language: string): object {
        return countryNames.find(name => name[language] !== undefined);
    }

    /**
     * @function backToSetting
     * @description
     * @public
     * @returns {void}
     */
    public backToSetting(): void {
        this.backToSettingEvent.emit();
    }

}
