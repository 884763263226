import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';

import {TranslateService} from '@ngx-translate/core';
import {FlashService} from '@app/shared/flash/flash.service';
import {UserService} from '@app/shared/service/user.service';
import {HeaderService} from '@app/core/shell/header/header.service';
import {FooterService} from '@app/core/shell/footer/footer.service';

@Component({
    selector: 'app-update-pwd',
    templateUrl: './update-pwd.component.html',
    styleUrls: ['./update-pwd.component.scss']
})
export class UpdatePwdComponent implements OnInit {

    @Output() backToSettingEvent = new EventEmitter();

    private _translations: string[] = [];
    public pwdForm: FormGroup;

    /**
     * @function Constructor
     * @param {Router} _router
     * @param {HeaderService} _headerService
     * @param {FooterService} _footerService
     * @param {FormBuilder} _fb
     * @param {UserService} _userService
     * @param {TranslateService} _translateService
     * @param {FlashService} _flashService
     */
    constructor(
        private _headerService: HeaderService,
        private _footerService: FooterService,
        private _fb: FormBuilder,
        private _userService: UserService,
        private _translateService: TranslateService,
        private _flashService: FlashService
    ) {
        this._initTranslations();
        this.pwdForm = this._fb.group({
            pwdOld: [null],
            pwdNew1: [null],
            pwdNew2: [null]
        });
    }


    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._headerService.changeTitle(this._translations['change_password']);
        this._footerService.changeVisibility(false);
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Change password')
            .subscribe((trans: string) => this._translations['change_password'] = trans);
        this._translateService.get('Your password has been successfully updated')
            .subscribe((trans: string) => this._translations['update_pwd_success'] = trans);
        this._translateService.get('An error occured during the update')
            .subscribe((trans: string) => this._translations['update_pwd_error'] = trans);
    }

    /**
     * @function backToProfile
     * @description
     * @public
     * @returns {void}
     */
    public backToProfile(): void {
        this.backToSettingEvent.emit();
    }

    /**
     * @function updatePwd
     * @description
     * @public
     * @returns {void}
     */
    public updatePwd(): void {
        const pwdOld = this.pwdForm.get('pwdOld').value;
        const pwdNew1 = this.pwdForm.get('pwdNew1').value;
        const pwdNew2 = this.pwdForm.get('pwdNew2').value;
        const data = {
            current_password: pwdOld,
            new_password: pwdNew2
        };
        if (pwdOld && pwdOld !== '' && pwdNew1 && pwdNew1 !== '' && pwdNew2 && pwdNew2 !== '' && pwdNew2 === pwdNew1) {
            this._userService.updatePwd(data)
                .subscribe(res => {
                    this.pwdForm.get('pwdNew2').markAsPristine();
                    this.pwdForm.setValue({
                        pwdOld: null,
                        pwdNew1: null,
                        pwdNew2: null
                    });
                    this._flashService.success(this._translations['update_pwd_success']);
                }, error => {
                    this._flashService.error(this._translations['update_pwd_error']);
                });
        }
    }

    /**
     * @function checkPasswords
     * @description
     * @public
     * @returns {void}
     */
    public checkPasswords(): void {
        const pwdNew1Val = this.pwdForm.get('pwdNew1').value;
        const pwdNew2 = this.pwdForm.get('pwdNew2');
        const pwdNew2Val = pwdNew2.value;
        if (pwdNew2Val === pwdNew1Val && pwdNew1Val !== '' && pwdNew2Val !== '') {
            pwdNew2.setErrors(null);
        } else {
            pwdNew2.setErrors({ 'incorrect': true });
        }
    }

}
