import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {merge} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

import {environment} from '@env/environment';
import {I18nService} from "@app/core/i18n.service";
import {Logger} from "@app/core/logger.service";
import {BrowserService} from '@app/shared/service/browser.service';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostListener('window:resize', ['$event'])
    onResize() {
        this._updateDeviceClassnames();
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private _browserService: BrowserService
    ) {
        this._updateDeviceClassnames();
    }

    /**
     * @function _updateDeviceClassnames
     * @description
     * @private
     * @returns {void}
     */
    private _updateDeviceClassnames() {
      const classlist = document.body.classList;
      if (this._browserService.isMobile() || (!this._browserService.isMobile() && document.body.clientWidth <= 823)) {
          classlist.add('mobile');
          classlist.remove('desktop');
      } else if (!this._browserService.isMobile() && document.body.clientWidth > 823) {
          classlist.remove('mobile');
          classlist.add('desktop');
      }
    }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }
    log.debug( 'init');
    log.info(environment.version);

    // this._sdkService.injectScript();

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

}
