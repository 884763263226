import {
    AfterContentInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnChanges,
    OnDestroy,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

@Component({
    selector: 'app-component-wrapper',
    template: `<div #target></div>`
})
export class ComponentWrapperComponent implements AfterContentInit, OnDestroy, OnChanges {

    @ViewChild('target', { read: ViewContainerRef }) target: any;
    @Input() component: any;

    private cmpRef: ComponentRef<any>;
    private isViewInitialized: boolean;

    constructor(
        private resolver: ComponentFactoryResolver
    ) {}

    updateComponent() {
        if (!this.isViewInitialized) {
            return;
        }
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
        const factory = this.resolver.resolveComponentFactory(this.component);
        this.cmpRef = this.target.createComponent(factory);
        this.cmpRef.instance.isLoaded = false;
    }

    ngOnChanges() {
        this.updateComponent();
    }

    ngAfterContentInit() {
        this.isViewInitialized = true;
        this.updateComponent();
    }

    ngOnDestroy() {
        if (this.cmpRef) {
            this.cmpRef.destroy();
        }
    }
}
