import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '@app/shared/models/user';
import {Country} from '@app/shared/models/country';

@Injectable()
export class UserService {

    constructor(private http: HttpClient) {
    }

    getUser(uid: string): Observable<User> {
        return this.http.get(environment.serverApiUrl + '/users/' + uid)
            .pipe(
                map((body: User) => body)
            );
    }

    me() {
        return this.http.get(environment.serverApiUrl + '/users/me')
            .pipe(
                map((body: User) => body)
            );
    }

    getCountries(): Observable<Country[]> {
        return this.http.get('./assets/data/countries.json')
            .pipe(
                map((countries: Country[]) => countries)
            );
    }

    filterCountriesByLocalLanguage(countries: Country[], localLanguage: string, defaultLanguage: string): Country[] {
        // get the country name out from the names collection following the current language
        countries.forEach(country => country.names.forEach(
            (countryName) => {
                if (countryName[localLanguage]) {
                    country.name = countryName[localLanguage];
                }
            }
        ));

        // sort countries by name
        countries.sort((countryA, countryB) => {
            if (countryA.name.localeCompare(countryB.name) === 1) {
                return 1;
            } else if (countryA.name.localeCompare(countryB.name) === -1) {
                return -1;
            } else {
                return 0;
            }
        });

        return countries;
    }

    getCountryByCode(countryCode: string): Observable<Country[]> {
        return this.http.get('./assets/data/countries.json')
            .pipe(map((countries: Country[]) => countries.filter(country => country.code === countryCode)));

    }

    getUserPreferences(): Observable<any> {
        return this.http.get(environment.serverApiPrefUrl + '/api/v1.0/preference/find/isharing.notification/use.sms')
            .pipe(
                map((preference: any) => preference)
            );
    }

    /**
     * @function saveUserData
     * @description
     * @public
     * @param {any} userData
     * @returns {Observable<any>}
     */
    public saveUserData(userData: any): Observable<any> {
        return this.http.put(environment.serverApiUrl + '/v2/users/me', userData).pipe(
            map((body: any) => body)
        );
    }

    /**
     * @function createSMSPreferences
     * @description
     * @public
     * @param {any} userSMSPref
     * @returns {Observable<any>}
     */
    public createSMSPreferences(userSMSPref: any): Observable<any> {
        return this.http.post(environment.serverApiPrefUrl + '/api/v1.0/preference', userSMSPref).pipe(
            map((body: any) => body)
        );
    }

    /**
     * @function saveUserPreferences
     * @description
     * @public
     * @param {number} preferenceId
     * @param {any} preferences
     * @returns {Observable<any>}
     */
    public saveUserPreferences(preferenceId: number, preferences: any): Observable<any> {
        return this.http.put(environment.serverApiPrefUrl + '/api/v1.0/preference/' + preferenceId, preferences).pipe(
            map((body: any) => body)
        );
    }

    /**
     * @function updatePwd
     * @description
     * @public
     * @param {any} data
     * @returns {Observable<any>}
     */
    public updatePwd(data: any): Observable<any>  {
        return this.http.put(environment.serverApiUrl + '/v2/users/password', data).pipe(
            map((body: any) => body)
        );
    }

    /**
     * @function recoverPwd
     * @description
     * @public
     * @param {any} data
     * @returns {Observable<any>}
     */
    public recoverPwd(data: any): Observable<any>  {
        /* @TODO: To finish !!! */
        return this.http.put(environment.serverApiUrl + '/v2/users/password/recover', data).pipe(
            map((body: any) => body)
        );
    }

}
