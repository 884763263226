import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription ,  merge ,  fromEvent ,  of ,  Observable } from 'rxjs';

import { Session } from '@app/shared/models/session';
import { ContentVersion } from '@app/shared/models/content_version';
import { SessionRoomStatus } from '@app/home/session/session-room-status';

import { MessageInterface } from '@app/core/messaging/message';
import { Presence } from '@app/core/messaging/presence';

import { AuthenticationService, ErrorMessage } from '@app/core/authentication/authentication.service';
import { HeaderService } from '@app/core/shell/header/header.service';
import { MessagingConnectionStatus, MessagingService } from '@app/core/messaging/messaging.service';
import { ContentService } from '@app/shared/service/content.service';
import { ContentCacheService } from '@app/shared/service/content-cache.service';
import { UtilService } from '@app/shared/service/util.service';
import { FlashService } from '@app/shared/flash/flash.service';
import { Logger } from '@app/core/logger.service';

import { XMPPmessage } from '@app/core/messaging-provider/abstract-message-provider';
import { mapTo } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {OpentokService} from '@app/home/session/conference/opentok.service';
import {RelatedService} from '@app/shared/service/related.service';
import {RemoteRelatedFile, RemoteRelatedFileAction} from '@app/core/messaging/remote-related-file';

const logger = new Logger('Home');

declare var Strophe: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

    /**
     * Data members
     */
    private _roomStatus: SessionRoomStatus = SessionRoomStatus.Disconnected;
    private _messagingServiceStatusSubscription: Subscription;
    private _sharedRelatedOpeningSubscription: Subscription;
    private _initConnection: boolean = false;
    public isLoading: boolean;
    public showPresentation: boolean = false;
    public selectedSession: Session = null;
    public connectedToMessagingService: boolean = false;
    public isExternal: boolean = false;
    public errorConnectionToMessagingService: boolean = false;
    public nbLostConnexion: number = XMPPmessage.MAX_RETRY_CONNEXION;
    public online: Observable<boolean>;
    private _presences: any = { count: 0 };
    public isSharedRelatedOpened: boolean;
    public sharedRelatedUrl: string;
    private subscriptions: Subscription[] = [];

    /**
     * @function constructor
     * @param {ContentService} contentService
     * @param {AuthenticationService} authService
     * @param {HeaderService} headerService
     * @param {MessagingService} messagingService
     */
    constructor(
        private _utilService: UtilService,
        private flashService: FlashService,
        private contentService: ContentService,
        private authService: AuthenticationService,
        private headerService: HeaderService,
        private messagingService: MessagingService,
        private _opentokService: OpentokService,
        private _relatedService: RelatedService
    ) {
        this._initNetworkStatus();
        this.authService.isExternalUser().then((bool: boolean) => {
            this.isExternal = bool;
        });

      this.subscriptions.push(
        this.messagingService.Messages
          .subscribe((message: MessageInterface) => {
            switch (message.constructor) {
              case RemoteRelatedFile:
                const remoteFile = <RemoteRelatedFile>message;
                if (remoteFile.action === RemoteRelatedFileAction.CLOSE) {
                  // close shared related notification
                  this.isSharedRelatedOpened = false;
                }
                break;
            }
          })
      );
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.headerService.changeVisibilty(true);
        this._messagingServiceStatusSubscription = this.messagingService.connectionStatusChanged
            .subscribe((data: MessagingConnectionStatus) => {
                    this.connectedToMessagingService = data.connected;
                    this._onMessagingServiceStatusChanged(data);
                }
            );
        this._sharedRelatedOpeningSubscription = this._relatedService.sharedRelatedOpeningSubject
            .subscribe((url: string) => {
                    this.sharedRelatedUrl = url;
                    this._onSharedRelatedOpening();
                }
            );

    }

    ngAfterViewInit() {
        this.messagingService.connect(this.authService.credentials.username);
        this._checkDualLoginAndLogout();
    }

    /**
     * @function _checkDualLoginAndLogout
     * @description
     * @private
     * @returns {void}
     */
    private _checkDualLoginAndLogout() {
        this.messagingService.Messages.subscribe((message: MessageInterface) => {
            if (message.constructor === Presence) {
                if (message.from.indexOf('@conference.') === -1 &&
                    message.from.indexOf(this.authService.credentials.username) !== -1 &&
                    message.to === this.authService.credentials.connection_id
                ) {
                    if (this._presences[message.from] == null) {
                        this._presences[message.from] = Math.round((new Date()).getTime() / 1000);
                        this._presences.count = this._presences.count + 1;
                    }
                    if (this._presences.count > 1) {
                        let loop = 0;
                        let value1: any = null;
                        Object.keys(this._presences).forEach(index => {
                            const value = this._presences[index];
                            if (index !== 'count') {
                                loop++;
                                if (loop === 1) {
                                    value1 = value;
                                } else if (loop === 2) {
                                    const offset = value - value1;
                                    if (offset < 2) {
                                        this._utilService._doLogout('/login');
                                        this._presences = { count: 0 };
                                        // disconnect user from xmpp
                                        this.messagingService.disconnect();
                                        this._opentokService.disable();
                                        setTimeout(() => {
                                          this.authService.errorSubject.next(ErrorMessage.DUAL_LOGIN);
                                        }, 1000);

                                    }
                                }
                            }
                        });
                    }
                }
            }
        });
    }

    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
         this._messagingServiceStatusSubscription.unsubscribe();
         this.messagingService.disconnect();
         this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * @function selectSession
     * @description
     * @public
     * @param {Session} $event
     * @returns {void}
     */
    public selectSession($event: Session): void {
        this.selectedSession = $event;
    }

    /**
     * @function roomStatusChanged
     * @description
     * @public
     * @param {SessionRoomStatus} $event
     * @returns {void}
     */
    public roomStatusChanged($event: SessionRoomStatus): void {
        this._roomStatus = $event;
        if (this._roomStatus === SessionRoomStatus.Disconnected) {
            this.selectedSession = null;
            this.headerService.changeVisibilty(true);
        }
    }

    /**
     * @function _onMessagingServiceStatusChanged
     * @description
     * @private
     * @param {MessagingConnectionStatus} status
     * @returns {void}
     */
    private _onMessagingServiceStatusChanged(status: MessagingConnectionStatus): void {
        this.connectedToMessagingService = status.connected;
        if (!this.connectedToMessagingService) {
            this.errorConnectionToMessagingService = true;
        } else {
            this.errorConnectionToMessagingService = false;
        }
        // disconnected while in session
        // @TODO: Try to connect again to the messanging system and mabe join auto the session
        if (!this.connectedToMessagingService && this.selectedSession) {
            this.selectedSession = null;
            this.headerService.changeVisibilty(true);
        }
    }

    /**
     * @function _onSharedRelatedOpening
     * @description
     * @private
     * @returns {void}
     */
    private _onSharedRelatedOpening(): void {
        this.isSharedRelatedOpened = true;
    }

    /**
     * @function openSharedRelated
     * @description
     * @public
     * @param {string} url
     * @returns {void}
     */
    public openSharedRelated(url: string): void {
        this._relatedService.openInNewTab(url);
        this.isSharedRelatedOpened = false;
    }

    /**
     * @function disconnectFromMessaging
     * @description
     * @public
     * @returns {void}
     */
    public disconnectFromMessaging(): void {
        this.messagingService.disconnect();
    }

    /**
     * @function showHidePresentation
     * @description
     * @public
     * @param {boolean} $event
     * @returns {void}
     */
    public showHidePresentation($event: boolean): void {
        this.showPresentation = $event;
    }

    forceReconnect() {
        this.messagingService.connect(this.authService.credentials.username);
    }

    /**
     * @function _resetConnection
     * @description
     * @private
     * @returns {void}
     */
    private _resetConnection(): void {
        this._initConnection = true;
        this.messagingService.resetConnection(  () => {
                const room = 'r' + this.selectedSession.sessionKey + '@conference.' + this.authService.credentials.tenant;
                this.messagingService.joinChannel(room, this.selectedSession.members[0].memberDN);
                // init the connection boolean
                this._initConnection = false;
            }
        );
    }

    /**
     * @function _initNetworkStatus
     * @description
     * @private
     * @returns {void}
     */
    private _initNetworkStatus() {
        this.online = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );
        this.online.subscribe(isOnline => {
            // Resetting XMPP connection if navigator is online (was offline before) and XMPP connection state is still disconnected
            if (isOnline && !this.messagingService.isConnected()) {
                setTimeout(() => {
                    this._resetConnection();
                }, 5000);
            }
        });
    }
}
