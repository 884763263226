/**
 * Created by sfraoua on 22/02/2018.
 */

export interface MessageInterface {
    type : string;
    from : string;
    to : string;
    body: any;
    url: string;
    error: {
        message: string,
        code: any
    }
}

export class Message implements MessageInterface{
    public type : string;
    public from : string;
    public to : string;
    public body: any;
    public url: string;
    public error: {
        message: string,
        code: any
    };
}
