import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from "@env/environment";
import {HttpClient} from "@angular/common/http";
import {ContentVersion} from "@app/shared/models/content_version";
import {ContentCacheService} from "@app/shared/service/content-cache.service";
import {AuthenticationService} from "@app/core/authentication/authentication.service";

@Injectable()
export class ContentService {

  constructor(private http: HttpClient, private authService : AuthenticationService) { }

  public getContentVersion(contentVersionURI : string): Observable<ContentVersion> {
    return this.http.get(environment.serverApiUrl + "/contentversions/"+contentVersionURI+"/extended").pipe(
        map((data : ContentVersion)=>{
          return data
        })
    );
  }
  public getContentFiles(contentZipUrl : string, version: number, versionId : number): Observable<string[]> {
    let url = environment.unzipContenUrl+'?access_token='+this.authService.credentials.access_token+'&url='+contentZipUrl+'&version='+version+'&version_id='+versionId;

      return this.http.get(url).pipe(
          map((data : string[])=>{
            return data;
          }
      )
    );
  }


}
