/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";
import {SessionState} from "@app/shared/models/session";

export class CommentBox extends Message{
    oid : string;
    versionUrn : string;
    anchor : string;
    text : string;
    owner : string;
    style : CommentBoxColor;
    height : number;
    width : number;
    x : number;
    y : number;
    actionType : CommentBoxActionType;
}

export enum CommentBoxColor {
    Blue = 1,
    Yellow = 2,
    Green = 3,
    Black = 4,
    White = 5,
    WhiteWithOpacity = 6,
    BlackWithOpacity = 7,
    OrangeWithOpacity = 8,
}

export enum CommentBoxActionType {
    Create = "create",
    Delete = "delete"
}