import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Question} from '@app/shared/models/question';
import {Logger} from '@app/core/logger.service';
import {QuestionValues} from '@app/shared/models/question-values';
import {QuestionValuesInterval} from '@app/shared/models/question_values_interval';
import {Answer} from '@app/shared/models/answer';
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';

const logger = new Logger('IntervalQuestionComponent');

@Component({
    selector: 'app-poll-interval',
    templateUrl: './interval.question.component.html',
    styleUrls: ['./interval.question.component.scss']
})
export class IntervalQuestionComponent implements OnInit, AfterViewInit {

    @Input() question: Question;
    @Output() questionAnswerEmitter = new EventEmitter();
    @ViewChild('range') range: ElementRef;

    public rangeValue: number = 0;
    public nbAnswers: number = 0;
    public nbParticipants: number = 0;
    public nbDontKnownAnswers: number = 0;
    public rangeList: Array<any> = [];
    public showAnswers: boolean = false;
    private _RANGE_WIDTH = QuestionValuesInterval.RANGE_WIDTH;
    private _RANGE_STEP = QuestionValuesInterval.RANGE_STEP;
    private _RANGE_SHIFT = QuestionValuesInterval.RANGE_SHIFT;

    /**
     * @function constructor
     */
    constructor(private _presentationActionsService: PresentationEventsService) {}

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        //this.rangeList = Array.from(new Array(this.question.max), (val, index) => index + 1);
        this.rangeList = Array.from({ length: this.question.max + 1 }, (val, index) => index);
    }

    /**
     * @function sendResponse
     * @public
     * @param {any} $event
     * @returns {void}
     */
    sendResponse($event: any): void {
        this._cleanButtonsClassnames();
        this.questionAnswerEmitter.emit(parseInt($event.target.value, 10));
    }

    /**
     * @function _cleanButtonsClassnames
     * @description
     * @private
     * @returns {void}
     */
    private _cleanButtonsClassnames(): void {
        const dontKnowBtnClassList = document.getElementById('dontKnowBtn').classList;
        dontKnowBtnClassList.remove('btn-tertiary');
        dontKnowBtnClassList.add('btn-alt');
    }

    /**
     * @function _updateBtnClassnames
     * @description
     * @private
     * @param {any} elem
     * @returns {void}
     */
    private _updateBtnClassnames(elem: any): void {
        // Cleaning all buttons classnames
        this._cleanButtonsClassnames();

        // Updating active button classnames
        elem.classList.remove('btn-alt');
        elem.classList.add('btn-tertiary');
    }

    /**
     * @function _updateRangeClassnames
     * @description
     * @private
     * @param {number} value
     * @returns {void}
     */
    public _updateRangeClassnames(value: number = 0) {
        const newDigit = value / 10;
        // Removing all potential active class
        for (let i = 0 ; i <= 10 ; i++) {
            const options = document.getElementById('opt' + i).classList;
            options.remove('active');
            options.remove('current');
        }
        // Changing range digit color by adding specific class
        for (let i = 0 ; i <= newDigit ; i++) {
            document.getElementById('opt' + i).classList.add('active');
        }
        // Setting the right digit as th current one by adding specific class
        document.getElementById('opt' + newDigit).classList.add('current');
        // Changing range path color
        this.range.nativeElement.style.backgroundImage = 'linear-gradient(to right, #299ffd ' + value + '%, #e9ecef 0%)';
    }

    /**
     * @function updateRangeStyle
     * @public
     * @param {any} $event
     * @returns {void}
     */
    public updateRangeStyle($event: any): void {
        const value = $event.target.value;
        const newValue = (parseInt(value, 10) * 100 /*- this._RANGE_SHIFT*/) / this.question.max;
        this._updateRangeClassnames(newValue);
    }

    /**
     * @function dontKnow
     * @public
     * @param {any} $event
     * @returns {void}
     */
    public dontKnow($event: any): void {
        this._updateBtnClassnames($event.target);
        this._updateRangeClassnames();
        this.range.nativeElement.value = 0;
        this.questionAnswerEmitter.emit(QuestionValues.UNKNOWN);
    }

    @Input()
    set answers(answers: Array<Answer>) {
        if (answers && answers.length > 0) {
            this.setAnswers(answers);
        }
    }

    /**
     * @function setAnswers
     * @public
     * @param {Answer[]} answers
     * @returns {void}
     */
    public setAnswers(answers: Array<Answer>): void {
        this.nbAnswers = answers.length;
        /* @TODO: Number of participants of the Presentation is not sent by XMPP */

        let rate: number = 0;
        for (const answer of answers) {
            if (answer.value !== QuestionValues.UNKNOWN) {
                rate += answer.value;
            } else {
                this.nbDontKnownAnswers++;
            }
        }
        this.rangeValue = rate / answers.length;
        this.showAnswers = true;
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        // STEP_RANGE_WIDTH width of one step in the range. used to adap the full width according of the number of the steps
        const rangeWidth = (this._RANGE_WIDTH * this.question.max) / this._RANGE_STEP;
        if (this.range) {
            this.range.nativeElement.style.width = rangeWidth + '%';
        }
    }

}
