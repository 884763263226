
export class ScreenSize {

  width: number;
  height: number;
  constructor() {
      this.width = 1024;
      this.height = 768;
  }
}
