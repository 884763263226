import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {I18nService} from '@app/core/i18n.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {HeaderService} from '@app/core/shell/header/header.service';
import {HeaderColor} from '@app/shared/models/header_color';
import {TranslateService} from '@ngx-translate/core';
import {UtilService} from '@app/shared/service/util.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    /**
     * Data members
     */
    @Input() show: boolean = true;
    public title: string = null;
    public hideBackButton: boolean = true;
    public backgroundColor: HeaderColor;
    public hideEditProfile: boolean = true;
    private _bgClass: HeaderColor = HeaderColor.DEFAULT;
    private _translations: string[] = [];
    private _isExternalUSer: boolean;

    /**
     * @function constructor
     * @param {Router} router
     * @param {HeaderService} headerService
     * @param {AuthenticationService} _authenticationService
     * @param {I18nService} i18nService
     * @param {UtilService} _utilService
     */
    constructor(
        private router: Router,
        private headerService: HeaderService,
        private _authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        private i18nService: I18nService,
        private _utilService: UtilService
    ) {
        this.headerService.visibilityChanged.subscribe((data: boolean) => this.show = data);
        this.headerService.titleChanged.subscribe((data: string) => this.title = data);
        this.headerService.backButtonVisibilityChanged.subscribe((data: boolean) => this.hideBackButton = data);
        this.headerService.headerBackgroundColorChanged.subscribe((color: HeaderColor) => this._changeBackgroundColor(color));
        this.headerService.editProfileButtonVisibilityChanged.subscribe((data: boolean) => this.hideEditProfile = !data);
        this._initTranslations();
        this._authenticationService.isExternalUser().then((bool: boolean) => {
            this._isExternalUSer = bool;
        });
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {}

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Are you sure you want to leave the presentation?')
            .subscribe((trans: string) => this._translations['leave_session_confirm'] = trans);
    }

    /**
     * @function setLanguage
     * @description Set a language
     * @public
     * @param {string} language
     * @returns {void}
     */
    public setLanguage(language: string): void {
        this.i18nService.language = language;
    }

    /**
     * @function currentLanguage
     * @description
     * @public
     * @returns {string}
     */
    public get currentLanguage(): string {
        return this.i18nService.language;
    }

    /**
     * @function languages
     * @description
     * @public
     * @returns {string[]}
     */
    public get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }

    /**
     * @function username
     * @description
     * @public
     * @returns {string|null}
     */
    public get username(): string|null {
        const credentials = this._authenticationService.credentials;
        return credentials ? credentials.username : null;
    }

    /**
     * @function backAction
     * @description
     * @public
     * @returns {void}
     */
    public backAction(): void {
      this.headerService.backButtonAction();
    }

  /**
   * @function _changeColor
   * @description
   * @private
   * @param {color} string
   * @returns {void}
   */
   private _changeBackgroundColor(color: HeaderColor) {
      this.backgroundColor = color;
   }


    /**
     * @function setClass
     * @description
     * @private
     * @param {color} string
     * @returns {void}
     */
   public setClass(): HeaderColor {
     if (this.backgroundColor) {
       this._bgClass = this.backgroundColor;
     }
     return this._bgClass;
   }

    /**
     * @function backAction
     * @description
     * @public
     * @returns {void}
     */
    public showEditProdil(): void {
      this.headerService.editProfilButtonAction();
    }

    /**
     * @function isExternalUser
     * @public
     * @description
     * @returns {boolean}
     */
    public isExternalUser(): boolean {
        return this._isExternalUSer;
    }
    /**
     * @function logout
     * @public
     * @description
     * @returns {void}
     */
    public logout(): void {
        if (confirm(this._translations['leave_session_confirm'])) {
            this._utilService._doLogout('/login');
        }
    }

}
