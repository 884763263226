/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";


export class SurveyNotification extends  Message{
  status: string;
  sessionId: number;
  id: number;
  questionID: number;

}
