import {AfterViewInit, Component, HostListener} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {I18nService} from '@app/core/i18n.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';

@Component({
    selector: 'app-grpd',
    templateUrl: './grpd.component.html',
    styleUrls: ['./grpd.component.scss']
})
export class GrpdComponent implements AfterViewInit {

    public CUSTO: any = {};
    public isDesktop: boolean;
    private _translations: string[] = [];

    @HostListener('window:resize', ['$event'])
    onResize() {
        this._resizeGrpdContainer();
    }

    /**
     * @function Constructor
     * @param {TranslateService} _translateService
     * @param {CustoService} _custoService
     * @param {BrowserService} _browserService
     */
    constructor(
        private _i18nService: I18nService,
        private _translateService: TranslateService,
        private _custoService: CustoService,
        private _browserService: BrowserService
    ) {
        if (this._browserService.isDesktop() && document.body.clientWidth > 823) {
            this.isDesktop = true;
        }
        this._initTranslations();
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {
        setTimeout(() => {
            this._resizeGrpdContainer();
        });
        this._initCusto();
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Client\'s privacy policy')
            .subscribe((trans: string) => this._translations['clients_privacy_policy'] = trans);
    }

    /**
     * @function _initCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _initCusto(locale: string = null): void {
        locale = !locale ? this._i18nService.language : locale;
        this.CUSTO['loginPageGrpdTabName'] = this._custoService.getProp('loginPageGrpdTabName', locale) || this._translations['clients_privacy_policy'];
        this.CUSTO['loginPageGrpdText'] = this._custoService.getProp('loginPageGrpdText', locale);
    }

    /**
     * @function displayGrpdKadrige
     * @description
     * @public
     * @returns {void}
     */
    public displayGrpdKadrige(): void {
        const clientGrpdMenu = document.getElementById('client-grpd-menu');
        const kadrigeGrpdMenu = document.getElementById('kadrige-grpd-menu');
        const clientGrpdContent = document.getElementById('client-grpd-content');
        const kadrigeGrpdContent = document.getElementById('kadrige-grpd-content');

        clientGrpdMenu.classList.remove('active');
        kadrigeGrpdMenu.classList.add('active');
        clientGrpdContent.classList.remove('active');
        kadrigeGrpdContent.classList.add('active');
    }

    /**
     * @function displayGrpdClient
     * @description
     * @public
     * @returns {void}
     */
    public displayGrpdClient(): void {
        const clientGrpdMenu = document.getElementById('client-grpd-menu');
        const kadrigeGrpdMenu = document.getElementById('kadrige-grpd-menu');
        const clientGrpdContent = document.getElementById('client-grpd-content');
        const kadrigeGrpdContent = document.getElementById('kadrige-grpd-content');

        clientGrpdMenu.classList.add('active');
        kadrigeGrpdMenu.classList.remove('active');
        clientGrpdContent.classList.add('active');
        kadrigeGrpdContent.classList.remove('active');
    }

    /**
     * @function _resizeGrpdContainer
     * @description
     * @private
     * @returns {void}
     */
    private _resizeGrpdContainer(): void {
        const grpdNav = document.getElementById('grpd-nav');
        const grpdContentWrapper = document.getElementById('grpd-content-wrapper');
        let windowHeight = 0;
        this._browserService.isIos() ? windowHeight = window.document.body.clientHeight : windowHeight = window.innerHeight;
        grpdContentWrapper.style.height = (windowHeight - grpdNav.offsetHeight) + 'px';
    }
}