import {
  AfterViewInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {ContentService} from '@app/shared/service/content.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {CacheState, ContentCacheService} from '@app/shared/service/content-cache.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {Session, SessionAudioType, SessionState, SessionType} from '@app/shared/models/session';
import {ContentVersion} from '@app/shared/models/content_version';
import {Logger} from '@app/core/logger.service';
import {Participant} from '@app/shared/models/participant';
import {Notification} from '@app/core/messaging/notification';
import {SessionService} from '@app/shared/service/session.service';
import {MessageInterface} from '@app/core/messaging/message';
import {finalize} from 'rxjs/operators';
import {Content} from '@app/shared/models/content';
import {Observable, forkJoin, Subject, fromEvent, Subscription, ReplaySubject} from 'rxjs';
import {Presence} from '@app/core/messaging/presence';
import {SessionRoomStatus} from '@app/home/session/session-room-status';
import {LoadSequence, LoadSlide, LoadSlideOld} from '@app/core/messaging/load-slide';
import {environment} from '@env/environment';
import {HeaderService} from '@app/core/shell/header/header.service';
import {Router} from '@angular/router';
import {DisplayRelatedContent, RelatedContentVisibility} from '@app/core/messaging/display-related-content';
import {HideRelatedContent} from '@app/core/messaging/hide-related-content';
import {RelatedContent} from '@app/shared/models/related_content';
import {PollService} from '@app/shared/service/poll.service';
import {Question} from '@app/shared/models/question';
import {Survey} from '@app/shared/models/survey';
import {SurveyNotification} from '@app/core/messaging/survey-notification';
import {PollAnswers} from '@app/core/messaging/poll-answers';
import {AttendeesService} from '@app/shared/service/attendees.service';
import {UserService} from '@app/shared/service/user.service';
import {User} from '@app/shared/models/user';
import {XMPPmessage} from '@app/core/messaging-provider/abstract-message-provider';
import {TranslateService} from '@ngx-translate/core';
import {RevokeAccess} from '@app/core/messaging/revoke-access';
import {FlashService} from '@app/shared/flash/flash.service';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from "@app/home/session/presentation/presentation-events.service";
import {UtilService} from '@app/shared/service/util.service';
import {PresenterControl} from '@app/core/messaging/presenter-control';
import {TouchGesture} from '@app/core/messaging/touch-gesture';
import {DrawAction} from '@app/core/messaging/draw-action';
import {SDK_EVENTS, SdkService} from '@app/shared/service/sdk.service';
import {DynamicContentService} from '@app/shared/service/dynamicContent.service';

import {VideoAction} from '@app/core/messaging/video-action';
import {SessionStatus, statePlayPause} from '@app/core/messaging/session-state';
import {SessionPlayPauseState} from '@app/core/messaging/session-play-pause-state';
import {SlideLoadedEvent} from '@app/core/messaging/slide-loaded-event';
import {InteractiveModeEnableCommand} from '@app/core/messaging/interactive-mode-enable-command';

const logger = new Logger('Session');

@Component({
    selector: 'app-session',
    templateUrl: './session.component.html',
    styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

    @Input() session: Session;
    @Output() onRoomConnectionStatusChange = new EventEmitter<SessionRoomStatus>();
    @Output() onPresentationStatusChange = new EventEmitter<boolean>();

    presentationMessages: ReplaySubject<MessageInterface> = new ReplaySubject<MessageInterface>();

    isLoading: boolean;
    isVertical: boolean = false;
    toggleConference: boolean = false;
    showPresentation: boolean = false;
    contentStatus: CacheState = CacheState.Waiting;
    speakerPresent: boolean = false;
    speaker: Participant;
    room: string;
    roomStatus: SessionRoomStatus = SessionRoomStatus.Disconnected;
    contentsVersions: ContentVersion[] = [];
    question: Question;
    surveyID: number;
    showPoll: Boolean;
    survey: Survey;
    pollAnswers: any = '';
    public loadSlide: MessageInterface;
    public loadSlideOCE: MessageInterface;
    public isExternalUser: boolean = false;
    private _username: string;
    private _translations: string[] = [];
    private subscriptions: Subscription[] = [];
    private initConnection: boolean = false;
    private _currentPath: string;
    public isOCE: boolean = false;
    private sdk: any;
    private _presenterReconnectionTimer: any;
    private _nbSecBeforeLogout: number = 120;
    private _hasPresenterMode: boolean;

    private OCE_CONTENTS_PROXY: string =  '/contentoce/';
    private DOP_CONTENTS_PROXY: string =  '/contents/';
    private LOCAL_ENV_NAME: string = 'local';


  /**
     * @function constructor
     * @param {ContentCacheService} contentCacheService
     * @param {AuthenticationService} _authService
     * @param {ContentService} contentService
     * @param {HeaderService} headerService
     * @param {Router} router
     * @param {MessagingService} _messagingService
     * @param {SessionService} sessionService
     * @param {PollService} pollService
     *
     * @param {DynamicContentService} _dynamicContentService
     */
    constructor(
        private _translateService: TranslateService,
        private contentCacheService: ContentCacheService,
        private _authService: AuthenticationService,
        private contentService: ContentService,
        private headerService: HeaderService,
        private router: Router,
        private _messagingService: MessagingService,
        private flashService: FlashService,
        private sessionService: SessionService,
        private pollService: PollService,
        private _attendees: AttendeesService,
        private _userService: UserService,
        private _presentationActionsService: PresentationEventsService,
        private _utilService: UtilService,
        private _sdkService: SdkService,
        private _dynamicContentService: DynamicContentService
    ) {
        // subscribe to messanging system
        this.subscriptions.push(

            this._messagingService.Messages
                .subscribe((message: MessageInterface) => {
                    switch (message.constructor) {
                        case RevokeAccess:
                            this._revokeAccessReceived(<RevokeAccess>message);
                            break;
                        case Notification:
                            this.notificationReceived(<Notification>message);
                            const notification = <Notification>message;
                            if (notification.sessionId === this.session.id) {
                              this._sdkService.injectScript().then(() => {
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(notification);
                              });
                            }
                            break;
                        case SurveyNotification:
                             this._surveyNotificationReceived(<SurveyNotification>message);
                            break;
                        case PollAnswers:
                             this._pollAnswersReceived(<PollAnswers>message);
                            break;
                        case LoadSlideOld:
                            // prepare url for the SDK JS  Player
                            const data = <LoadSlideOld>message;
                            const path = data.path.replace(/^../, '');
                            if (environment.env === this.LOCAL_ENV_NAME && environment.proxyConfig) {
                              message.url =  document.location.origin + this.DOP_CONTENTS_PROXY + path;
                            } else {
                              message.url = environment.contentUrl + '/' + path;
                            }
                            this.sdk.getPlayerJS().eventDispatcher(<LoadSlide>message);
                            break;
                        case LoadSequence:
                            // prepare url for the SDK JS  Player
                            const dataSequenceOCE = <LoadSequence>message;
                            if (environment.env === this.LOCAL_ENV_NAME && environment.proxyConfig) {
                              const url = dataSequenceOCE.sequenceURL.split('/');
                              if (url[2] === 'guest.isharing.dev.kadrige.com') {
                                message.url = document.location.origin + this.DOP_CONTENTS_PROXY + url[4] + '/' + url[5];
                              } else {
                                message.url = document.location.origin + this.OCE_CONTENTS_PROXY + url[4] + '/' + url[5];
                              }

                            } else {
                              message.url = dataSequenceOCE.sequenceURL;
                            }
                            this._sdkService.injectScript().then(() => {
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(<LoadSequence>message);
                            });
                            break;
                        case LoadSlide:
                            // prepare url for the SDK JS  Player
                            const dataSlideOCE = <LoadSlide>message;
                            if (environment.env === this.LOCAL_ENV_NAME && environment.proxyConfig) {
                              const url = dataSlideOCE.slideURL.split('/');
                              message.url = document.location.origin + this.OCE_CONTENTS_PROXY + url[4] + '/' + url[5];
                            } else {
                              message.url = dataSlideOCE.slideURL;
                            }
                            this._sdkService.injectScript().then(() => {
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(<LoadSlide>message);
                            });

                            // Updating drawer
                            this._presentationActionsService.updateDrawerAction(dataSlideOCE);
                            break;
                        case SessionPlayPauseState:
                            const playPauseState = <SessionPlayPauseState>message;
                            // Redirecting participant if the presenter has closed the session properly
                            if (playPauseState.state === statePlayPause.close) {
                                this.logout(true);
                            }

                            // prepare url for the SDK JS  Player
                            // const sessionState = <SessionPlayPauseState>message;
                            this._sdkService.injectScript().then(() => {
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(playPauseState);
                            });
                            break;
                        case SessionStatus:
                            // prepare url for the SDK JS  Player
                            const sessionState = <SessionStatus>message;
                            // console.log(sessionState);

                            // Generating dynamic content
                            this._dynamicContentService.buildOCEDynamicContent(sessionState);

                            // current slide status
                            const currentSlide = sessionState.currentSlide;
                            currentSlide.type = SDK_EVENTS.LOAD_SLIDE;
                            if (environment.env === this.LOCAL_ENV_NAME && environment.proxyConfig) {
                              const url = currentSlide.slideURL.split('/');
                              currentSlide.url = document.location.origin + this.OCE_CONTENTS_PROXY + url[4] + '/' + url[5];
                            } else {
                              currentSlide.url = sessionState.currentSlide.slideURL;
                            }
                            // playPause status
                            const playPauseStatus = new SessionPlayPauseState();
                            playPauseStatus.state = sessionState.state;
                            playPauseStatus.type = SDK_EVENTS.NOTIFICATION;

                            // notification for remote related files
                            const relatedDocuments = sessionState.relatedDocuments;
                            this._presentationActionsService.remoteRelatedContentAction(relatedDocuments);
                            // is chat enable notification
                            const isChatEnabled = sessionState.isChatEnabled;
                            this._presentationActionsService.isChatEnabled(isChatEnabled);

                            // is conference enable notification
                            const isConferenceEnabled = sessionState.isConferenceEnabled;
                            this._presentationActionsService.isConferenceEnabled(isConferenceEnabled);

                            if (sessionState.isInteractiveModeEnabled) {
                              const InteractiveModeMessage = new InteractiveModeEnableCommand();
                              InteractiveModeMessage.type = 'interactive-mode-enable-command';
                              InteractiveModeMessage.isEnabled = sessionState.isInteractiveModeEnabled;
                               this._messagingService.ReplayMessages.next(InteractiveModeMessage);
                            }

                            if (sessionState.presentations) {
                              this._presentationActionsService.sessiondrawerInfo(sessionState.presentations);
                            }

                            if (sessionState.isHighlightingModeEnabled) {
                              this._sdkService.injectScript().then(() => {
                                this._sdkService.sdk.getPlayerJS().eventDispatcher({
                                    type: 'drawing-mode',
                                    isEnabled: sessionState.isHighlightingModeEnabled,
                                    userID: this._authService.credentials.username
                                });
                              });
                            }

                            this._sdkService.injectScript().then(() => {
                              this.startPresentation();
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(<any>currentSlide);
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(<SessionPlayPauseState>playPauseStatus);
                              if (currentSlide.videos) {
                                const video: any = currentSlide.videos[0];
                                const videoAction = new VideoAction();
                                videoAction.currentTime = video.time;
                                videoAction.eventType = video.state;
                                videoAction.index = video.index;
                                videoAction.type = 'video-action';
                                this._sdkService.sdk.getPlayerJS().eventDispatcher(<VideoAction>videoAction);
                              }

                              // Updating drawer
                              setTimeout(() => {
                                this._presentationActionsService.updateDrawerAction(currentSlide);
                              }, 500);
                            });
                            break;
                        case TouchGesture:
                            this._sdkService.injectScript().then(() => {
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(<TouchGesture>message);
                            });
                            break;
                        case DrawAction:
                            this._sdkService.injectScript().then(() => {
                              const msg = <DrawAction>message;
                              const user  = this._attendees.getAttendee(message.body.ie.u);
                              msg.userID = user && user.uid || '';
                              msg.userName = user && user.first_name && user.first_name ? user.first_name + ' ' + user.last_name : (user && user.token_name ? user.token_name : '');
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(msg);
                             });
                            break;
                        case VideoAction:
                            this._sdkService.injectScript().then(() => {
                              this._sdkService.sdk.getPlayerJS().eventDispatcher(<VideoAction>message);
                            });
                            break;
                        default:
                            this._messageReceived(message);
                    }
                })
        );
        this.headerService.changeVisibilty(true);

        this.isVertical = window.innerHeight > window.innerWidth;

        //subscribe to window resize event
        this.subscriptions.push(
            fromEvent(window, 'resize')
                .subscribe((event: Event) => {
                    this.isVertical = window.innerHeight > window.innerWidth;
                })
        );
        this._initTranslations();

        //subscribe to the presentation actions
        this.subscriptions.push(
            this._presentationActionsService.actionRequests.subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.ToWaitingRoom:
                            this.toWaitingRoom();
                            break;
                        case PresentationEvent.askForHandNotification:
                          if (action.data) {
                           this._hasPresenterMode = action.data;
                           // if participant take hand we must init current path (current path can be changed through click)
                           this._currentPath = null;
                          }
                          break;
                        case PresentationEvent.closePoll:
                          this.showPollAction(false);
                          break;
                    }
                }
            )
        )
    }

    /**
     * @function
     */
    ngOnInit() {

        this.isOCE = this.session.sessionType === SessionType.RemoteOCE;
        this._authService.isExternalUser().then((bool: boolean) => {
            this.isExternalUser = bool;
        });
        this.showPoll = false;
        this.headerService.changeTitle(this.session.sessionName);
        this.headerService.backButtonActionChanged.subscribe(() => this.backToSessionList());
        this._username = this._authService.credentials.username;

        // fetch speaker
        this.session.members.forEach((member: Participant) => {
            if (member.role === 'speaker') {
                this.speaker = member;
                this.sessionService.setSpeaker(member);
            }
        });

        // join room
        this.room = 'r' + this.session.sessionKey + '@conference.' + this._authService.credentials.tenant;
        this._messagingService.joinChannel(this.room, this.speaker.memberDN);

        // Loading iAdmin contents for not-OCE sessions
        if (!this.isOCE) {
            this._loadContents();
        }

        // refresh opentokSession (if status changed)
        this.sessionService.getSession(this.session.id)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((data: Session) => this.session = data,
                (err) => console.log(err)
            );

    }

    ngAfterViewInit() {

        this.subscriptions.push(
          // listen to the presence message after receiving the session object in ngOnInit section
          this._messagingService.Messages
            .subscribe((message: MessageInterface) => {
              switch (message.constructor) {
                case Presence:
                  this._presenceReceived(<Presence>message);
                  break;
              }
            })
        );
        this._sdkService.injectScript().then(() => {
          this.sdk = this._sdkService.sdk;
          this.sdk.getPlayerJS().eventObservable.subscribe((message: any) => {
            switch (message.type) {
              case SDK_EVENTS.SLIDE_LOADED_EVENT :
                const slideEvent = new SlideLoadedEvent();
                slideEvent.presentationIdentifier = message.data.presentationIdentifier;
                slideEvent.sequenceIdentifier = message.data.sequenceIdentifier;
                slideEvent.slideName = message.data.slideName;
                this._messagingService.slideLoadedNotification(slideEvent);
                break;
              case SDK_EVENTS.TOUCH_EVENT:
                this._messagingService.sendTouch(message.eventType, message.x, message.y);
                break;
              case SDK_EVENTS.VIDEO_EVENT:
                this._messagingService.sendVideoAction(message.eventType, message.i, message.ct);
                break;
              case SDK_EVENTS.DRAWING_EVENT_NOTIFICATION:
                this._messagingService.sendDrawingAction(message);
                break;
                case SDK_EVENTS.DRAWING_ERASE_NOTIFICATION:
                this._messagingService.sendEraseDrawingAction();
                break;
            }
          });
        });

    }

    /**
     * @description reset connection (
     * @function ngDoCheck
     */
    ngDoCheck() {
        // check if connection is lost accidentally to init connection and join the room again
        if (!this._messagingService.isConnected() && !this.initConnection) {
           this.initConnection = true;
           this._messagingService.resetConnection(  () => {
              const room = 'r' + this.session.sessionKey + '@conference.' + this._authService.credentials.tenant;
              this._messagingService.joinChannel(room, this.speaker.memberDN);
              // init the connection boolean
              this.initConnection = false;
             }
          );
        }
    }


    /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        const sdk = this._sdkService.sdk;
        // Sending a blank page when leaving the session before destroying the component
        // Work around for KIWEB-664 to avoid the last loaded slide from last presentation to appear
        // when accessing the first time in a new presentation (from a new session key)
        // Probably, an issue with ReplaySubject in events.service.ts from JS SDK
        sdk.getPlayerJS().eventDispatcher({ type: 'load-slide', size: { width: 1024, height: 768 }, url: 'about:blank' });

        this.subscriptions.forEach(
            (subscription: Subscription) => subscription.unsubscribe()
        );
        if (sdk && sdk.playerJS && sdk.playerJS.initEventObservable) {
            sdk.playerJS.initEventObservable();
        }
    }

    /**
     * @function logout
     * @description
     * @public
     * @param {boolean} force
     * @returns {void}
     */
    public logout(force: boolean = false): void {
        if (force) {
            this._utilService._doLogout();
        } else if (confirm(this._translations['leave_session_confirm'])) {
            this._utilService._doLogout();
        }
        this._stopCheckingPresenterReconn();
    }



    /**
     * @function startPresentation
     * @public
     * @param
     * @returns {void}
     */
    public startPresentation(): void {
        if (!this.isOCE && this.contentsVersions.length === 0) {
            logger.warn('No contents versions ! Can\'t start the session ! ');
            return;
        }

        if (!this.speakerPresent) {
            logger.warn('Speaker is not present ! Can\'t start the session ! ');
            return;
        }

        if (!this.isOCE && this.session.sessionState !== SessionState.Started) {
            logger.warn('Session not started ! Can\'t go in ! ');
            return;
        }

        if (this.contentStatus === CacheState.Caching) {
            logger.warn('Session not started ! cant cancel caching operation ! ');
            return;
        }

        if (!this._sdkService.sdk) {
          logger.warn('SDK JS not loaded yet ! ');
          return;
        }

        this.showPresentation = true;
        this.onPresentationStatusChange.emit(true);
        setTimeout(() => this._messagingService.askForCurrentSlide(), 500);
    }

    /**
     * @function toWaitingRoom
     * @public
     * @param
     * @returns {void}
     */
    public toWaitingRoom(): void {
        this.showPresentation = false;
        this.headerService.changeVisibilty(true);
        this.onPresentationStatusChange.emit(false);
    }

      /**
       * @function getContentPath
       * @public
       * @param
       * @returns {string}
       */
      public getContentPath(): string {
        return environment.contentUrl;
      }


  /**
     * @function backToSessionList
     * @public
     * @param
     * @returns {void}
     */
    public backToSessionList(): void {
        this.headerService.changeVisibilty(true);
        this._messagingService.leaveRoom(this.room);
        this.roomStatus = SessionRoomStatus.Disconnected;
        this.onRoomConnectionStatusChange.emit(this.roomStatus);
        this._attendees.deleteAttendee(this._username);
        this.sessionService.leaveSession(this.session.id).subscribe(() => {
        });
    }

    /**
     * @function showFullScreenButton
     * @public
     * @param
     * @returns {boolean}
     */
    public showFullScreenButton(): boolean {
        let elem: any = document.getElementById('content-container');

        return typeof elem.requestFullscreen !== 'undefined' || typeof elem.msRequestFullscreen !== 'undefined'
            || typeof elem.mozRequestFullScreen !== 'undefined' || typeof elem.webkitRequestFullscreen !== 'undefined';
    }


    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Are you sure you want to leave the presentation?')
            .subscribe((trans: string) => this._translations['leave_session_confirm'] = trans);
    }

    private _revokeAccessReceived(message: RevokeAccess): void {
        if (message.jidToRevoke !== this._authService.credentials.connection_id) {
            return;
        }

        this._authService.isExternalUser().then((bool: boolean) => {
            if (bool && message.from) {
                this._authService.logout().subscribe(
                    () => {
                        this.router.navigate(['/login'], {replaceUrl: true}).then(
                            (data) => this.flashService.error(message.reason)
                        );
                    },
                    (err) => console.error(err)
                );
            } else {
                this.flashService.error(message.reason);
                this.backToSessionList();
            }
        });
    }

    /**
     * @description When room send a presence message
     * @function _presenceReceived
     * @private
     * @param {Presence} message
     * @returns {void}
     */
    private _presenceReceived(message: Presence): void {
        if (message.error) {
            // todo check what we have to do in case of error
            return;
        }

        // we received succeffully a message, so we are in
        if (this.roomStatus !== SessionRoomStatus.Connected) {
            this.roomStatus = SessionRoomStatus.Connected;
            this.onRoomConnectionStatusChange.emit(this.roomStatus);
        }

        // update list of attendees
        if (message.uid && message.uid.indexOf('api_user') === -1) {
            if (message.type === XMPPmessage.PRESENSE_UNAVAILABLE) {
                this._attendees.deleteAttendee(message.uid);
            } else if (!this._attendees.getAttendee(message.uid)) {
                // this._attendees.setAttendees()
                this._userService.getUser(message.uid)
                    .subscribe((user: User) => {
                        if (!this._attendees.getAttendee(message.uid)) {
                            this._attendees.setAttendees(user);
                        }
                    });
            }
        }

        if (this.speaker) {
            // check if we got a message from speaker
            if (message.from.toLowerCase() === `${this.room}/${this.speaker.memberDN}`) {
              if (message.type === XMPPmessage.PRESENSE_UNAVAILABLE) {
                this.speakerPresent = false;
                if (this.isExternalUser) {
                    // The participant stays in the presentation till the presenter returns
                    // Or is logged out after some presenter absence
                    this._startCheckingPresenterReconnAndLogout();
                } else {
                  this.showPresentation = false;
                  this.headerService.changeVisibilty(true);
                  this.onPresentationStatusChange.emit(false);
                }
              } else {
                // Getting the hand over back to the presenter when he comes back if the participant still got it
                if (this._hasPresenterMode) {
                    this._messagingService.leaveHand();
                }
                this.speakerPresent = true;
                this._stopCheckingPresenterReconn();
                this.startPresentation();
              }
            }
        }
        // TODO send notification only on opening presentation, because we receive multiple type of presence
        // send message to speaker for ready user
        this._messagingService.sendContentState(1);
    }

    /**
     * @function _startCheckingPresenterReconnAndLogout
     * @description
     * @private
     * @returns {void}
     */
    private _startCheckingPresenterReconnAndLogout(): void {
        const nbSecWarningDisplayed = 10;
        const nbSecBeforeWarning = this._nbSecBeforeLogout * 1000 - nbSecWarningDisplayed * 1000;
        this._presenterReconnectionTimer = setTimeout(() => {
            if (!this.speakerPresent) {
                this._translateService.get('Presenter did not return after being disconnected', { x: this._nbSecBeforeLogout }).subscribe((trans: string) => {
                    this.flashService.warning(trans, nbSecWarningDisplayed * 1000, true);
                });
                setTimeout(() => this.logout(true), nbSecWarningDisplayed * 1000);
            }
        }, nbSecBeforeWarning);
    }

    /**
     * @function _stopCheckingPresenterReconn
     * @description
     * @private
     * @returns {void}
     */
    private _stopCheckingPresenterReconn(): void {
        if (this._presenterReconnectionTimer) {
            clearTimeout(this._presenterReconnectionTimer);
        }
    }

    /**
     * @function notificationReceived
     * @public
     * @param {Notification} message
     * @returns {void}
     */
    private notificationReceived(message: Notification): void {
        if (message.sessionId === this.session.id) {
            this.session.sessionState = message.state;

            this.sessionService.getSession(this.session.id).subscribe(
                (session: Session) => {
                    this.session.audioType = session.audioType
                    this.startPresentation();
                },
                (err) => logger.error(err)
            );


            return;
        }
    }

    /**
     * @description notification for poll
     * @function surveyNotificationReceived
     * @private
     * @param {SurveyNotification} message
     * @returns {void}
     */
    private _surveyNotificationReceived(message: SurveyNotification): void {
        if (message.status === Survey.OPENED && message.sessionId === this.session.id) {
            this.pollService.getQuestionData(message.questionID)
                .subscribe((question: Question) => {
                    this.question = question;
                    this.surveyID = message.id;
                    this.showPoll = true;
                });
        }
        else if (message.status === Survey.CLOSED && message.sessionId === this.session.id) {
            this._presentationActionsService.closePollAction();
            this.pollAnswers = null;
        }
        if (message.body.notify.survey.status === Survey.CANCELLED) {
            this.showPoll = false;
            this.pollAnswers = null;
        }
    }

    /**
     * @function _pollAnswersReceived
     * @description show poll answers of all users
     * @private
     * @param {PollAnswers} message
     * @returns {void}
     */
    private _pollAnswersReceived(message: PollAnswers): void {
        if (message.status === Survey.OPENED) {
            this.showPoll = true;
            this.pollAnswers = message.answers;
        }
    }

    /**
     * @function _loadSlideMessageReceived
     * @private
     * @param {LoadSlide} message
     * @returns {void}
     */
    private _loadSlideMessageReceived(message: LoadSequence): void {
        // Stopping loading slide if the slide to load is the same than the actual or outside the presentation
        if (!this.showPresentation) {
            return;
        }
        this.loadSlide = message;
    }

    /**
     * @function _loadSlideOCEMessageReceived
     * @private
     * @param {LoadSlideOCE} message
     * @returns {void}
     */
    private _loadSlideOCEMessageReceived(message: LoadSlide): void {
        // Stopping loading slide if the slide to load is the same than the actual or outside the presentation
        if (!this.showPresentation) {
            return;
        }
        this.loadSlideOCE = message;
    }

    /**
     * @function _messageReceived
     * @private
     * @param {MessageInterface} message
     * @returns {void}
     */
    private _messageReceived(message: MessageInterface): void {
        if (!this.showPresentation) {
            return;
        }
        this.presentationMessages.next(message);
    }

    /**
     * @function _loadContents
     * @private
     * @param
     * @returns {void}
     */
    private _loadContents(): void {
        if (this.session === null) {
            return;
        }

        let contents: Content[] = [];

        // fetch all contents
        this.session.members.forEach((member: Participant) => {
            if (member.role !== 'speaker' && member.role !== 'expert') {
                return;
            }

            if (member.role === 'expert') {
              this._attendees.setExpert(member);
            }

            member.contents.forEach((content: Content) => contents.push(content));
        });

        // one we got all contents, we need to fetch the actual version to download / cache / add the to presentation
        let observables: Observable<ContentVersion>[] = [];

        contents.forEach((content: Content) =>
            observables.push(this.contentService.getContentVersion(content.contentVersionURI)));

        forkJoin(observables).subscribe((data: ContentVersion[]) => {
            this.contentsVersions = data;
            // load first slide if session Scheduled and not receiving an XMPP loadSlide message
            if (this.session.sessionState === SessionState.Scheduled) {
              const message = new LoadSequence();
              const path = this.contentsVersions[0].version_id + this.contentsVersions[0].slides[0].archive_resource_uri;
              message.sequenceURL = document.location.origin + 'contents/' + path;
              this._loadSlideMessageReceived(message);
            }

            this._downloadContents();
        });
    }

    /**
     * @function _downloadContents
     * @private
     * @param
     * @returns {void}
     */
    private _downloadContents(): void {


        if (this.contentsVersions.length === 0) {
            this.contentStatus = CacheState.NoContentToCache;
            logger.error('No contents found ! Can\'t download contents.');
            return;
        }

          this.contentsVersions.forEach((contentVersion: ContentVersion, index) => {
            this.contentService.getContentFiles(
              contentVersion.zip_url,
              contentVersion.version,
              contentVersion.version_id
            ).subscribe((url) => {

              if (this.contentCacheService.serviceWorkerCompatible) {
                this.contentStatus = CacheState.Caching;
                this.contentCacheService.isCached(contentVersion.version_id).subscribe((cached) => {
                  if (cached) {
                    if (index === 0) {
                      this.contentStatus = CacheState.Cached;
                      this.startPresentation();
                    }
                  } else {
                    this.contentCacheService.cacheRessources(contentVersion.version_id, url)
                      .subscribe((result) => {
                        this.contentStatus = CacheState.Cached;
                        if (index === 0) {
                          this.startPresentation();
                        }
                      }, (error: any) => {
                        console.log(error);
                        this.contentStatus = CacheState.Failed;
                      });
                  }
                });
              } else {
                // if browser not support cache, we need to show presentation anyway
                this.contentStatus = CacheState.NotCompatible;
                logger.warn('Not compatible with service worker');
                this.startPresentation();
              }
            }, (error) => {
              logger.warn(error);
              // load presentation even in cache error
              this.startPresentation();
            });
          });


    }



    public showPollAction(value: Boolean): void {
      this.showPoll = value;
    }
}
