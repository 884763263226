import { Session } from '@app/shared/models/session';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchSession'
})
@Injectable()
export class SearchSessionFilterPipe implements PipeTransform {
  transform(items: Session[], value: string): any[] {
    if (!items || !value) {
      return items;
    }

    let str = value.toLocaleLowerCase();
      return items.filter(e => {
        if (!e.hasOwnProperty('members')) { return false; }
        const speaker = e.members.filter(member => member.role === 'speaker')[0];
        const memberDN = speaker.memberDN.toLowerCase();
        let firstName = speaker.first_name.toLowerCase();
        let lastName = speaker.last_name.toLowerCase();

        // clean accents
        const accent_clean = (function () {
          const accent_map_unicode = {
            '224': 'a', '225': 'a', '226': 'a', 227: 'a', '228': 'a', '229': 'a', // a
            '231': 'c',                                                   // c
            '232': 'e', '233': 'e', 'ê': 'e', '234': 'e', '101': 'e',                    // e
            '236': 'i', '237': 'i', '238': 'i', '239': 'i',                     // i
            '241': 'n',                                                   // n
            '242': 'o', '243': 'o', '244': 'o', '245': 'o', '246': 'o', '248': 'o', // o
            '223': 's',                                                   // s
            '249': 'u', '250': 'u', '251': 'u', '252': 'u',                     // u
            '255': 'y',                                                    // y
            '769': ''                                                      // ́
          };
          const accent_map = {
            'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', // a
            'ç': 'c',                                                   // c
            'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'é': 'e',                    // e
            'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',                     // i
            'ñ': 'n',                                                   // n
            'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ø': 'o', // o
            'ß': 's',                                                   // s
            'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u',                     // u
            'ÿ': 'y'                                                    // y
          };

          return function accent_fold(s: string) {
            if (!s) { return ''; }
            let res = '';
            for (let i = 0; i < s.length; i++) {
              if (accent_map_unicode[s.charAt(i).charCodeAt(0)] !== undefined) {
                res += accent_map_unicode[s.charAt(i).charCodeAt(0)];
              } else {
                res += s.charAt(i);
              }
            }
            return res;
          };
        } ());

        str = accent_clean(str);
        const sessionName = accent_clean(e.sessionName);
        firstName = accent_clean(firstName);
        lastName = accent_clean(lastName);


        const regex = new RegExp(str, 'i');
        const firstNameLastName = firstName + ' ' + lastName;
        return regex.test(sessionName)
          || regex.test(firstName)
          || regex.test(lastName)
          || regex.test(firstNameLastName);
      });

  }
}
