import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {User, UserClient} from '@app/shared/models/user';
import {Country} from '@app/shared/models/country';

@Injectable()
export class ClientService {

    constructor(private http: HttpClient) {
    }

}
