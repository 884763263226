import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class VersionService {

    private _version: string;

    constructor(private http: HttpClient) {}

    /**
     * @function getVersion
     * @description
     * @public
     * @returns {Observable<String>}
     */
    public getVersion(): Observable<String> {
        if (this._version) {
            return of(this._version);
        } else {
            return this.http.get('assets/version.txt?t=' + new Date().getTime(), { responseType: 'text' })
                .pipe(
                    map((body: string) => body)
                );
        }
    }

    /**
     * @function setVersion
     * @description
     * @public
     * @param {string} version
     * @returns {void}
     */
    public setVersion(version: string) {
        this._version = version;
    }

}
