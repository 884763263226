/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";

export class DisplayRelatedContent extends Message{
    contentId : any;
    visibility : RelatedContentVisibility;
}

export enum RelatedContentVisibility {
    SHARED = 'shared',
    PRIVATE = 'private',
    PUBLIC = 'related',
    PUBLIC_REMOTE = 'public',
    MANDATORY = 'mandatory'
}
