import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {User} from '@app/shared/models/user';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validator, Validators} from '@angular/forms';
import {UserService} from '@app/shared/service/user.service';
import {Country} from '@app/shared/models/country';
import {TranslateService} from '@ngx-translate/core';
import {isValidNumber} from 'libphonenumber-js';
import {HeaderService} from '@app/core/shell/header/header.service';

export enum PreferenceSMSImage {
    SMSoff = 'assets/img/icons/10Param_smsOff@3x.png',
    SMSon = 'assets/img/icons/10Param_smsOn@3x.png'
}

export function validPhoneNumber(control: AbstractControl): { [key: string]: boolean } | null {

    if (control.parent) {
        if (!isValidNumber(control.value, control.parent.get('countryCode').value)) {
            return {'validPhoneNumber': true};
        }
        return null;
    }
}

@Component({
    selector: 'app-coodinate',
    templateUrl: './coordinate.component.html',
    styleUrls: ['./coordinate.component.scss']
})
export class CoordinateComponent implements OnInit {

    @Input() user: User;
    @Output() backToProfileEvent = new EventEmitter();
    @ViewChild('dialCode') dialCode: ElementRef;

    private _isSMSOn: boolean = false;
    public smsOffOn: string = PreferenceSMSImage.SMSoff;
    public userForm: FormGroup;
    public countries: Array<any>;
    public preference: any;
    public toggleCountryShow: boolean = false;
    public updated: boolean = false;
    private _patchUser: any = {'phone': '', 'email': '', 'phone_country': ''};
    private _translations: string[] = [];

    /**
     * @function constructor
     * @param {FormBuilder} _fb
     * @param {UserService} _userService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _headerService: HeaderService,
        private _fb: FormBuilder,
        private _userService: UserService,
        private _translateService: TranslateService
    ) {
        this._initTranslations();

        this.userForm = this._fb.group({
            email: [null],
            phone: [null],
            countryName: [null],
            countryCode: [null],
            smsPreference: false
        });
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._headerService.changeTitle(this._translations['edit_my_profile']);

        const currentLanguage = this._translateService.currentLang.replace(/^.*-/, '');
        const defaultLanguage = this._translateService.defaultLang.replace(/^.*-/, '');

        // Initializing values
        this._userService.me().subscribe((user) => {
            this._patchUser.email = user.email;
            this._patchUser.phone = user.phone;
            this._patchUser.phone_country = user.phone_country;

            // Deleting the phone call code from the string . the phone call code is added in a separated field
            const phone = user.phone.replace(/\(.*\)/, '');
            this.userForm = this._fb.group({
                email: [user.email, [Validators.required, Validators.pattern('^[a-z0-9._-]+@[a-z0-9._-]+\\.[a-z]{2,6}$')]],
                phone: [phone, [Validators.required, validPhoneNumber]],
                countryName: [''],
                countryCode: [user.phone_country],
                smsPreference: false
            });

            this._userService.getUserPreferences().subscribe((preference: any) => {
                if (!preference.length) {
                    return;
                }
                this.preference = { id: preference[0].id, value: preference[0].value };
                this._isSMSOn = preference[0].value === '1';
                this._setSwitchOffOnSMS(this._isSMSOn);
            }, (error) => console.log(error));

            this._userService.getCountries().subscribe((countries: Country[]) => {
                    this.countries = this._userService.filterCountriesByLocalLanguage(countries, currentLanguage.toLowerCase(), defaultLanguage.toLowerCase());
                    if (this.countries) {
                        const selectedCountry = this.countries.filter((country) => {
                            return country.code === user.phone_country;
                        });
                        if (selectedCountry.length > 0) {
                            this.userForm.get('countryName').setValue(selectedCountry[0].name);
                            this.userForm.get('countryCode').setValue(selectedCountry[0].code);
                            this.dialCode.nativeElement.textContent = selectedCountry[0].dial_code;
                        }
                    }
                }
            );
        });
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Edit my profile')
            .subscribe((trans: string) => {
                this._translations['edit_my_profile'] = trans;
            });
    }

    /**
     * @function switchOffOnSMS
     * @description
     * @public
     * @returns {void}
     */
    public switchOffOnSMS(): void {
        if (this._isSMSOn) {
            this._setSwitchOffOnSMS(false);
        } else {
            this._setSwitchOffOnSMS(true);
        }
    }

    /**
     * @function _setSwitchOffOnSMS
     * @description
     * @private
     * @param {boolean} isOn
     * @returns {void}
     */
    private _setSwitchOffOnSMS(isOn: boolean): void {
        this.smsOffOn = isOn ? PreferenceSMSImage.SMSon : PreferenceSMSImage.SMSoff ;
        this._isSMSOn = isOn;
        this.userForm.get('smsPreference').setValue(isOn);
    }

    /**
     * @function backToProfile
     * @description
     * @public
     * @returns {void}
     */
    public backToProfile(): void {
        this.backToProfileEvent.emit();
    }

    /**
     * @function updateCoordinates
     * @description
     * @public
     * @returns {void}
     */
    public updateCoordinates() {
        this._patchUser.email = this.userForm.get('email').value;
        this._patchUser.phone = '(' + this.dialCode.nativeElement.textContent + ')' + this.userForm.get('phone').value;
        this._patchUser.phone_country = this.userForm.get('countryCode').value;

        this._userService.saveUserData(this._patchUser).subscribe(res => {
            this.updated = true;
        });

        if (this.userForm.get('smsPreference').value != this.preference.value) {
            const smsPreference: any = { key: 'use.sms', scope: 'isharing.notification', value: +this.userForm.get('smsPreference').value };
            this._userService.saveUserPreferences(this.preference.id, smsPreference).subscribe(res => {});
        }
    }

    /**
     * @function toggleCountry
     * @description
     * @public
     * @returns {void}
     */
    public toggleCountry(): void {
        if (this.toggleCountryShow) {
            this.toggleCountryShow = false;
        } else {
            this.toggleCountryShow = true;
        }
    }

    /**
     * @function toggleCountry
     * @description
     * @public
     * @param {country}
     * @returns {void}
     */
    public selectCountry(country: Country) {
        this.toggleCountryShow = false;
        this.userForm.get('countryName').setValue(country.name);
        this.userForm.get('countryCode').setValue(country.code);
        this.userForm.get('phone').setValue('');
        this.dialCode.nativeElement.textContent = country.dial_code;
    }


}
