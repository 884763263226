import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ThanksPageComponent} from './thanks-page.component';
import {extract} from '@app/core/i18n.service';

const routes: Routes = [
  { path: 'exit', component: ThanksPageComponent, data: { title: extract('APP_NAME') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ThanksPageRoutingModule { }
