import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class FooterService {

    /**
     * Data members
     */
    public visibilityChanged: Subject<boolean> = new Subject<boolean>();

    /**
     * @function constructor
     */
    constructor() {}

    /**
     * @function changeVisibility
     * @description
     * @public
     * @param {boolean} isVisible
     * @returns {void}
     */
    changeVisibility(isVisible: boolean): void {
        this.visibilityChanged.next(isVisible);
    }

}