import {NgModule, Optional, SkipSelf} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouteReuseStrategy, RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {I18nService} from "./i18n.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ShellComponent} from "@app/core/shell/shell.component";
import {MessagingService} from "@app/core/messaging/messaging.service";
import {AuthenticationGuard} from "@app/core/authentication/authentication.guard";
import {AuthenticationService} from "@app/core/authentication/authentication.service";
import {AuthInterceptor} from "@app/core/authentication/authentication.interceptor";
import {AbstractMessageProvider} from "@app/core/messaging-provider/abstract-message-provider";
import {XmppProviderService} from "@app/core/messaging-provider/xmpp-providers.service";
import {RouteReusableStrategy} from "@app/core/route-reusable-strategy";
import {HeaderComponent} from "@app/core/shell/header/header.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {XmppStanzaValidatorService} from '@app/core/messaging-provider/xmpp-stanza-validator.service';
import {FooterComponent} from '@app/core/shell/footer/footer.component';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        RouterModule,
        NgbModule,
    ],
    declarations: [
        ShellComponent,
        HeaderComponent,
        FooterComponent
    ],
    providers: [
        MessagingService,
        AuthenticationService,
        XmppStanzaValidatorService,
        AuthenticationGuard,
        I18nService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {provide: AbstractMessageProvider, useClass: XmppProviderService},
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        }
    ]
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // Import guard
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }

}
