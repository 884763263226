import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '@env/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PollResponse} from '@app/shared/models/poll_response';
import {Answer} from '@app/shared/models/answer';
import {Question} from '@app/shared/models/question';


@Injectable()
export class PollService {

  answers: any;
  constructor(private http: HttpClient) { }



  getQuestionData(questionId: number): Observable<Question> {
    return this.http.get(environment.serverUrl + '/voteservice/question/' + questionId)
      .pipe(
        map((res) => res['Question'])
      );
  }

  postResponse(pollResponse: PollResponse, surveyID: number): Observable<Answer>  {

    const body = new HttpParams()
      .set('suerveyid', JSON.stringify(surveyID))
      .set('value', JSON.stringify(pollResponse.value ));

    const httpOptions = {
      headers: new HttpHeaders({
         'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8'
      })
    };

    return this.http.post(environment.serverUrl + '/voteservice/survey/' + surveyID + '/newanswers', body, httpOptions)
      .pipe(
        map((data: any) => { return data['Answers']; })
      );

  }





}
