import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterTitle'
})
export class FilterTitlePipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        function clearText(title: string) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = title;
            return tempDiv.innerText;
        }
        if (!items) { return []; }
        if (!searchText) {
            items.forEach( (elt: any) => {
                elt.title = clearText(elt.title);
            });
            return items;
        }
        searchText = searchText.toLowerCase();
        return items.filter((it: any) => {
            const plainText = clearText(it.title);
            if (plainText.toLowerCase().includes(searchText)) {
                const regEx = new RegExp(searchText, 'gi');
                it.title = plainText.replace(regEx, match => `<span class="highlight">${match}</span>`);
                return it.title;
            }
        });
    }
}