/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";

export class Presence extends Message {
    uid: string;
    public user : {
        jid: string,
        role: string,
        affiliation: string
    }
}
