import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FooterService } from '@app/core/shell/footer/footer.service';

export enum SessionListMenu {
    sessions = 'sessions',
    settings = 'settings'
}

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    /**
     * Data members
     */
    @Input() show = true;
    public title: string = null;
    public activeMenu: string;

    /**
     * @function constructor
     * @param {Router} _router
     * @param {FooterService} _footerService
     */
    constructor(
        private _router: Router,
        private _footerService: FooterService
    ) {
        this.activeMenu = SessionListMenu.sessions;
        this._footerService.visibilityChanged.subscribe((data: boolean) => this.show = data);
        // Resynchronizing the active tab for routes different than "/sessions" when the page is reloaded
        this._syncActiveTab();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {}

    /**
     * @function getMenuImage
     * @description
     * @public
     * @param {string} menuName
     * @returns {any}
     */
    public getMenuImage(): any {
        const sessionsImageOff = 'assets/img/icons/10Param_SessionOff@3x.png';
        const sessionsImageOn = 'assets/img/icons/2SessionList_SessionOn@3x.png';
        const settingsImageOff = 'assets/img/icons/2SessionList_ParamOff@3x.png';
        const settingsImageOn = 'assets/img/icons/10Param_ParamOn@3x.png';
        const pathObj: any = {};
        if (this.activeMenu === SessionListMenu.sessions) {
            pathObj.sessions = sessionsImageOn;
            pathObj.settings = settingsImageOff;
        } else if (this.activeMenu === SessionListMenu.settings) {
            pathObj.sessions = sessionsImageOff;
            pathObj.settings = settingsImageOn;
        }
        return pathObj;
    }

    /**
     * @function toggleMenu
     * @description
     * @public
     * @param {string} menuName
     * @returns {void}
     */
    public toggleMenu(menuName: string): void {
        this.activeMenu = menuName;
    }

    /**
     * @function _syncActiveTab
     * @description
     * @private
     * @returns {void}
     */
    private _syncActiveTab(): void {
        if (this._router.url.includes(SessionListMenu.settings)) {
            this.toggleMenu(SessionListMenu.settings);
        }
    }

}
