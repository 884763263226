import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {FlashService} from '@app/shared/flash/flash.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';

@Component({
    selector: 'app-thanks-page',
    templateUrl: './thanks-page.component.html',
    styleUrls: ['./thanks-page.component.scss']
})
export class ThanksPageComponent implements OnInit {

    public CUSTO: any = {};

    /**
     * Magic trick : used by ngStyle directive in the template to call getBackgroundStyle() repeatedly
     * Also used the in Login page
     */
    @HostListener('window:resize', ['$event'])
    onResize() {}

    /**
     * @function Constructor
     * @param {BrowserService} _browserService
     * @param {Router} _router
     * @param {CustoService} _custoService
     * @param {TranslateService} _translateService
     * @param {FlashService} _flashService
     */
    constructor(
        private _browserService: BrowserService,
        private _router: Router,
        private _custoService: CustoService,
        private _translateService: TranslateService,
        private _flashService: FlashService
    ) {}

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._initCusto();
    }

    /**
     * @function _initCuto
     * @description
     * @private
     * @returns {void}
     */
    private _initCusto(): void {
        this._custoService.custoLoadedSubject.subscribe((loaded: boolean) => {
            this._parseCusto();
        });
    }

    /**
     * @function _parseCusto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _parseCusto(locale: string = null): void {
        this.CUSTO['thanksPageBackgroundColorMobile'] = this._custoService.getProp('thanksPageBackgroundColorMobile', locale);
        this.CUSTO['thanksPageBackgroundImageMobile'] = this._custoService.getProp('thanksPageBackgroundImageMobile', locale);
        this.CUSTO['thanksPageBackgroundPositionMobile'] = this._custoService.getProp('thanksPageBackgroundPositionMobile', locale);
        this.CUSTO['thanksPageBackgroundSizeMobile'] = this._custoService.getProp('thanksPageBackgroundSizeMobile', locale);
        this.CUSTO['thanksPageBackgroundColorDesktop'] = this._custoService.getProp('thanksPageBackgroundColorDesktop', locale);
        this.CUSTO['thanksPageBackgroundImageDesktop'] = this._custoService.getProp('thanksPageBackgroundImageDesktop', locale);
        this.CUSTO['thanksPageBackgroundPositionDesktop'] = this._custoService.getProp('thanksPageBackgroundPositionDesktop', locale);
        this.CUSTO['thanksPageBackgroundSizeDesktop'] = this._custoService.getProp('thanksPageBackgroundSizeDesktop', locale);
        this.CUSTO['thanksPageContainerColorTheme'] = this._custoService.getProp('thanksPageContainerColorTheme', locale);
        this.CUSTO['thanksPageText'] = this._custoService.getProp('thanksPageText', locale);
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

    /**
     * @function _isDesktopView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isDesktopView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return !this._browserService.isMobile() && windowWidth > 823;
    }

    /**
     * @function getBackgroundStyle
     * @description
     * @public
     * @returns {any}
     */
    public getBackgroundStyle(): any {
        const style: any = {};
        // Custo for mobile
        if (this._isMobileView()) {
            if (this.CUSTO['thanksPageBackgroundColorMobile']) {
                style.backgroundColor = this.CUSTO['thanksPageBackgroundColorMobile'];
            }
            // Fallback with desktop data if no mobile background-color available
            else if (this.CUSTO['thanksPageBackgroundColorDesktop']) {
                style.backgroundColor = this.CUSTO['thanksPageBackgroundColorDesktop'];
            }

            if (this.CUSTO['thanksPageBackgroundImageMobile']) {
                style.backgroundImage = 'url(' + this.CUSTO['thanksPageBackgroundImageMobile'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }
            // Fallback with desktop data if no mobile background-image available
            else if (this.CUSTO['thanksPageBackgroundImageDesktop']) {
                style.backgroundImage = 'url(' + this.CUSTO['thanksPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }

            if (this.CUSTO['thanksPageBackgroundPositionMobile']) {
                style.backgroundPosition = this.CUSTO['thanksPageBackgroundPositionMobile'];
            }
            // Fallback with desktop data if no mobile background-position available
            else if (this.CUSTO['thanksPageBackgroundPositionDesktop']) {
                style.backgroundPosition = this.CUSTO['thanksPageBackgroundPositionDesktop'];
            }

            if (this.CUSTO['thanksPageBackgroundSizeMobile']) {
                style.backgroundSize = this.CUSTO['thanksPageBackgroundSizeMobile'];
            }
            // Fallback with desktop data if no mobile background-size available
            else if (this.CUSTO['thanksPageBackgroundSizeDesktop']) {
                style.backgroundSize = this.CUSTO['thanksPageBackgroundSizeDesktop'];
            }
        }
        // Custo for desktop
        else {
            if (this.CUSTO['thanksPageBackgroundColorDesktop']) {
                style.backgroundColor = this.CUSTO['thanksPageBackgroundColorDesktop'];
            }
            if (this.CUSTO['thanksPageBackgroundImageDesktop']) {
                style.backgroundImage = 'url(' + this.CUSTO['thanksPageBackgroundImageDesktop'] + ')';
                style.backgroundRepeat = 'no-repeat';
            }
            if (this.CUSTO['thanksPageBackgroundPositionDesktop']) {
                style.backgroundPosition = this.CUSTO['thanksPageBackgroundPositionDesktop'];
            }
            if (this.CUSTO['thanksPageBackgroundSizeDesktop']) {
                style.backgroundSize = this.CUSTO['thanksPageBackgroundSizeDesktop'];
            }
        }
        return style;
    }
}
