import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Question, QuestionType} from '@app/shared/models/question';
import {Logger} from '@app/core/logger.service';
import {PollResponse} from '@app/shared/models/poll_response';
import {PollService} from '@app/shared/service/poll.service';
import {Answer} from '@app/shared/models/answer';
import {PresentationEventsService} from '@app/home/session/presentation/presentation-events.service';

const logger = new Logger('Poll');

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit {
   @Input() question: Question;
   @Input() surveyID: number;
   @Output() showPoll = new EventEmitter();

   public questionText: string;
   public pollResponse: PollResponse;
   response: Array<number> = [] ;
   public isValid: boolean = false;
   public showConfirmMessage: boolean = false;
   public showConfirmDispatchedResponse: boolean = false;
   public showQuestion: boolean = true;
   public answers: Array<Answer>;

  /**
   * @function constructor
   * @param {PollService} _pollService
   */
   constructor(
       private _pollService: PollService,
       private _presentationActionsService: PresentationEventsService
  ) {}

    /**
     * @function ngOnInit
     */
    ngOnInit() {
      this.questionText = this.question['label'];
    }

    /**
     * @function submitPoll
     * @public
     * @param
     * @returns {void}
     */
    public submitPoll(): void {
        this._pollService.postResponse(this.pollResponse, this.surveyID).subscribe(
          (answer: Answer) => {this.confirmMessage(); },
          (err: any) => console.error(err)
        );
    }

    /**
     * @function confirmMessage
     * @public
     * @param
     * @returns {void}
     */
    public confirmMessage(): void {
        this.showConfirmMessage = true;
        this.showQuestion = false;
    }

    /**
     * @function questionResponse
     * @public
     * @param {number} answer
     * @returns {void}
     */
    public questionResponse(answer: number) {
        this.pollResponse = new PollResponse();
        this.pollResponse.value = [];
        this.pollResponse.surveyid = this.surveyID;
        this.pollResponse.value.push(answer);
        this.isValid = true;
    }

    /**
     *
     * @param {Answer[]} pollAnswers
     */
    @Input('pollAnswers')
    set pollAnswers(pollAnswers: Array<Answer>) {
      if (pollAnswers && pollAnswers.length > 0) {
         this.answers = pollAnswers;
         this.showConfirmMessage = false;
         this.showQuestion = false;
      }
    }

    public closePoll(): void {
      this._presentationActionsService.closePollAction();
    }
}
