import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {


  /**
   * @function constructor
   */
  constructor() { }

  /**
   * @function ngOnInit
   */
  ngOnInit() {
  }


}
