import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home.component';
import {Route} from "@app/core/route.service";
import {extract} from "@app/core/i18n.service";
import {SettingComponent} from '@app/setting/setting.component';

const routes: Routes = Route.withShell([
  { path: '', redirectTo: '/sessions', pathMatch: 'full' },
  { path: 'sessions', component: HomeComponent, data: { title: extract('APP_NAME') } },
  { path: 'settings', component: SettingComponent, data: { title: extract('APP_NAME') } }
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule { }
