
import {Message} from '@app/core/messaging/message';
import {RemoteRelatedFile} from '@app/core/messaging/remote-related-file';
import {CurrentSlide} from '@app/core/messaging/current-slide';


export class SessionStatus extends Message {
  isChatEnabled: boolean;
  isConferenceEnabled: boolean;
  isInteractiveModeEnabled: boolean;
  isHighlightingModeEnabled: boolean;
  state: statePlayPause;
  relatedDocuments: RemoteRelatedFile;
  currentSlide: CurrentSlide;
  presentations: any[];
  customers: any[];
}

export enum statePlayPause {
  play = 'playing',
  pause = 'paused',
  close = 'closed'
}
