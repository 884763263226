import { Injectable } from '@angular/core';
import { DynamicContentOCE } from '@app/shared/models/dynamic_content/dynamic-content-oce';
import { SessionStatus } from '@app/core/messaging/session-state';
import {DynamicContentPresentation} from '@app/shared/models/dynamic_content/presentation';
import {DynamicContentSequence} from '@app/shared/models/dynamic_content/sequence';
import {DynamicContentSlide} from '@app/shared/models/dynamic_content/slide';
import {DynamicContentCustomer} from '@app/shared/models/dynamic_content/customer';

@Injectable()
export class DynamicContentService {

    private _dynamicContent: string;

    constructor() {}

    /**
     * @function dynamicContent
     * @description Setter for dynamicContent
     * @public
     * @param dynamicContent
     * @returns {void}
     */
    set dynamicContent(dynamicContent: any) {
        this._dynamicContent = dynamicContent;
    }

    /**
     * @function dynamicContent
     * @description Getter for dynamicContent
     * @public
     * @returns {any}
     */
    get dynamicContent() {
        return this._dynamicContent;
    }

    /**
     * @function buildOCEDynamicContent
     * @description
     * @public
     * @param {SessionStatus} sessionStatus
     * @returns {void}
     */
    public buildOCEDynamicContent(sessionStatus: SessionStatus): void {
        const dynamicContent = new DynamicContentOCE();
        const indexes: any = this._getPresentationIndex(sessionStatus.presentations, sessionStatus.currentSlide.presentationIdentifier, sessionStatus.currentSlide.sequenceIdentifier, sessionStatus.currentSlide.slideName);
        dynamicContent.setCustomers = this._formatCustomers(sessionStatus.customers);
        dynamicContent.setPresentations = this._formatPresentations(sessionStatus.presentations);
        dynamicContent.setPresentationIndex = indexes.presentationIndex;
        dynamicContent.setSequenceIndex = indexes.sequenceIndex;
        dynamicContent.setSlideIndex = indexes.slideIndex;
        this.dynamicContent = dynamicContent;
    }

    /**
     * @function _getPresentationIndex
     * @description
     * @private
     * @param {any[]} presentations
     * @param {string} presentationIdentifier
     * @returns {number}
     */
    private _getPresentationIndex(presentations: any[], presentationIdentifier: string, sequenceIdentifier: string, slideName: string): number {
        const indexes: any = {};
        for (const [i, presentation] of presentations.entries()) {
            if (presentation.identifier === presentationIdentifier) {
                indexes['presentationIndex'] = i;
            }
            for (const [j, sequence] of presentation.sequences.entries()) {
                if (sequence.identifier === sequenceIdentifier) {
                    indexes['sequenceIndex'] = j;
                }
                for (const [k, slide] of sequence.slides.entries()) {
                    if (slide.name === slideName) {
                        indexes['slideIndex'] = k;
                    }
                }
            }
        }
        return indexes;
    }

    /**
     * @function _formatPresentations
     * @description
     * @private
     * @param {any[]} presentations
     * @returns {DynamicContentPresentation[]}
     */
    private _formatPresentations(presentations: any[]): DynamicContentPresentation[] {
        const newFormatObj: DynamicContentPresentation[] = [];

        // Looping on presentations
        for (const presentation of presentations) {
            const newPresentation: DynamicContentPresentation = {
                id: presentation.identifier,
                externalid: '',
                name: presentation.name,
                sequences: []
            };

            // Looping on sequences
            for (const sequence of presentation.sequences) {
                const newSequence: DynamicContentSequence = {
                    id: sequence.identifier,
                    slides: []
                };

                // Looping on slides
                for (const slide of sequence.slides) {
                    const newSlide: DynamicContentSlide = {
                        name: slide.name
                    };
                    newSequence.slides.push(newSlide);
                }
                newPresentation.sequences.push(newSequence);
            }
            newFormatObj.push(newPresentation);
        }

        return newFormatObj;
    }

    /**
     * @function _formatCustomers
     * @description
     * @private
     * @param {any[]} customers
     * @returns {DynamicContentCustomer[]}
     */
    private _formatCustomers(customers: any[]): DynamicContentCustomer[] {
        const newFormatObj: DynamicContentCustomer[] = [];

        for (const customer of customers) {
            const newCustomer: DynamicContentCustomer = {
                id: '',
                suffix_name: '',
                nationality: '',
                account_type: '',
                lastname: customer.lastName,
                middle_name: '',
                firstname: customer.firstName,
                specialty: '',
                onekeyid: '',
                salutation: '',
                email: '',
                isPrimary: false,
                customertype: ''
            };
            newFormatObj.push(newCustomer);
        }

        return newFormatObj;
    }

}