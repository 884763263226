import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Session, SessionAudioType, SessionState, SessionType} from '@app/shared/models/session';
import {ContentVersion} from '@app/shared/models/content_version';
import {CacheState} from '@app/shared/service/content-cache.service';
import {Logger} from '@app/core/logger.service';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {SessionRoomStatus} from '@app/home/session/session-room-status';
import {Participant} from '@app/shared/models/participant';
import {AbstractMessageProvider} from '@app/core/messaging-provider/abstract-message-provider';
import {HeaderService} from '@app/core/shell/header/header.service';
import {TranslateService} from '@ngx-translate/core';
import {FooterService} from '@app/core/shell/footer/footer.service';
import {OpentokService} from "@app/home/session/conference/opentok.service";
import {I18nService} from '@app/core/i18n.service';
import {CustoService} from '@app/shared/service/custo.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {SdkService} from '@app/shared/service/sdk.service';
import {UtilService} from '@app/shared/service/util.service';
import {LoadSequence} from '@app/core/messaging/load-slide';

const logger = new Logger('Waiting Room');

@Component({
    selector: 'app-waiting-room',
    templateUrl: './waiting-room.component.html',
    styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent implements OnInit, AfterViewInit {
    @Input() session: Session;
    @Input() speaker: Participant;
    @Input() isSpeakerOnline: boolean = false;
    @Input() contentStatus: CacheState = CacheState.Waiting;
    @Input() roomStatus: SessionRoomStatus;
    @Input() contents: ContentVersion[] = [];

    @Output() onStartSession = new EventEmitter<void>();
    @Output() onExit = new EventEmitter<void>();

    @ViewChild('contentInfoContainer') contentInfoContainer: ElementRef;
    @ViewChild('stateContainer') stateContainer: ElementRef;
    @ViewChild('footer') footer: ElementRef;
    public isVertical: boolean;
    private _translations: string[] = [];
    public isOCE: boolean = false;
    public locale: string;
    public thumbnailBgSize: string = 'cover';
    public CUSTO: any = {};
    public isDesktop: boolean;
    public isSdkLoaded: boolean;

    /**
     * @function constructor
     * @param {CustoService} _custoService
     * @param {BrowserService} _browserService
     * @param {AuthenticationService} _authService
     * @param {AbstractMessageProvider} _messageProvider
     * @param {OpentokService} _opentokService
     * @param {HeaderService} _headerService
     * @param {TranslateService} _translateService
     * @param {FooterService} _footerService
     * @param {I18nService} _i18nService
     */
    constructor(
        private _custoService: CustoService,
        private _browserService: BrowserService,
        private _authService: AuthenticationService,
        private _messageProvider: AbstractMessageProvider,
        private _opentokService: OpentokService,
        private _headerService: HeaderService,
        private _translateService: TranslateService,
        private _footerService: FooterService,
        private _i18nService: I18nService,
        private _sdkService: SdkService,
        private _utilService: UtilService
    ) {
        this._initTranslations();
        this.locale = this._i18nService.language;
        this.isVertical = this._windowOrientation();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isVertical = this._windowOrientation();
        this._resizeContentInfoContainer();
        this._initView();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit(): void {
        this._initCuto();
        this._initView();
        this.isOCE = this.session.sessionType === SessionType.RemoteOCE;
        // Forcing OCE mode
        if (this._utilService.forcedStandalone()) {
            this.isOCE = true;
        }
        this._headerService.changeTitle(this._translations['sessions']);
        this._headerService.backButtonVisibility(false);
        this._footerService.changeVisibility(false);
        this._sdkService.injectScript().then(() => {
          this.isSdkLoaded = true;
        });
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit(): void {
        this._resizeContentInfoContainer();
        // this._messageProvider.sendContentState();
    }

    /**
     * @function _initView
     * @description
     * @private
     * @returns {void}
     */
    private _initView() {
        if (this._isMobileView()) {
            this.isDesktop = false;
        } else if (this._isDesktopView()) {
            this.isDesktop = true;
        }
    }

    /**
     * @function _isMobileView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isMobileView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return this._browserService.isMobile() || (!this._browserService.isMobile() && windowWidth <= 823);
    }

    /**
     * @function _isDesktopView
     * @description
     * @private
     * @param {number} windowWidth
     * @returns {boolean}
     */
    private _isDesktopView(windowWidth: number = null) {
        if (!windowWidth) {
            windowWidth = document.body.clientWidth;
        }
        return !this._browserService.isMobile() && windowWidth > 823;
    }

    /**
     * @function _initCuto
     * @description
     * @private
     * @param {string} locale
     * @returns {void}
     */
    private _initCuto(locale: string = null): void {
        if (this.isOCE) {
            this.CUSTO['loginPageLogoUrl'] = this._custoService.getProp('loginPageLogoUrl', locale);
        }
    }

    /**
     * @function startPresentation
     * @public
     * @param
     * @returns {void}
     */
    public startPresentation(): void {
        this.onStartSession.emit();
    }

    /**
     * @function canStartPresentation
     * @public
     * @param
     * @returns {boolean}
     */
    public canStartPresentation(): boolean {
        // Checking speaker presence to enter the session for RemoteOCE sessions only
        if (this.isOCE) {
            return this.roomStatus === SessionRoomStatus.Connected
                && this.isSpeakerOnline && this.isSdkLoaded;
        } else {
            return this.contentStatus !== CacheState.Caching
                && this.roomStatus === SessionRoomStatus.Connected
                && this.session.sessionState === SessionState.Started
                && this.isSpeakerOnline
                && this.isSdkLoaded;
        }
    }

    /**
     * @function backToSessionList
     * @public
     * @param
     * @returns {void}
     */
    public backToSessionList(): void {
        this.onExit.emit();
    }

    /**
     * @function isCaching
     * @public
     * @param
     * @returns {boolean}
     */
    public isCaching(): boolean {
        return (this.contentStatus === CacheState.Caching || this.contentStatus === CacheState.Waiting);
    }

    /**
     * @function cacheIsNotCompatible
     * @public
     * @param
     * @returns {boolean}
     */
    public cacheIsNotCompatible(): boolean {
        return this.contentStatus === CacheState.NotCompatible;
    }

    /**
     * @function contentIsCached
     * @public
     * @param
     * @returns {boolean}
     */
    public contentIsCached(): boolean {
        return this.contentStatus === CacheState.Cached;
    }

    /**
     * @function cacheError
     * @public
     * @returns {boolean}
     */
    public cacheError(): boolean {
        return this.contentStatus === CacheState.Failed;
    }

    /**
     * @function getThumbnail
     * @public
     * @returns {string}
     */
    public getThumbnail(): string {
        if (this.contents.length === 0 || !this._authService.credentials) {
            return 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
        }
        return `${this.contents[0].thumbnail_url}?access_token=${this._authService.credentials.access_token}`;
    }

    /**
     * @function getThumbnail
     * @description
     * @public
     * @returns {void}
     *
     */
    public toggleThumbnailSize(): void {
        this.thumbnailBgSize = this.thumbnailBgSize === 'cover' ? 'contain' : 'cover';
    }

    getConferenceStatus() {
        return this._opentokService.conferenceStatus;
    }

    getTranlatedConferenceStatus() {
        return this._translateService.instant( this._opentokService.conferenceStatus);
    }

    isAllowedToStream() {
        return this._opentokService.isPublishing();
    }

    isStreamingAudio() {
        return this._opentokService.isPublishingAudio();
    }

    isStreamingVideo() {
        return this._opentokService.isPublishingVideo();
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Sessions')
            .subscribe((trans: string) => this._translations['sessions'] = trans);
    }

    /**
     * @function _resizeContentInfoContainer
     * @description
     * @private
     * @returns {void}
     */
    private _resizeContentInfoContainer(): void {
        if (document.getElementById('header')) {
            this._goResizeContentInfoContainer();
        } else {
            // Handling to a timeout because #header and #footer are not accessible right now in ngAfterViewInit
            setTimeout(() => this._goResizeContentInfoContainer(), 300);
        }
    }

    /**
     * @function _goResizeContentInfoContainer
     * @description
     * @private
     */
    private _goResizeContentInfoContainer(): void {
        const headerHeight = document.getElementById('header').offsetHeight;
        const statusContainerHeight = this.stateContainer.nativeElement.offsetHeight;
        const footerHeight = this.footer.nativeElement.offsetHeight;
        this.contentInfoContainer.nativeElement.style.height = (window.innerHeight - headerHeight - statusContainerHeight - footerHeight) + 'px';
    }

    /**
     * @function _windowOrientation
     * @private
     * @param
     * @returns {boolean}
     */
    private _windowOrientation(): boolean {
        return window.innerHeight > window.innerWidth;
    }

    showConferenceStatus() {
        return !!this.session && this.session.audioType === SessionAudioType.Isharing;
    }

    /**
     * @function getButtonStyle
     * @description
     * @private
     * @returns {string}
     */
    public getButtonStyle(): string {
        return this.canStartPresentation() ? 'btn-tertiary' : 'disabled';
    }
}
