import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CommentBox, CommentBoxActionType, CommentBoxColor} from "@app/core/messaging/comment-box";
import {MessagingService} from "@app/core/messaging/messaging.service";
import {MessageInterface} from "@app/core/messaging/message";
import {filter} from "rxjs/operators";
import {Subscription} from "rxjs";
import {SafeResourceUrl} from "@angular/platform-browser";
import {LoadSequence, LoadSlide} from '@app/core/messaging/load-slide';

@Component({
    selector: 'app-comment-box',
    templateUrl: './comment-box.component.html',
    styleUrls: ['./comment-box.component.scss']
})
export class CommentBoxComponent implements OnInit {

    @ViewChild('commentHolder') commentHolder: ElementRef;
    @Input() scaleCss: string;

    private currentSlide: SafeResourceUrl;

    public commentBoxes: CommentBox[] = [];
    public subscriptions: Subscription[] = [];

    private commentBoxPaddings = {
        headerHeight: 39, // 37 + 2
        headerWidthCB: 1, // 2 * 2
        paddingCB: 10, // 2 * 5
        borderCB: 4
    };

    constructor(private messagingService: MessagingService) {
        this.subscriptions.push(
            this.messagingService.Messages
                .pipe(filter((message: MessageInterface) => message !instanceof CommentBox || message !instanceof LoadSlide))
                .subscribe((message: MessageInterface) => {
                    if(message instanceof CommentBox)
                        this.commentBoxeMessageReceived(message);
                    if(message instanceof LoadSequence)
                       this.currentSlide = message.sequenceURL;
                })
        );

    }

    ngOnInit() {
        setTimeout(() => {
            this.commentHolder.nativeElement.style = this.scaleCss;
        }, 500);
    }

    private commentBoxeMessageReceived(commentBox: CommentBox) {

        let index = this.getCommentBoxIndex(commentBox.oid);

        if (index < 0 && commentBox.actionType === CommentBoxActionType.Create) {
            this.commentBoxes.push(commentBox);
            return;
        }

        if (commentBox.actionType === CommentBoxActionType.Delete) {
            this.commentBoxes.splice(index, 1);
            return;
        }

        this.commentBoxes[index] = commentBox;

    }

    private getCommentBoxIndex(commentBoxId: string): number {
        for (let i = 0; i < this.commentBoxes.length; i++) {
            if (this.commentBoxes[i].oid === commentBoxId) {
                return i;
            }
        }

        return -1;
    }

    getCssStyle(commentBox: CommentBox): {} {

        const yCB = Math.round(commentBox.y - commentBox.height / 2 + this.commentBoxPaddings.headerHeight);
        const xCB = Math.round(commentBox.x - commentBox.width / 2 + this.commentBoxPaddings.headerWidthCB);
        const widthCB = Math.round(commentBox.width - this.commentBoxPaddings.paddingCB - this.commentBoxPaddings.headerWidthCB - this.commentBoxPaddings.borderCB);
        const heightCB = Math.round(commentBox.height - this.commentBoxPaddings.paddingCB - this.commentBoxPaddings.headerHeight - this.commentBoxPaddings.borderCB);


        let css: {} = {
            width: widthCB + "px",
            height: heightCB + "px",
            left: xCB + "px",
            top: yCB + "px",
        };

        return css;
    }

    getFiltredCommentBoxes() {
        return this.commentBoxes.filter( (val) => val.anchor == this.currentSlide )
    }

    getCssClass(commentBox: CommentBox) :string {
        switch (commentBox.style){
            case CommentBoxColor.Black:
                return 'black';
            case CommentBoxColor.BlackWithOpacity:
                return 'black-opacity';
            case CommentBoxColor.OrangeWithOpacity:
                return 'orange-opacity';
            case CommentBoxColor.WhiteWithOpacity:
                return 'white-opacity';
            case CommentBoxColor.White:
                return 'white';
            case CommentBoxColor.Blue:
                return 'blue';
            case CommentBoxColor.Green:
                return 'green';
            case CommentBoxColor.Yellow:
                return 'yellow';
        }

        return 'yellow';
    }
}
