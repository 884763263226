
import {Message} from '@app/core/messaging/message';

export class VideoAction extends Message {
    eventType: VideoEventType;
    currentTime:  number;
    index: number;
}

export enum VideoEventType {
  PLAY = 'play',
  PAUSE = 'pause'
}
