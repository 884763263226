import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {HeaderColor} from '@app/shared/models/header_color';

@Injectable()
export class HeaderService {

  public titleChanged: Subject<string> = new Subject<string>();
  public visibilityChanged: Subject<boolean> = new Subject<boolean>();
  public backButtonVisibilityChanged: Subject<boolean> = new Subject<boolean>();
  public backButtonActionChanged: Subject<boolean> = new Subject<boolean>();
  public headerBackgroundColorChanged: Subject<HeaderColor> = new Subject<HeaderColor>();
  public editProfileButtonVisibilityChanged: Subject<boolean> = new Subject<boolean>();
  public editProfileButtonChanged: Subject<boolean> = new Subject<boolean>();
  constructor() {
  }

  changeTitle(title: string) {
    this.titleChanged.next(title);
  }

  changeVisibilty(isVisible : boolean) {
    this.visibilityChanged.next(isVisible);
  }

  backButtonVisibility(isVisible: boolean) {
    this.backButtonVisibilityChanged.next(isVisible);
  }

  backButtonAction() {
    this.backButtonActionChanged.next();
  }

  changeHeaderBackgroundColor(color: HeaderColor) {
    this.headerBackgroundColorChanged.next(color);
  }

  editProfileButtonVisibility(isVisible: boolean) {
    this.editProfileButtonVisibilityChanged.next(isVisible);
  }

  editProfilButtonAction() {
    this.editProfileButtonChanged.next(true);
  }

}
