import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {Notification} from "@app/core/messaging/notification";
import {ChatMessage} from "@app/core/messaging/chat-message";
import {Presence} from "@app/core/messaging/presence";
import {Logger} from 'app/core/logger.service';
import {AbstractMessageProvider, XMPPmessage} from '@app/core/messaging-provider/abstract-message-provider';
import {Message, MessageInterface} from "@app/core/messaging/message";
import {AuthenticationService} from "@app/core/authentication/authentication.service";
import {LoadSequence, LoadSlide} from '@app/core/messaging/load-slide';
import {TouchGesture, TouchGestureType} from "@app/core/messaging/touch-gesture";
import {DrawAction, DrawActionType} from "@app/core/messaging/draw-action";
import {HideRelatedContent} from "@app/core/messaging/hide-related-content";
import {DisplayRelatedContent, RelatedContentVisibility} from "@app/core/messaging/display-related-content";
import {SurveyNotification} from '@app/core/messaging/survey-notification';
import {XmppStanzaValidatorService} from '@app/core/messaging-provider/xmpp-stanza-validator.service';
import {PollAnswers} from '@app/core/messaging/poll-answers';
import {RevokeAccess} from "@app/core/messaging/revoke-access";
import {AllowStreaming} from "@app/core/messaging/allow-streaming";
import {ScreenSize} from '@app/core/messaging/screen-size';
import {CommentBox, CommentBoxActionType} from "@app/core/messaging/comment-box";
import {PresenterControl} from '@app/core/messaging/presenter-control';
import {InteractiveModeEnableCommand} from '@app/core/messaging/interactive-mode-enable-command';
import {VideoAction, VideoEventType} from '@app/core/messaging/video-action';

import {SessionStatus} from '@app/core/messaging/session-state';
import {SessionPlayPauseState} from '@app/core/messaging/session-play-pause-state';
import {RemoteRelatedFile, RemoteRelatedFileAction} from '@app/core/messaging/remote-related-file';
import {ConferenceEnableCommand} from '@app/core/messaging/conference-enable-command';
import {SlideLoadedEvent} from '@app/core/messaging/slide-loaded-event';
import {CurrentSlide} from '@app/core/messaging/current-slide';
import {HighlightingModeCommand} from '@app/core/messaging/highlighting-mode-command';
import {DrawingModeCommand} from '@app/core/messaging/drawing-mode-command';
import {DisallowStreaming} from '@app/core/messaging/disallow-streaming';
import {BrowserService} from '@app/shared/service/browser.service';


declare var Strophe: any;
declare var $pres: any;
declare var $msg: any;
declare var $iq: any;

const logger = new Logger('XMPP Provider');

@Injectable()
export class XmppProviderService extends AbstractMessageProvider {

    public connection: any;
    public currentRoom: string;

    public messageReceivedCallback: (message: MessageInterface) => void;

    public connectedCallback: () => void;
    public disconnectedCallback: () => void;
    public joinRoomCallBack: () => void;

    public nbRetryConnection: number = 0;
    private _isResetConnection: boolean = false;

    private OCE_CONTENTS_PROXY: string =  '/contentoce/';
    private LOCAL_ENV_NAME: string = 'local';

    readonly _browserInfo: any;

    constructor(
        private authService: AuthenticationService,
        private stanzaValidator: XmppStanzaValidatorService,
        private _browserService: BrowserService
    ) {
        super();
        this._browserInfo = this._getBrowserInfo();
    }

    connect() {
        if (this.authService.credentials) {
            if (!this.connection) {
                this.nbRetryConnection++;
                this.connection = new Strophe.Connection(environment.webSocketUrl);
                this.connection.connect(this.authService.credentials.connection_id, this.authService.credentials.access_token, this.onXmppConnected.bind(this));
            } else {
                this.connectedCallback();
            }
        }
    }

    /**
     * @function _getBrowserInfo
     * @description
     * @private
     * @returns {any}
     */
    private _getBrowserInfo(): any {
        const info: any = this._browserService.getAll();
        const platform = info.platform.type ? info.platform.type : '';
        const os = info.os.name ? (info.os.name + (info.os.version ? '-' + info.os.version : '')) : '';
        const browser = info.browser.name ? (info.browser.name + (info.browser.version ? '-' + info.browser.version : '')) : '';
        return { platform: platform, os: os, browser: browser };
    }

    /**
     * @function resetConnection
     * @description reset connection
     * @public
     * @returns {void}
     */
    public resetConnection(callBack: () => void): void {
      if (this.connection && !this.connection.connected) {
        this.disconnect();
        this.nbRetryConnection = 0;
        this.connect();
        if (callBack) {
          this.joinRoomCallBack = callBack;
          this._isResetConnection = true;
        }
      }
    }

    /**
     * @function disconnect
     * @description disconnect from Strophe
     * @public
     * @returns {void}
     */
    public disconnect(): void {
        if (this.connection) {
            this.connection.disconnect();
            this.connection = null;
        }
    }

    /**
     * @function isConnection
     * @description check if connection is performed
     * @public
     * @returns {boolean}
     */
    public isConnection(): boolean {
        return this.connection ? true : false;
    }

    onConnected(callback: () => void) {
        this.connectedCallback = callback;
    }

    onDisconnect(callback: () => void) {
        this.disconnectedCallback = callback;
    }

    isConnected(): boolean {
        return this.connection && this.connection.connected;
    }

    sendMessage(message: Message) {
        if (!this.authService.credentials) {
            return true;
        }

        if (!message.from) {
            message.from = this.authService.credentials.connection_id;
        }

        if (!message.to && this.currentRoom) {
            message.to = this.currentRoom;
        }

        const msg = $msg({to: message.to, from: message.from, type: message.type});

        if (message.body) {
            msg.c('body').t(message.body).up();
        }


        /*let msg = $msg({
            to: message.to,
            type: 'groupchat'
        }).c('body').t(message.body).up().c('nick', {
            xmlns: 'http://jabber.org/protocol/nick'
        }).t('sfraoua');
      */
        if (this.connection.connected) {
            this.connection.send(msg);
        }
        return true;
    }

    sendContentState(percent: number): void {

        const message = new Message();
        message.type = 'groupchat';
        message.body = '{"LoadingStatus":{"status":"","bandwidth":0,"state":2,"audioState":0,"percent": '
            + percent + ',"timestamp":' + new Date().getTime() / 1000 + '}}';
        setTimeout(() => {
            this.sendMessage(message);
        }, 500);
    }

    sendPresence(to: string, type: string): boolean {
        const msg = $pres({to: to, type: type});
        this.connection.send(msg);
        return true;
    }

    submitFormRelatedContent(session_id: number): void {
        const message = new Message();
        message.type = 'groupchat';
        // timestamp user id session id action 52
        message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + this.authService.credentials.username + '", "p":' + session_id + ', "a":52}}';
        setTimeout(() => {
            this.sendMessage(message);
        }, 500);
    }

     sendDrawingAction(drawMessage: any): void {
       const message = new Message();
       message.type = 'groupchat';
       message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + this.authService.credentials.username + '", "p":' + JSON.stringify(drawMessage.data.color) + ', "a":' + drawMessage.data.actionType + ', "x":' + drawMessage.data.x + ',  "y":' + drawMessage.data.y + ', "platform":"' + this._browserInfo.platform + '"' + ', "browser":"' + this._browserInfo.browser + '"' + '}}';
       this.sendMessage(message);
     }

     sendEraseDrawingAction(): void {
       const message = new Message();
       message.type = 'groupchat';
       message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + this.authService.credentials.username + '", "oy":0, "a":3}}';
       this.sendMessage(message);
     }

    requestAudioVideoStreaming(speakerUid: string): void {
        const message = new Message();
        message.type = 'groupchat';
        // timestamp user id session id action 52
        message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + speakerUid + '", "p":"' + this.authService.credentials.connection_id + '", "a":13}}';
        this.sendMessage(message);
    }

    askForHand(participantUID: string): void {
      const message = new Message();
      message.type = 'groupchat';
      message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + participantUID + '", "p":"' + this.authService.credentials.connection_id + '", "a":18}}';
      this.sendMessage(message);
    }

     mandatoryFileNotification(mandatoryFileName: string, status: string): void {
        const message = new Message();
        message.type = 'groupchat';
        message.body = '{"MandatoryDocumentViewingStatus":{"name":"' + mandatoryFileName + '", "status" : "' + status + '"}}' ;
        this.sendMessage(message);
     }

    leaveHand(presenterUID: string): void {
      const message = new Message();
      message.type = 'groupchat';
      message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + presenterUID + '", "p":"' + presenterUID + '", "a":2}}';
      this.sendMessage(message);
    }

    sendTouch(eventType: string, currentPositionX: number, currentPositionY: number, JID: string): void {
      const message = new Message();
      message.type = 'groupchat';
      message.body =  '{"ie":{"x":' + currentPositionX + ',"t":' + new Date().getTime() / 1000 + ',"p":"' + eventType + '","y":' + currentPositionY + ',"u":"' + JID + '","oy":' + 0 + ',"a":1}}';
      this.sendMessage(message);
    }

    sendVideoAction(eventType: string, index: number, currentTime: number, JID: string): void {
      const message = new Message();
      message.type = 'groupchat';
      const videoParams = {
        i: index,
        e: eventType,
        ct: currentTime
      };
      message.body =  '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + JID + '","p":' + JSON.stringify(videoParams) + ',"a":10}}';
      this.sendMessage(message);
    }

    changeSlide(slideURI: string, ActiveHandJid: string, contentVersion: number, contentVersionID: number, size: ScreenSize): void {

      const slide = contentVersion + '_' + contentVersionID + slideURI;
      const screenSize = {
        width: size.width,
        height: size.height
      }
      const message = new Message();
      message.type = 'groupchat';
      message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ', "u":"' + ActiveHandJid + '", "p":"' + slide + '", "r":"' + slideURI + '", "cvid":"' + contentVersionID + '", "size": ' +  JSON.stringify(screenSize) + ', "a":0}}';
      this.sendMessage(message);
    }

    slideLoadedNotification(slideEvent: SlideLoadedEvent): void {
      const message = new Message();
      message.type = 'groupchat';
      message.body = '{"PresentationSlideLoadStatus": ' + JSON.stringify(slideEvent) + '}';
      this.sendMessage(message);
    }

    presentationSlideLoadCommand(slide: CurrentSlide): void {
      const message = new Message();
      message.type = 'groupchat';
      message.body = '{"PresentationSlideLoadCommand":' + JSON.stringify(slide) + '}';
      this.sendMessage(message);
    }

    askForCurrentSlide(): void {


        let message = new Message();
        //message.type = "groupchat";
        //message.body = '{"ie":{"t":' + new Date().getTime() / 1000 + ',"u":"' + this.authService.credentials.username + '","a":11}};';
        //this.sendMessage(message);

        /*
                let msg = $msg({
                    to: this.currentRoom,
                    type: 'groupchat'
                }).c('body').t('{"ie":{"t":' + new Date().getTime() / 1000 + ',"u":"' + this.authService.credentials.username + '","a":11}};').up()
                    .c('nick', {
                    xmlns: 'http://jabber.org/protocol/nick'
                })
                    .t('sfraoua');
                this.connection.send(msg); */


        message.type = 'groupchat';
        message.body = '{"ie":{"t": "' + new Date().getTime() / 1000 + '","u":"' + this.authService.credentials.username + '","a":11}}';
        this.sendMessage(message);
    }

    onMessageReceived(callback: (message: Message) => void) {
        this.messageReceivedCallback = callback;
    }

    joinRoom(room: string): void {
        this.currentRoom = room;
        this.connection.muc.join(room, this.authService.credentials.username, this.onRoomMsgReceive.bind(this),
            this.onRoomPresenceReceive.bind(this), null, this.authService.credentials.access_token);
    }

    leaveRoom(room: string): void {
        logger.info("Leaving room : " + room);
        this.connection.muc.leave(room, this.authService.credentials.username, () => {
            console.log("leaved room : " + room)
        });
    }

    onXmppConnected(status: any) {
        // THESE STATUS CODES ARE SELF EXPLANATORY

        if (status == Strophe.Status.CONNECTING) {
            logger.info('Connecting');
        } else if (status == Strophe.Status.CONNFAIL) {
            logger.info('Connection failed');
        } else if (status == Strophe.Status.DISCONNECTING) {
            logger.info('Disconnecting');
        } else if (status == Strophe.Status.DISCONNECTED) {
            logger.info('Disconnected');
            if (this.nbRetryConnection < XMPPmessage.MAX_RETRY_CONNEXION) {
                this.connect();
            } else {
                this.disconnectedCallback();
            }
        } else if (status == Strophe.Status.AUTHFAIL) {
            logger.info('Auth Failed');
        } else if (status == Strophe.Status.CONNECTED) {

            logger.info('Connected');

            //subscribe to pubsub
            // Commenting Pubsub fonctions: asked by evalentin on 2019, june 11th as it is no longer used
            /*this.connection.pubsub.subscribe(
                '/kis/update',
                null,
                null,
                this.onPubSubSuccessEvent.bind(this),
                this.onPubSubError.bind(this),
                true
            );

            //add message handler
            this.connection.addHandler(
                this.onPubSubCBEvent.bind(this),
                null,
                "message",
                null,
                null,
                "pubsub." + this.authService.credentials.tenant
            );
            */

            //add presence handler
            this.connection.addHandler(
                this.onPubSubCBEvent.bind(this),
                null,
                "presence"
            );

            this.connection.send($pres());

            if (this._isResetConnection) {
              this.joinRoomCallBack();
              this._isResetConnection = false;
              return true;
            }
            this.connectedCallback();
            return true;

        } else {
            logger.info(status);
        }
    }

    public onRoomMsgReceive(stanza: any) {
        this.onXmppMsgReceive(stanza);
        return true;
    }

    public onRoomPresenceReceive(stanza: any) {

        this.onXmppMsgReceive(stanza);

        return true;
    }

    public onPubSubCBEvent(stanza: any) {
        this.onXmppMsgReceive(stanza);
        return true;
    }

    public onPubSubSuccessEvent(stanza: any) {
        logger.info("Pubsub connected")
        this.onXmppMsgReceive(stanza);
        return true;
    }

    public onPubSubError(err: any) {
        alert("pubsub error");
        return true;
    }

    public formatStanza(stanza: Element): MessageInterface {

        if (stanza.tagName == 'presence') {

            //check if we asked to revoke acess
            let status = stanza.getElementsByTagName("status");
            let statusCode = 0;

            for (let i = 0; i < status.length; i++) {
                if (status[i].getAttribute("code") === "307") {
                    return this.stanzaToRevokeAccess(stanza);
                }
            }

            return this.stanzaToPresence(stanza);
        }

        if (stanza.getElementsByTagName('notification').length > 0) {


            const notification = stanza.getElementsByTagName('notification');
            const survey = JSON.parse(notification[0].textContent);

            // TODO listing the types of notification and create for each type a sub class
            if (survey.notify.survey) {
                return this.stanzaToSurveyNotification(stanza);
            } else {
                return this.stanzaToNotification(stanza);
            }

        }

        //messages depending on content
        let body = stanza.getElementsByTagName('body');

        if (body.length > 0) {
            let content = JSON.parse(body[0].textContent);

            //chat message received
            if (content.ChatMessage) {
                return this.stanzaToChatMessage(stanza);
            }

            // Loading sequence for OCE
            if (typeof content.PresentationSequenceLoadCommand != "undefined") {
                return this.stanzaToLoadSequence(stanza);
            }

            // Loading slide for OCE
            if (typeof content.PresentationSlideLoadCommand != "undefined") {
              return this.stanzaToLoadSlide(stanza);
            }

            // Loading pause/play for OCE
            if (typeof content.SessionStateChangeCommand != "undefined") {
              return this.stanzaToSessionPlayPauseState(stanza);
            }

            // Session state/config
            if (typeof content.SessionStatus != "undefined") {
              return this.stanzaToSessionStatus(stanza);
            }

            if (typeof content.RelatedDocumentOpenCommand !== 'undefined' || typeof content.RelatedDocumentCloseCommand !== 'undefined' ) {
              return this.stanzaToRemoteRelatedDocument(stanza);
            }

            if (typeof content.ConferenceChangeCommand !== 'undefined') {
                return this.stanzaToConferenceEnableCommand(stanza);
            }

            if (typeof content.InteractiveModeCommand !== 'undefined') {
                return this.stanzaToInteractiveModeCommand(stanza);
            }

            if (typeof content.HighlightingModeCommand !== 'undefined') {
                return this.stanzaToHighlightingModeCommand(stanza);
            }

            if (typeof content.DrawingModeCommand !== 'undefined') {
                return this.stanzaToDrawingModeCommand(stanza);
            }

            if (typeof content.ie != "undefined" && typeof content.ie.a != "undefined") {
                let action = parseInt(content.ie.a);
                switch (action) {
                    case 0:
                        // return this.stanzaToLoadSlideOld(stanza);
                    case 1:
                        return this.stanzaToTouch(stanza);
                    case 2:
                        let message = new PresenterControl();
                        message = this.fetchCommonMessageProperties(message, stanza);
                        if (message.to === this.authService.credentials.connection_id) {
                          return this._stanzaToPresenterControl(stanza);
                        }
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        return this.stanzaToDrawAction(stanza);
                    case 10:
                        return this.stanzaToVideoAction(stanza);
                    case 14:
                        return this.stanzaToAllowStreaming(stanza);
                    case 140:
                        return this.stanzaToDisallowStreaming(stanza);
                    case 16:
                        return this.stanzaToRelatedContent(stanza);
                    case 17:
                        return this.stanzaToHideRelatedContent(stanza);
                    case 19:
                    case 20:
                        return this.stanzaToCommentBox(stanza, CommentBoxActionType.Create);
                    case 21:
                    case 22:
                        return this.stanzaToCommentBox(stanza, CommentBoxActionType.Delete);
                    case 26:
                        return this.stanzaToPollAnswers(stanza);
                }
            }
        }

        return this.stanzaToMessage(stanza);

    }

    public stanzaToRevokeAccess(stanza: Element): RevokeAccess {
        let message = new RevokeAccess();
        message = this.fetchCommonMessageProperties(message, stanza);

        let reason = stanza.getElementsByTagName('reason');
        let item = stanza.getElementsByTagName('item');

        if (reason.length > 0) {
            message.reason = reason[0].textContent;
        }
        if (item.length > 0) {
            message.jidToRevoke = item[0].getAttribute('jid');
        }

        return message;
    }

    public stanzaToPresence(stanza: Element): Presence {
        let message = new Presence();
        message = this.fetchCommonMessageProperties(message, stanza);
        this.stanzaValidator.validatePresenceBody(message.body);
        let item = stanza.getElementsByTagName('item');

        if (item.length > 0) {
            message.user = {
                jid: item[0].getAttribute('jid'),
                role: item[0].getAttribute('role'),
                affiliation: item[0].getAttribute('affiliation'),
            };
            // message.uid = item[0].getAttribute('jid').replace(/\/.*/, '');
            message.uid = Strophe.getResourceFromJid(message.from);
        }
        if (message.type === 'unavailable' && item.length === 0) {
            // message.uid = message.from.replace(/\/.*/, '');
            message.uid = Strophe.getBareJidFromJid(message.from);
        }


        return message;
    }

   /* public stanzaToLoadSlideOld(stanza: Element): LoadSlide {
        let message = new LoadSlide();
        message = this.fetchCommonMessageProperties(message, stanza);
        this.stanzaValidator.validateLoadSlideBody(message.body);
        message.type = "load-slide";
        if (message.body.ie.cvid) message.version_id = message.body.ie.cvid;
        if (message.body.ie.p) message.path = message.body.ie.p;
        if (message.body.ie.r) message.real_path = message.body.ie.r;
        if (message.body.ie.t) message.timestamp = message.body.ie.t;
        if (message.body.ie.u) message.username = message.body.ie.u;

        message.size = new ScreenSize();
        if (message.body.ie.size && message.body.ie.size.width && message.body.ie.size.height) {
            message.size.height = message.body.ie.size.height;
            message.size.width = message.body.ie.size.width;
        }
        if (message.body.ie.v) {
          message.video = message.body.ie.v;
        }

        return message;
    }*/

    public stanzaToLoadSequence(stanza: Element): LoadSequence {
        let message = new LoadSequence();
        message = this.fetchCommonMessageProperties(message, stanza);
        // this.stanzaValidator.validateLoadSlideBodyOCE(message.body);
        message.type = 'load-slide';
        if (message.body.PresentationSequenceLoadCommand.sequenceIdentifier) message.sequenceIdentifier = message.body.PresentationSequenceLoadCommand.sequenceIdentifier;
        if (message.body.PresentationSequenceLoadCommand.presentationIdentifier) message.presentationIdentifier = message.body.PresentationSequenceLoadCommand.presentationIdentifier;
        if (message.body.PresentationSequenceLoadCommand.sender) message.sender = message.body.PresentationSequenceLoadCommand.sender;
        if (message.body.PresentationSequenceLoadCommand.dynamicContent) message.dynamicContent = message.body.PresentationSequenceLoadCommand.dynamicContent;
        if (message.body.PresentationSequenceLoadCommand.sequenceURL) message.sequenceURL = message.body.PresentationSequenceLoadCommand.sequenceURL;
        if (message.body.PresentationSequenceLoadCommand.timestamp) message.timestamp = message.body.PresentationSequenceLoadCommand.timestamp;

        message.size = new ScreenSize();
        if (message.body.PresentationSequenceLoadCommand.size && message.body.PresentationSequenceLoadCommand.size.width && message.body.PresentationSequenceLoadCommand.size.height) {
            message.size.height = message.body.PresentationSequenceLoadCommand.size.height;
            message.size.width = message.body.PresentationSequenceLoadCommand.size.width;
        }

        return message;
    }

    public stanzaToLoadSlide(stanza: Element): LoadSlide {
      let message = new LoadSlide();
      message = this.fetchCommonMessageProperties(message, stanza);
      // this.stanzaValidator.validateLoadSlideBodyOCE(message.body);
      message.type = 'load-slide';
      if (message.body.PresentationSlideLoadCommand.sequenceIdentifier) message.sequenceIdentifier = message.body.PresentationSlideLoadCommand.sequenceIdentifier;
      if (message.body.PresentationSlideLoadCommand.presentationIdentifier) message.presentationIdentifier = message.body.PresentationSlideLoadCommand.presentationIdentifier;
      if (message.body.PresentationSlideLoadCommand.sender) message.sender = message.body.PresentationSlideLoadCommand.sender;
      if (message.body.PresentationSlideLoadCommand.dynamicContent) message.dynamicContent = message.body.PresentationSlideLoadCommand.dynamicContent;
      if (message.body.PresentationSlideLoadCommand.slideURL) message.slideURL = message.body.PresentationSlideLoadCommand.slideURL;
      if (message.body.PresentationSlideLoadCommand.slideName) message.slideName = message.body.PresentationSlideLoadCommand.slideName;
      if (message.body.PresentationSlideLoadCommand.timestamp) message.timestamp = message.body.PresentationSlideLoadCommand.timestamp;

      message.size = new ScreenSize();
      if (message.body.PresentationSlideLoadCommand.size && message.body.PresentationSlideLoadCommand.size.width && message.body.PresentationSlideLoadCommand.size.height) {
        message.size.height = message.body.PresentationSlideLoadCommand.size.height;
        message.size.width = message.body.PresentationSlideLoadCommand.size.width;
      }

      return message;
    }

    public stanzaToSessionPlayPauseState(stanza: Element): SessionPlayPauseState {
      let message = new SessionPlayPauseState();
      message = this.fetchCommonMessageProperties(message, stanza);
      message.type = 'notification';
      if (message.body.SessionStateChangeCommand.state) { message.state = message.body.SessionStateChangeCommand.state; }
      if (message.body.SessionStateChangeCommand.currentSlide) {
          message.currentSlide = message.body.SessionStateChangeCommand.currentSlide;
          if (environment.env === this.LOCAL_ENV_NAME && environment.proxyConfig) {
              const url = message.currentSlide.slideURL.split('/');
              message.currentSlide.url = document.location.origin + this.OCE_CONTENTS_PROXY + url[4] + '/' + url[5];
          } else {
              message.currentSlide.url = message.currentSlide.slideURL;
          }
      }
      return message;
    }

    public stanzaToAllowStreaming(stanza: Element): AllowStreaming {
        let message = new AllowStreaming();
        message = this.fetchCommonMessageProperties(message, stanza);
        if (message.body.ie.p) { message.streamerUid = message.body.ie.p; }
        return message;
    }

    public stanzaToDisallowStreaming(stanza: Element): DisallowStreaming {
        let message = new DisallowStreaming();
        message = this.fetchCommonMessageProperties(message, stanza);
        if (message.body.ie.p) { message.streamerUid = message.body.ie.p; }
        return message;
    }

    public stanzaToDrawAction(stanza: Element): DrawAction {
        let message = new DrawAction();
        message = this.fetchCommonMessageProperties(message, stanza);
        this.stanzaValidator.validateDrawActionBody(message.body);

        message.type = "draw-action";
        message.x = message.body.ie.x;
        message.y = message.body.ie.y;
        // message.color = "#000"; // No color by default
        message.userID = message.from;
        message.platform = message.body.ie.platform;

        if (typeof message.body.ie.p != "undefined") {
            let unsigned = (parseInt(message.body.ie.p) >>> 0);
            message.color = '#' + unsigned.toString(16).substring(2);
        }

        switch (parseInt(message.body.ie.a)) {
            case 3:
                message.actionType = DrawActionType.CLEAR;
                break;
            case 4:
                message.actionType = DrawActionType.STARTED;
                break;
            case 5:
                message.actionType = DrawActionType.ADD;
                break;
            case 6:
                message.actionType = DrawActionType.ENDED;
                break;
            case 7:
                message.actionType = DrawActionType.CANCELED;
                break;
            default:
                logger.warn("Unkown Draw action : " + message.body.ie.a);
                break;
        }

        return message;
    }

    public stanzaToTouch(stanza: Element): TouchGesture {
        let message = new TouchGesture();
        message = this.fetchCommonMessageProperties(message, stanza);
        this.stanzaValidator.validateTouchGestureBody(message.body);

        message.type = "touch-gesture";
        message.x = message.body.ie.x;
        message.y = message.body.ie.y;

        switch (message.body.ie.p) {
            case "s":
                message.gestureType = TouchGestureType.SCROLL;
                break;
            case "m":
                message.gestureType = TouchGestureType.MOVE;
                break;
            case "c":
                message.gestureType = TouchGestureType.CANCEL;
                break;
            case "click":
                message.gestureType = TouchGestureType.CLICK;
                break;
            case "d":
                message.gestureType = TouchGestureType.DOWN;
                break;
            case "u":
                message.gestureType = TouchGestureType.UP;
                break;
            default:
                logger.error("Gesture type not found !");
                break;
        }

        return message;
    }

    public stanzaToVideoAction(stanza: Element): VideoAction {
      let message = new VideoAction();
      message = this.fetchCommonMessageProperties(message, stanza);

      // console.log(stanza);

      message.type = 'video-action';
      message.currentTime = message.body.ie.p.ct;
      message.index = message.body.ie.p.i;

      switch (message.body.ie.p.e) {
        case VideoEventType.PLAY:
          message.eventType = VideoEventType.PLAY;
          break;
        case VideoEventType.PAUSE:
          message.eventType = VideoEventType.PAUSE;
          break;
        default:
          logger.error('video event type not found !');
          break;
      }
      return message;
    }

    public stanzaToRelatedContent(stanza: Element): DisplayRelatedContent {

        let message = new DisplayRelatedContent();
        message = this.fetchCommonMessageProperties(message, stanza);
        this.stanzaValidator.validateDisplayRelatedContentBody(message.body);

        message.contentId = message.body.ie.p;

        switch (message.body.ie.r) {
            case 'shared':
                message.visibility = RelatedContentVisibility.SHARED;
                break;
            case 'public':
                message.visibility = RelatedContentVisibility.PUBLIC;
                break;
            case 'private':
                message.visibility = RelatedContentVisibility.PRIVATE;
                break;
        }

        return message;
    }

    public stanzaToCommentBox(stanza: Element, actionType : CommentBoxActionType): CommentBox {

        let message = new CommentBox();
        message = this.fetchCommonMessageProperties(message, stanza);

        message.body.ie.p = JSON.parse(message.body.ie.p);
        this.stanzaValidator.validateCommentBoxBody(message.body);

        //message.contentId = message.body.ie.p;
        const commentBoxJson = message.body.ie.p.CommentBox;
        message.oid =commentBoxJson.oid;
        message.versionUrn =commentBoxJson.versionURN;
        message.anchor =commentBoxJson.anchor;
        message.owner =commentBoxJson.owner;
        message.style =commentBoxJson.style;
        message.height =commentBoxJson.height;
        message.width =commentBoxJson.width;
        message.x =commentBoxJson.x;
        message.y =commentBoxJson.y;
        message.text =commentBoxJson.text;
        message.actionType = actionType;

        return message;
    }

    public stanzaToHideRelatedContent(stanza: Element): HideRelatedContent {

        let message = new HideRelatedContent();
        message = this.fetchCommonMessageProperties(message, stanza);
        this.stanzaValidator.validateHideRelatedContentBody(message.body);
        //message.contentId = message.body.ie.p;

        return message;
    }

    public stanzaToChatMessage(stanza: Element): ChatMessage {
        let message = new ChatMessage();
        message = this.fetchCommonMessageProperties(message, stanza);
        this.stanzaValidator.validateChatBody(message.body);

        message.message = message.body.ChatMessage.content;
        message.timestamp = message.body.ChatMessage.timestamp;
        message.username = message.from.replace(this.currentRoom + '/', '');

        return message;
    }

    public stanzaToNotification(stanza: Element): Notification {
        let message = new Notification();
        message.type = 'notification';
        message = this.fetchCommonMessageProperties(message, stanza);
        // this.stanzaValidator.validateNotificationBody(message.body);

        let notification = stanza.getElementsByTagName('notification');

        if (notification.length > 0) {
            message.body = JSON.parse(notification[0].textContent);
            message.state = message.body.notify.state;
            message.sessionId = message.body.notify.session;
        }

        return message;
    }

    stanzaToSurveyNotification(stanza: Element): SurveyNotification {

        const notification = stanza.getElementsByTagName('notification');
        let message = new SurveyNotification();
        message.type = 'surveyNotification';
        message = this.fetchCommonMessageProperties(message, stanza);

        message.body = JSON.parse(notification[0].textContent);
        message.status = message.body.notify.survey.status;
        message.sessionId = message.body.notify.survey.sessionID;
        message.questionID = message.body.notify.survey.questionID;
        message.id = message.body.notify.survey.id;
        return message;
    }

    public stanzaToMessage(stanza: Element): MessageInterface {
        let message = new Message();
        message = this.fetchCommonMessageProperties(message, stanza);

        return message;
    }

    private disconnectFromAllRooms() {
        this.connection.muc.listRooms(
            'conference.' + this.authService.credentials.tenant,
            (stanza: Element) => {
                const elements: any = stanza.getElementsByTagName('item');
                if (elements.length > 0) {
                    elements.forEach((elem: any) => {
                        this.leaveRoom(elem.getAttribute('jid'));
                    });
                }
            },
            (error: any) => {}
        );
    }

    private onXmppMsgReceive(stanza: Element) {
        // Avoiding delayed stanzas, except delayed Presence stanzas, used to detect dual login error
        if (stanza.getElementsByTagName('delay').length > 0 && stanza.tagName !== 'presence') {
            return true;
        }
        const decodedMessage: MessageInterface = this.formatStanza(stanza);
        // logger.info('Stanza received =====> ' + (decodedMessage.constructor.name));
        this.messageReceivedCallback(decodedMessage);
        return true;
    }

    private fetchCommonMessageProperties(message: any, stanza: Element) {

        if (stanza.getAttribute('type')) {
            message.type = stanza.getAttribute('type');
        }

        message.from = stanza.getAttribute('from');
        message.to = stanza.getAttribute('to');

        let error = stanza.getElementsByTagName('error');
        if (error.length > 0) {
            message.error = {message: error[0].textContent, code: error[0].getAttribute('code')};
        }

        let body = stanza.getElementsByTagName('body');

        if (body.length > 0) {
            message.body = JSON.parse(body[0].textContent);
        }

        return message;
    }

    private stanzaToPollAnswers(stanza: Element): PollAnswers {

        let message = new PollAnswers();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'poll-answers';

        message.body = JSON.parse(message.body.ie.p);
        message.answers = message.body.survey.answers.answers;
        message.status = message.body.survey.status;

        return message;
    }

    private _stanzaToPresenterControl(stanza: Element): PresenterControl {

      let message = new PresenterControl();
      message = this.fetchCommonMessageProperties(message, stanza);
      message.type = 'presenter-control';

      // message.body = JSON.parse(message.body.ie);
      message.presenterID = message.body.ie.p;

      return message;
    }

    private stanzaToSessionStatus(stanza: Element): SessionStatus {

      let message = new SessionStatus();
      message = this.fetchCommonMessageProperties(message, stanza);
      message.type = 'session-state-status';
      message.isChatEnabled = message.body.SessionStatus.isChatEnabled;
      message.isConferenceEnabled = message.body.SessionStatus.isConferenceEnabled;
      message.currentSlide = message.body.SessionStatus.currentSlide;
      message.state = message.body.SessionStatus.state;
      message.relatedDocuments =  message.body.SessionStatus.relatedDocuments;
      message.presentations =  message.body.SessionStatus.presentations;
      message.customers =  message.body.SessionStatus.customers;
      message.isHighlightingModeEnabled =  message.body.SessionStatus.isHighlightingModeEnabled;
      if (message.body.SessionStatus.isInteractiveModeEnabled) {
        message.isInteractiveModeEnabled = message.body.SessionStatus.isInteractiveModeEnabled;
      }

      if (!message.body.SessionStatus.currentSlide.size || !message.body.SessionStatus.currentSlide.size.width || !message.body.SessionStatus.currentSlide.size.height) {
        message.body.SessionStatus.currentSlide.size = new ScreenSize();
      }


      return message;
    }

    private stanzaToRemoteRelatedDocument(stanza: Element): RemoteRelatedFile {

      let message = new RemoteRelatedFile();

      message = this.fetchCommonMessageProperties(message, stanza);
      if (message.body.RelatedDocumentOpenCommand) {
        message.type = message.body.RelatedDocumentOpenCommand.relatedDocument.type;
        message.networkURL = message.body.RelatedDocumentOpenCommand.relatedDocument.networkURL;
        message.name = message.body.RelatedDocumentOpenCommand.relatedDocument.name;
        message.action = RemoteRelatedFileAction.OPEN;
      }
      if (message.body.RelatedDocumentCloseCommand) {
        message.action = RemoteRelatedFileAction.CLOSE;
      }

      return message;
    }

    private stanzaToConferenceEnableCommand(stanza: Element): ConferenceEnableCommand {

      let message = new ConferenceEnableCommand();

      message = this.fetchCommonMessageProperties(message, stanza);
      message.type = 'conference-enable-command';
      message.isConferenceEnabled = message.body.ConferenceChangeCommand.isEnabled;

      return message;
    }

    private stanzaToInteractiveModeCommand(stanza: Element): InteractiveModeEnableCommand {
        let message = new InteractiveModeEnableCommand();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'interactive-mode-enable-command';
        message.isEnabled = message.body.InteractiveModeCommand.isEnabled;
        return message;
    }

    private stanzaToHighlightingModeCommand(stanza: Element): HighlightingModeCommand {
        let message = new HighlightingModeCommand();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'highlighting-mode-command';
        message.isEnabled = message.body.HighlightingModeCommand.isEnabled;
        return message;
    }

    private stanzaToDrawingModeCommand(stanza: Element): DrawingModeCommand {
        let message = new DrawingModeCommand();
        message = this.fetchCommonMessageProperties(message, stanza);
        message.type = 'drawing-mode-command';
        message.isEnabled = message.body.DrawingModeCommand.isEnabled;
        return message;
    }

}
