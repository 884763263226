import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '@app/shared/models/user';
import * as _ from 'lodash';
import {Participant} from '@app/shared/models/participant';


@Injectable()
export class AttendeesService {

  listOfAttendees: Array<User> = [];
  listOfExpert: Array<Participant> = [];

  constructor(private http: HttpClient) { }

  setAttendees(user: User): Array<User> {
    this.listOfAttendees.push(user);
    return this.listOfAttendees;
  }

  getAttendee(username: String): User {
    return _.find(this.listOfAttendees, (attendee: User) =>  attendee.uid === username );
  }

  deleteAttendee(username: String): void {
    this.listOfAttendees = _.reject(this.listOfAttendees, (attendee: User) =>  attendee.uid === username );
  }

  setExpert(user: Participant): void {
    this.listOfExpert.push(user);
  }

  getListOfExpert(): Array<Participant> {
    return this.listOfExpert;
  }

  isExpert(username: String): Participant {
    return _.find(this.listOfExpert, (expert: Participant) =>  expert.memberDN === username );
  }



}
