import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AlertModule} from 'ngx-bootstrap';

import {SettingRoutingModule} from './setting-routing.module';
import {SettingComponent} from '@app/setting/setting.component';
import {CoordinateComponent} from '@app/setting/profil/coordinate/coordinate.component';
import {TermsComponent} from '@app/setting/terms/terms.component';
import {UpdatePwdComponent} from '@app/setting/update-pwd/update-pwd.component';
import {PrivacyPolicyComponent} from '@app/setting/privacy-policy/privacy-policy.component';
import {ProfileComponent} from '@app/setting/profil/profile.component';
import {CoreModule} from '@app/core/core.module';
import {SharedModule} from '@app/shared/shared.module';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
        AlertModule.forRoot(),
        SettingRoutingModule,
        FormsModule,
        CoreModule,
        SharedModule
    ],
    declarations: [
        SettingComponent,
        CoordinateComponent,
        TermsComponent,
        UpdatePwdComponent,
        PrivacyPolicyComponent,
        ProfileComponent
    ]
})
export class SettingModule {
}
