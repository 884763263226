import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {I18nService} from '@app/core/i18n.service';
import {ReplaySubject} from 'rxjs';

@Injectable()
export class CustoService {

    private _PARAMS: any = {};
    private _CUSTO: any = {};
    public paramsLoadedSubject = new ReplaySubject();
    public custoLoadedSubject = new ReplaySubject();

    constructor(
        private http: HttpClient,
        private _i18nService: I18nService
    ) {
        if (!this._i18nService.language) {
            console.error('Current language is not defined!');
            return;
        }
        if (this._PARAMS) { this.loadParams(); }
        if (this._CUSTO) { this.loadCusto(); }
    }

    /**
     * @function _loadParams
     * @description
     * @public
     * @returns {void}
     */
    public loadParams(): void {
        this.http.get('./assets/custo/params.json?t=' + new Date().getTime()).subscribe((data: any) => {
            this._PARAMS = data;
            this.paramsLoadedSubject.next(true);
        }, (error: any) => {
            this.paramsLoadedSubject.next(false);
        });
    }

    /**
     * @function _loadCusto
     * @description
     * @public
     * @returns {void}
     */
    public loadCusto(): void {
        this.http.get('./assets/custo/custo.json?t=' + new Date().getTime()).subscribe((data: any) => {
            this._CUSTO = data;
            this.custoLoadedSubject.next(true);
        }, (error: any) => {
            this.custoLoadedSubject.next(false);
        });
    }

    /**
     * @function getProp
     * @description
     * @public
     * @param {string} property
     * @param {string} locale
     * @returns {string}
     */
    public getProp(property: string, locale: string = null): string {
        if (!this._CUSTO.hasOwnProperty(property)) {
            // console.warn('Property "' + property + '" does not exist in custo.json file');
            return null;
        }
        locale = locale || this._i18nService.language;
        const prop = this._CUSTO[property].find((item: any) => item.locale === locale);
        if (prop && prop.hasOwnProperty('value')) {
            return prop.value;
        } else if (prop && !prop.hasOwnProperty('value')) {
            console.error('Property "' + property + '" does not have any value property');
            return null;
        } else {
            // console.warn('Property "' + property + '" does not exist for ' + locale + ' locale');
            return null;
        }
    }

    /**
     * @function getParam
     * @description
     * @public
     * @param {string} property
     * @returns {any}
     */
    public getParam(property: string): any {
        if (!this._PARAMS.hasOwnProperty(property)) {
            console.warn('Property "' + property + '" does not exist in params.json file');
            return null;
        }
        return this._PARAMS[property];
    }
}
