import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {AuthenticationService} from "@app/core/authentication/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public authService: AuthenticationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(!this.authService.credentials){
      console.log('dont change bearer !')
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.credentials.access_token}`
      }
    });
    return next.handle(request);
  }
}