import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment}  from '@env/environment';

import { Gtu } from '@app/shared/models/gtu';

import { Observable } from 'rxjs';
import { map} from 'rxjs/operators';

@Injectable()
export class GtuService {

    /**
     * Constructor
     * @param {HttpClient} http
     */
    constructor(
        private http: HttpClient
    ) {}

    /**
     * getGtu
     * @public
     * @returns {Observable<Gtu>}
     */
    get(): Observable<Gtu> {
      return this.http.get(environment.serverApiUrl + '/gtus/url').pipe(
          map((body: Gtu) => body)
      );
    }

    /**
     * accepGtu
     * @public
     * @returns {Observable<any>}
     */
    accept() {
      return this.http.post(environment.serverApiUrl + '/gtus/acceptance', {}).pipe(
          map((body: any) => body)
      );
    }

}
