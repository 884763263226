/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";



export class PollAnswers extends  Message{
  status: string;
  answers: Array<any>;
  id: number;
  questionID: number;

}
