/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";
import {SessionState} from "@app/shared/models/session";

export class DrawAction extends Message{
    actionType : DrawActionType;
    color : string;
    x : number;
    y : number;
    userID: string;
    userName: string;
    platform: string;
}


export enum DrawActionType {
    STARTED = 4,
    ADD = 5,
    ENDED = 6,
    CANCELED = 7,
    CLEAR = 3,
}