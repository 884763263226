import {Directive, OnInit, ElementRef, Input} from '@angular/core';
import {AttendeesService} from '@app/shared/service/attendees.service';

@Directive({
  selector: '[appIsExpert]'
})
export class IsExpertDirective implements OnInit {


  @Input('appIsExpert') uid: String;
  constructor(private _el: ElementRef,
              private _attendees: AttendeesService ) {}
  ngOnInit() {

    if (!this._attendees.isExpert(this.uid)) {
      this._el.nativeElement.style.display = 'none';
    }

  }

}
