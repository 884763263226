import {Injectable} from '@angular/core';
import * as OT from '@opentok/client';
import {environment} from '@env/environment';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Logger} from "@app/core/logger.service";
import Session = OT.Session;
import SubscriberProperties = OT.SubscriberProperties;
import OTError = OT.OTError;
import PublisherProperties = OT.PublisherProperties;
import {BrowserService} from '@app/shared/service/browser.service';
import {TranslateService} from '@ngx-translate/core';

const logger = new Logger('Opentok Service');

export enum OpentokConnectionStatus {
    Disabled = "disabled",
    Connecting = "connecting",
    Connected = "connected",
    Disconnected = "disconnected",
    Reconnecting = "reconnecting",
    Reconnected = "reconnected",
    PublishingError = "publish_error",
    Error = "error"
}

@Injectable()
export class OpentokService {

    public conferenceEvents: Subject<OpentokConnectionStatus> = new Subject();
    public conferenceStatus: OpentokConnectionStatus = OpentokConnectionStatus.Disabled;

    session: OT.Session = null;
    token: string = null;
    streams: Array<OT.Stream> = [];
    publisher: OT.Publisher;
    publishing: boolean;
    private _translations: string[] = [];

    /**
     * @param {AuthenticationService} authService
     * @param {HttpClient} httpClient
     * @param {BrowserService} _browserService
     */
    constructor(
        private authService: AuthenticationService,
        private httpClient: HttpClient,
        private _browserService: BrowserService,
        private _translateService: TranslateService
    ) {
      this._translateService.get('not supported browser version for OpenTok')
        .subscribe((trans: string) => this._translations['not supported browser version for OpenTok'] = trans);
    }

    getOT() : any {
        return OT;
    }

    public resetSession() {
        this.token = null;
        this.session = null;
    }

    initSession(key: string, isPresenter: boolean = false, isOCE: boolean = false): Observable<Session> {
        // key parameter can be the sessionId or the sessionKey, depending on the OCE status
        const uid: string = this.authService.credentials.username;
        const url = environment.serverApiOTUrl + '/session/' + (isOCE ? 'key/' : '') + key + '/token/' + uid + '?presenter=' + (isPresenter ? 'true' : 'false');
        return this.httpClient.get(url)
            .pipe(map((data: any) => {
                this.session = this.getOT().initSession(environment.opentokApiKey, data.tokSession);
                this.token = data.token;
                this.initEventHandlers();
                return this.session;
            }));
    }

    connect() {
        this.publishing = false;
        this.conferenceStatus = OpentokConnectionStatus.Connecting;
        return new Promise((resolve, reject) => {
            this.session && this.session.connect(this.token, (err) => {
                if (!!err) {
                    reject(err);
                    logger.error(err);
                    this.conferenceStatus = OpentokConnectionStatus.Error;
                    return;
                }
                resolve(this.session);
                this.conferenceEvents.next(OpentokConnectionStatus.Connected);

                const uiOT = this.session.connection && this.session.connection.data && this.session.connection.data.substring(4);
                const uiXMPP = this.authService.credentials && this.authService.credentials.username;
                if (uiOT !== uiXMPP) {
                    let text = "Warning: your OpenTok uid is different from your XMPP uid!";
                    text += "\nOpenTok uid: " + uiOT;
                    text += "\n   XMPP uid: " + uiXMPP;
                    console.error(text);
                }
            });
        });
    }

    disable() {
        logger.info('Disabled');
        this.streams = [];
        if (this.session) { this.session.disconnect(); }
        this.conferenceStatus = OpentokConnectionStatus.Disabled;
        this.conferenceEvents.next(OpentokConnectionStatus.Disabled);
        this.publishing = false;
    }

    startPublishing() {
        logger.info('Init Publishing');

        if (this.conferenceStatus !== OpentokConnectionStatus.Connected) {
            logger.info('Cant publish => not connected');
            return;
        }
        let name = (this.authService.credentials.user.first_name) ? this.authService.credentials.user.first_name :'';
        name += " "+this.authService.credentials.user.last_name;


        const publisherOptions: PublisherProperties = {
            width: 133,
            height: 100,
            name: name,
            publishAudio: true,
            publishVideo: false,
            //audioSource : mediaStream.getAudioTracks()[0].clone(),
            //videoSource : mediaStream.getVideoTracks()[0].clone(),
            insertMode: "append"
        };

        this.publisher = OT.initPublisher(document.getElementById('kad-conference-publisher'), publisherOptions);

        this.session.publish(this.publisher, (err) => {
            if (err) {
                logger.info('Error while Publishing');
                logger.error(err.message);
                if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
                    // Access denied can also be handled by the accessDenied event
                    logger.error('Please allow access to the Camera and Microphone and try publishing again.');
                } else {
                    logger.error(err);
                }
                this.stopPublishing();
                this.conferenceEvents.next(OpentokConnectionStatus.PublishingError);
            } else {
                this.publishing = true;
                logger.info('Started Publishing');
            }
        });
    }

    stopPublishing() {
      if (this.isPublishingAudio()) {
        this.publisher.publishAudio(false);
      }
      if (this.isPublishingVideo()) {
        this.publisher.publishVideo(false);
      }

      logger.info('Stop Publishing');
      setTimeout(() => {
        this.publishing = false;
        if (this.publisher && this.session) {
          this.session.unpublish(this.publisher);
          this.publisher.destroy();
          this.publisher = null;
        }
      }, 1000);
    }

    isPublishingAudio(): boolean {
        if (!this.publishing)
            return false;
        return this.publisher.stream && this.publisher.stream.hasAudio;
    }

    isPublishingVideo(): boolean {
        if (!this.publishing)
            return false;
        return this.publisher.stream && this.publisher.stream.hasVideo;
    }

    muteUnmutePublisher() {
        if (!this.publishing || this.conferenceStatus !== OpentokConnectionStatus.Connected)
            return;

        this.publisher.publishAudio(!this.isPublishingAudio());
        logger.info('Enable publisher audio = ' + this.isPublishingAudio());
        /* this.conferenceEvents.next(OpentokConnectionStatus.Disabled);
        this._eventsService.streamingDisabledNotify();
        this.stopPublishing(); */
    }

    togglePublisherVideo() {
        if (!this.publishing || this.conferenceStatus !== OpentokConnectionStatus.Connected)
            return;

        this.publisher.publishVideo(!this.isPublishingVideo());
        logger.info('Enable publisher video = ' + this.isPublishingVideo());
    }

    private initEventHandlers() {
        this.session.on('streamCreated', this.streamCreated.bind(this));
        this.session.on('streamPropertyChanged', this.streamPropertyChanged.bind(this));
        this.session.on('streamDestroyed', this.streamDestroyed.bind(this));
        this.session.on("sessionDisconnected", this.sessionDisconnected.bind(this));
        this.session.on("sessionReconnecting", this.sessionReconnecting.bind(this));
        this.session.on("sessionReconnected", this.sessionReconnected.bind(this));
        this.session.on("sessionConnected", this.sessionConnected.bind(this));
        this.session.on("connectionDestroyed", this.connectionDestroyed.bind(this));
    }

    private streamCreated(event: any) {

        if (this.streams.indexOf(event.stream) >= 0) {
            return;
        }
        logger.info('Stream created');

        this.streams.push(event.stream);

        const subscriberOptions: SubscriberProperties = {
            width: 133,
            height: 100,
            //testNetwork: true => problems when activated for iphone safari
        };
        setTimeout(() => {
            this.session.subscribe(
                event.stream,
                document.getElementById('kad-conference-' + event.stream.streamId),
                subscriberOptions,
                (err: OTError) => {

                    if (!!err) {
                        logger.error('Error while subscribing to publisher', err);
                        if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
                            // Access denied can also be handled by the accessDenied event
                            logger.error('Please allow access to the Camera and Microphone and try publishing again.');
                        } else {
                            logger.error('Failed to get access to your camera or microphone. Please check that your webcam'
                                + ' is connected and not being used by another application and try again.');
                        }
                        this.stopPublishing();
                        return;
                    }

                    logger.info('Subscribed to the stream "' + event.stream.name + '"');
                    this.conferenceEvents.next(OpentokConnectionStatus.Connected);
                }
            );
        }, 200);
    }

    private streamDestroyed(event: any) {
        const idx = this.streams.indexOf(event.stream);
        logger.info(event.stream.name+" : Stream destroyed ");

        if (idx > -1) {
            this.streams.splice(idx, 1);
        }
        }

    private streamPropertyChanged(event: any) {
        logger.info('"' + event.stream.name + '" Stream changed => Video : ' + event.stream.hasVideo + ", Audio : " + event.stream.hasAudio);
        this.conferenceEvents.next(OpentokConnectionStatus.Connected);
    }

    private sessionDisconnected(event: any) {
        this.stopPublishing();
        this.conferenceStatus = OpentokConnectionStatus.Disconnected;
        this.conferenceEvents.next(OpentokConnectionStatus.Disconnected);
        logger.info('Session disconnected');
    }

    private sessionReconnecting(event: any) {
        logger.info('Session reconnecting');
        this.conferenceStatus = OpentokConnectionStatus.Reconnecting;
        this.conferenceEvents.next(OpentokConnectionStatus.Reconnecting);
    }

    private sessionReconnected(event: any) {
        logger.info('Session reconnected');
        this.conferenceStatus = OpentokConnectionStatus.Connected;
        this.conferenceEvents.next(OpentokConnectionStatus.Reconnected);
    }

    private sessionConnected(event: any) {
        logger.info('Session connected');
        this.conferenceStatus = OpentokConnectionStatus.Connected;
        this.conferenceEvents.next(OpentokConnectionStatus.Connected);
    }
    private connectionDestroyed(event: any) {
        logger.info('connectionDestroyed');
    }

    isPublishing() : boolean {
        return this.publishing;
    }

}
