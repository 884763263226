import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  PresentationEvent,
  PresentationEventsService, PresentationResponse,
} from '@app/home/session/presentation/presentation-events.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {MessageInterface} from '@app/core/messaging/message';
import {Subscription} from 'rxjs';
import {DisplayRelatedContent, RelatedContentVisibility} from '@app/core/messaging/display-related-content';
import {HideRelatedContent} from '@app/core/messaging/hide-related-content';
import {ContentVersion} from '@app/shared/models/content_version';
import {RelatedContent} from '@app/shared/models/related_content';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {Session, SessionType} from '@app/shared/models/session';
import {RemoteRelatedFile, RemoteRelatedFileAction} from '@app/core/messaging/remote-related-file';
import {BrowserService} from '@app/shared/service/browser.service';
import {RelatedService} from '@app/shared/service/related.service';
import {UtilService} from '@app/shared/service/util.service';

@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.scss']
})
export class RelatedContentComponent implements OnInit, OnDestroy {

  @Input()
  set session(session: Session) {
    if (session) {
      this._session = session;
    }
  }

  get session(): Session {
    return this._session;
  }

  @Input()
  set content(contents: ContentVersion[]) {
    if (contents) {
       this._getContent(contents);
    }
  }
  @ViewChild('contentRelatedIFrame') contentRelatedIFrame: ElementRef;

  private subscriptions: Subscription[] = [];
  public currentRelatedContent: SafeResourceUrl = null;
  public closableRelatedContent: boolean = false;
  private _isHtmlForm: boolean = false;
  public relatedContents: RelatedContent[] = [];
  public contentsVersions: ContentVersion[] = [];
  public relatedContentName: string = '';
  private _session: Session;
  public isOCE: boolean = false;

  /**
   * @function constructor
   * @param {MessagingService} messagingService
   * @param {PresentationEventsService} presentationActionsService
   * @param {AuthenticationService} _authService
   * @param {DomSanitizer} _sanitizer
   */
  constructor(
      private messagingService: MessagingService,
      private presentationActionsService: PresentationEventsService,
      private _authService: AuthenticationService,
      private _sanitizer: DomSanitizer,
      private _eventService: PresentationEventsService,
      private _browserService: BrowserService,
      private _relatedService: RelatedService,
      private _utilService: UtilService
  ) {
    this.subscriptions.push(
      this.messagingService.Messages
        .subscribe((message: MessageInterface) => {
          switch (message.constructor) {
            case DisplayRelatedContent:
                this._relatedContentReceived(<DisplayRelatedContent>message);
             break;
            case HideRelatedContent:
              this._hideRelatedContentReceived(<HideRelatedContent>message);
              break;
            case RemoteRelatedFile:
              const remoteFile = <RemoteRelatedFile>message;
              if (remoteFile.action === RemoteRelatedFileAction.OPEN) {
                this.remoteRelatedContentClicked(remoteFile);
              } else if (remoteFile.action === RemoteRelatedFileAction.CLOSE) {
                this.hideRelatedContentAction(remoteFile);
              }
              break;
          }
        })
    );

    this.subscriptions.push(this._eventService.actionRequests
      .subscribe(
        (action: PresentationResponse) => {
          switch (action.event) {
            case PresentationEvent.relatedContentClickAction:
              this.relatedContentClicked(action.data) ;
              break;
            case PresentationEvent.remoteRelatedContentClickAction:
              this.remoteRelatedContentClicked(action.data) ;
              break;
          }
        }
      ));
  }

  /**
   * @function ngOnInit
   */
  ngOnInit() {
    this.isOCE = this.session.sessionType === SessionType.RemoteOCE;
  }

  /**
   * @function ngOnDestroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(
      (subscription: Subscription) => subscription.unsubscribe()
    );
  }


  /**
   * @function relatedContentClicked
   * @public
   * @param {RelatedContent} content
   * @returns {void}
   */
  public relatedContentClicked(content: RelatedContent) {
    const message = new DisplayRelatedContent();
    message.contentId = content.element_id;

    switch (content.type) {
      case 'shared':
        message.visibility = RelatedContentVisibility.SHARED;
        break;
      case 'related':
        message.visibility = RelatedContentVisibility.PUBLIC;
        break;
      case 'private':
        message.visibility = RelatedContentVisibility.PRIVATE;
        break;
    }
    this._relatedContentReceived(message);
  }

  /**
   * @function remoteRelatedContentClicked
   * @public
   * @param {RelatedContent} content
   * @returns {void}
   */
    public remoteRelatedContentClicked(content: RemoteRelatedFile): void {
      // Cases we cannot open any modal window, thus we open a new tab
      if (this._browserService.isAndroid() || (this._browserService.isIE() && !this._utilService.isAcrobatReaderInstalled())) {
        this._relatedService.openInNewTab(content.networkURL);
      }
      // Cases we cannot open a new tab (because of browser security constraint),
      // thus we display a notification with a button for the participant to open the shared content by itself
      else if (this._browserService.isIphone() || this._browserService.isIpad()) {
        if(content.type === RelatedContentVisibility.SHARED) {
          this._relatedService.sharedRelatedOpeningSubject.next(content.networkURL);
        } else {
          this._relatedService.openInNewTab(content.networkURL);
        }
      }
      // Cases we can open a modal window
      else {
        this.currentRelatedContent = this._sanitizer.bypassSecurityTrustResourceUrl(content.networkURL);
        this.relatedContentName = content.name;
        if (content.type ===  RelatedContentVisibility.SHARED) {
          this.closableRelatedContent = false;
        } else {
          this.closableRelatedContent = true ;
        }
      }
    }

  /**
   * @function _relatedContentReceived
   * @private
   * @param {DisplayRelatedContent} message
   * @returns {void}
   */
  private _relatedContentReceived(message: DisplayRelatedContent): void {
    const pdfjsPath:string = 'assets/js/pdfjs-1.9.426/web/viewer.html?file=';
    let params:string = '?access_token=' + this._authService.credentials.access_token;

    this.closableRelatedContent = false;
    this.currentRelatedContent = null;

    this.contentsVersions.forEach((content: ContentVersion) => {
      content.relateds.forEach((relatedContent: RelatedContent) => {
        if (relatedContent.element_id == message.contentId) {
          let url:string = relatedContent.resource_url + params;
          this.relatedContentName = relatedContent.name;
          // Checking if the related content allows a close button

          if (message.visibility === RelatedContentVisibility.PUBLIC) {
            this.closableRelatedContent = true;
          }
          // Checking if the related content is an html content
          this._isHtmlForm = (relatedContent.type === RelatedContentVisibility.SHARED && relatedContent.format === 'text/html');
          // Adding some parameter for form files
          if (this._isHtmlForm) {
              params += '&sessionID=' + this.session.id + '&userID=' + this._authService.credentials.user.uid + '&dspSubmit=true';
          }
          // Adding some parameter for PDF files
          else if (relatedContent.format === 'application/pdf') {
              // Does not work when file is from a different origin than the viewer's
              // Default browser's PDF viewer will be used (size issue on iOS)

              // url = pdfjsPath + url;
          }

          this.presentationActionsService.relatedContentClicked();
          this.currentRelatedContent = this._sanitizer.bypassSecurityTrustResourceUrl(url);
          // hack for dev env => to solve with a docker container
          /* if (!environment.production) {
             this.currentRelatedContent = this.sanitizer.bypassSecurityTrustResourceUrl('assets/test/test.html');
          }*/
        }
      });
    });
  }

    /**
     * @function hideRelatedContentAction
     * @public
     * @param {HideRelatedContent} related
     * @returns {void}
     */
    public hideRelatedContentAction(related: any): void {
      this.currentRelatedContent = null;
     /* if (this.isOCE) {
        this.messagingService.mandatoryFileNotification(this.relatedContentName, MandatoryFileStatus.CLOSED);
      } */
    }

    /**
     * @function _hideRelatedContentReceived
     * @private
     * @param {HideRelatedContent} message
     * @returns {void}
     */
    private _hideRelatedContentReceived(message: HideRelatedContent): void {
      this.currentRelatedContent = null;
    }

    /**
     * @description event listener to the  form submit (related content)
     * @function loadContentListner
     * @public
     * @param
     * @returns {void}
     */
    public loadContentListner(): void {
      if (this.contentRelatedIFrame && this._isHtmlForm) {
        const submitButton = this.contentRelatedIFrame.nativeElement.contentWindow.document.getElementById('submit_form');
        if (submitButton) {
          submitButton.addEventListener('click', () => {
            // handle submit button of the form related content
            this.messagingService.submitFormRelatedContent(this.session.id);
          });
        } else {
          console.error('Error with form content');
        }
      }
    }

  /**
   * @function _getContent
   * @param {ContentVersion[]} contents
   * @private
   * @returns {void}
   */
    private _getContent(contents: ContentVersion[]): void {
      this.contentsVersions = contents;
      contents.forEach((content: ContentVersion) => {
        this.relatedContents = [
          ...this.relatedContents,
          ...content.relateds.filter((rc: RelatedContent) =>
            rc.type === RelatedContentVisibility.PUBLIC)
        ];
      });
    }

    /**
     * @function getThumbnail
     * @public
     * @param content {ContentVersion}
     * @returns {string}
     */
    public getThumbnail(content: ContentVersion): string {
        if (!content.thumbnail_url || content.thumbnail_url === '') {
            return 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
        }
        return `${content.thumbnail_url}?access_token=${this._authService.credentials.access_token}`;
    }

}
