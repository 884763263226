import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class RelatedService {

    public sharedRelatedOpeningSubject: Subject<string> = new Subject();

    constructor() {}

    /**
     * @function openInNewTab
     * @description
     * @public
     * @param {sting} url
     * @returns {void}
     */
    public openInNewTab(url: string): void {
      const win = window.open(url, '_blank');
      if (win) {
          win.focus();
      }
    }

}
