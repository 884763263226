import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {HeaderService} from '@app/core/shell/header/header.service';
import {HeaderColor} from '@app/shared/models/header_color';
import {FooterService} from '@app/core/shell/footer/footer.service';
import {VersionService} from '@app/shared/service/version.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@env/environment';
import {User} from '@app/shared/models/user';

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

    public version: string = environment.version;
    public showProfile: boolean = false;
    public showCoordinate: boolean = false;
    public showUpdatePwd: boolean = false;
    public modalUrlToLoad: string;
    public isModalDisplayed: boolean = false;
    private _translations: string[] = [];
    public user: User;

    /**
     * @function constructor
     */
    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _headerService: HeaderService,
        private _translateService: TranslateService,
        private _footerService: FooterService,
        private _versionService: VersionService
    ) {
        this._initTranslations();
        this._displayVersion();
        this._router.events.subscribe( (event: any) => {
            if (event instanceof NavigationEnd) {
                // external user may not access setting page
                this._authenticationService.isExternalUser().then((bool: boolean) => {
                    if (bool && event.url === '/setting') {
                        this._router.navigate(['/home']);
                    }
                });
            }
        });
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this._headerService.changeTitle(this._translations['setting']);
        this._headerService.backButtonVisibility(true);
        this._headerService.changeHeaderBackgroundColor(HeaderColor.SETTING);
        this._footerService.changeVisibility(true);
        this._headerService.editProfileButtonChanged.subscribe((show: boolean) => {
            this.displayCoordinates();
        });
        this.user = this._authenticationService.credentials.user;
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Setting')
            .subscribe((trans: string) => this._translations['setting'] = trans);
        this._translateService.get('My profile')
            .subscribe((trans: string) => this._translations['my_profile'] = trans);
        this._translateService.get('Change password')
            .subscribe((trans: string) => this._translations['change_password'] = trans);
    }

    /**
     * @function displaySetting
     * @description
     * @public
     * @returns {void}
     */
    public displaySetting(): void {
        this._headerService.changeTitle(this._translations['setting']);
        this._headerService.editProfileButtonVisibility(false);
        this._footerService.changeVisibility(true);
        this.showProfile = false;
        this.showUpdatePwd = false;
    }

    /**
     * @function displayProfile
     * @description
     * @public
     * @returns {void}
     */
    public displayProfile(): void {
        this._headerService.editProfileButtonVisibility(true);
        this.showCoordinate = false;
        this.showProfile = true;
    }

    /**
     * @function displayUpdatePwd
     * @description
     * @public
     * @returns {void}
     */
    public displayUpdatePwd(): void {
        this.showUpdatePwd = true;
    }

    /**
     * @function displayCoordinates
     * @description
     * @public
     * @returns {void}
     */
    public displayCoordinates(): void {
        this._headerService.editProfileButtonVisibility(false);
        this.showProfile = false;
        this.showCoordinate = true;
    }

    /**
     * @function logout
     * @description Logout and redirect the user to the thanks page
     * @public
     * @returns {void}
     */
    public logout(): void {
        this._authenticationService.logout()
            .subscribe(() => this._router.navigate(['/login'], {replaceUrl: true}));
    }

    /**
     * @function displayModal
     * @description
     * @public
     * @param {string} type
     * @return {void}
     */
    public displayModal(type: string): void {
        const docsPath = 'assets/js/pdfjs-1.9.426/web/viewer.html?file=/assets/docs/';
        let path = '';
        switch (type) {
            case 'termsOfService':
                path = docsPath + 'terms_of_service.pdf';
                break;
            case 'privacyPolicy':
                path = docsPath + 'privacy_policy.pdf';
                break;
            case 'changePassword':
                path = environment.changePwdUrl + '?access_token=' + this._authenticationService.credentials.access_token;
                break;
        }
        this.modalUrlToLoad = path;
        this.isModalDisplayed = true;
    }

    /**
     * @function openInNewTab
     * @description
     * @public
     * @param {string} type
     * @return {void}
     */
    public openInNewTab(type: string): void {
        const docsPath = '/assets/docs/';
        let path = '';
        switch (type) {
            case 'termsOfService':
                path = docsPath + 'terms_of_service.pdf';
                break;
            case 'privacyPolicy':
                path = docsPath + 'privacy_policy.pdf';
                break;
        }
        window.open(path,'_blank');
    }


    /**
     * @function closeModal
     * @description
     * @public
     * @returns {void}
     */
    public closeModal(): void {
        this.modalUrlToLoad = 'about:blank';
        this.isModalDisplayed = false;
    }

    /**
     * @function cancelFullScreen
     * @description
     * @public
     * @returns {void}
     */
    public cancelFullScreen(): void {
        const elem: any = document;
        if (elem.exitFullScreen) { return elem.exitFullScreen(); }
        else if (elem.webkitCancelFullScreen) { return elem.webkitCancelFullScreen(); }
        else if (elem.msExitFullscreen) { return elem.msExitFullscreen(); }
        else if (elem.mozCancelFullScreen) { return elem.mozCancelFullScreen(); }
    }

    /**
     * @function _displayVersion
     * @description
     * @private
     * @returns {void}
     */
    private _displayVersion() {
        this._versionService.getVersion()
            .subscribe((version: string) => {
                this.version = version;
            });
    }

}
