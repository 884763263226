import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild, ViewChildren
} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {filter, finalize} from 'rxjs/operators';
import {Notification} from '@app/core/messaging/notification';
import {User} from '@app/shared/models/user';
import {Participant} from '@app/shared/models/participant';
import {Session, SessionState} from '@app/shared/models/session';

import {MessageInterface} from '@app/core/messaging/message';

import {AuthenticationService, Credentials} from '@app/core/authentication/authentication.service';
import {HeaderService} from '@app/core/shell/header/header.service';
import {FooterService} from '@app/core/shell/footer/footer.service';
import {UserService} from '@app/shared/service/user.service';
import {SessionService} from '@app/shared/service/session.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {HeaderColor} from '@app/shared/models/header_color';
import {Subscription} from 'rxjs';
import {UtilService} from '@app/shared/service/util.service';
import {BrowserService} from '@app/shared/service/browser.service';
import {I18nService} from '@app/core/i18n.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-sessions-list',
    templateUrl: './sessions-list.component.html',
    styleUrls: ['./sessions-list.component.scss']
})
export class SessionsListComponent implements OnInit, AfterViewInit, DoCheck {

    /**
     * Data members
     */
    @ViewChild('sessionListContainer') sessionListContainer: ElementRef;
    @ViewChild('searchBar') searchBar: ElementRef;
    @ViewChildren('sessionListHeader') set content(content: any) {
      if (content.length > 0) {
        // hide session list header if no session is displayed
        setTimeout(() => {
          content.forEach( (elt: any) => {
            if (elt.nativeElement.nextElementSibling.innerText === '' ||
              elt.nativeElement.nextElementSibling.innerText === '  '/* IE11 empty element equal to '  ' */) {
              elt.nativeElement.hidden = true;
            } else {
              elt.nativeElement.hidden = false;
            }
          });
        }, 0);
      }
    }
    @Output() onSessionSelected = new EventEmitter<Session>();
    public isLoading: boolean;
    public sessionList: any[];
    public searchBarEnabled = false;
    public search: string;
    public showProfile: boolean = false;
    private _credentials: Credentials;
    private _translations: string[] = [];
    private _HIDE: boolean = true;
    private initConnection: boolean = false;
    private _notificationSubscription: Subscription;
    public locale: string;

    /**
     * @function constructor
     * @param {AuthenticationService} _authenticationService
     * @param {SessionService} _sessionService
     * @param {HeaderService} _headerService
     * @param {FooterService} _footerService
     * @param {UserService} _userService
     * @param {MessagingService} _messagingService
     * @param {UtilService} _utilService
     * @param {BrowserService} _browserService
     * @param {I18nService} _i18nService
     */
    constructor(
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _headerService: HeaderService,
        private _footerService: FooterService,
        private _userService: UserService,
        private _sessionService: SessionService,
        private _messagingService: MessagingService,
        private _utilService: UtilService,
        private _browserService: BrowserService,
        private _i18nService: I18nService
    ) {
        this._initTranslations();
        this.locale = this._i18nService.language;
        this._credentials = this._authenticationService.credentials;
        this._notificationSubscription = this._messagingService.Messages
            .pipe(filter((message: MessageInterface) => message instanceof Notification))
            .subscribe((message: Notification) => {
                this._handleSessionNotification(message);
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this._resizeSessionsListContainer();
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.isLoading = true;
        this._headerService.changeTitle(this._translations['sessions']);
        this._headerService.changeVisibilty(true);
        this._headerService.backButtonVisibility(this._HIDE);
        this._authenticationService.isExternalUser().then((bool: boolean) => {
            const showFooter: boolean = !bool;
            this._footerService.changeVisibility(showFooter);
        });
        this._headerService.changeHeaderBackgroundColor(HeaderColor.DEFAULT);
        this._sessionService.getSessions()
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(
                (result: Session[]) => {
                    this.sessionList = this._reformatSessionList(result);
                    // Auto-selecting the first (and sole) session if the user is an external
                    this._authenticationService.isExternalUser().then((bool: boolean) => {
                        if (bool) {
                            // case when external user try to log to an deleted session
                            if (result.length === 0) {
                                this._utilService._doLogout();
                                return;
                            }
                            this.selectSession(result[0]);
                            return;
                        }
                    });
                });
        this._messagingService.Messages.pipe(filter((message: MessageInterface) => message instanceof Notification))
          .subscribe((message: any) => {
              this.sessionList.forEach(sessionList => {
                   const isSession = _.find(sessionList.sessions, (session: Session) =>
                     session.id === message.sessionId );
                   if (isSession) { this._updateSessionStatus(isSession, message.state); }
                  });
              });
    }

    ngOnDestroy() {
        this._notificationSubscription.unsubscribe();
    }

    /**
     * @description reset connection (
     * @function ngDoCheck
     */
    ngDoCheck() {
      // check if connection is lost accidentally to init connection.
        setTimeout(() => {
          if (this._messagingService.isConnection() && !this._messagingService.isConnected() && !this.initConnection) {
            this._messagingService.resetConnection();
            this.initConnection = true;
        }}, 1000);
    }

    /**
     * @function nbAfterViewInit
     */
    ngAfterViewInit() {
        this._resizeSessionsListContainer();
    }

    /**
     * @function selectSession
     * @public
     * @param {Session} session
     * @returns {void}
     */
    public selectSession(session: Session): void {
        this.onSessionSelected.emit(session);
    }

    /**
     * @function getSpeakerName
     * @description
     * @public
     * @param {Session} session
     * @returns {string}
     */
    public getSpeakerName(session: Session): string {
        const speaker = session.members[0];
        let speakerName = speaker.memberDN;
        if (speaker.hasOwnProperty('first_name') && speaker.hasOwnProperty('last_name')) {
            speakerName = speaker.first_name + ' ' + speaker.last_name;
        }
        return speakerName;
    }

    /**
     * @function getSpeakerStatus
     * @description
     * @public
     * @param {Session} session
     * @returns {boolean}
     */
    public getSpeakerStatus(session: Session): boolean {
        const speaker = session.members[0];
        if (speaker.hasOwnProperty('isOnline')) {
            return speaker['isOnline'];
        }
        return false;
    }

    /**
     * @function filterAllSessions
     * @description
     * @public
     * @returns {void}
     */
    public filterAllSessions(): void {
        this.search = null;
    }

    /**
     * @function filterMySessions
     * @description
     * @public
     * @returns {void}
     */
    public filterMySessions(): void {
        this.search = this._credentials.username;
    }

    /**
     * @function toggleSearchBar
     * @description
     * @public
     * @returns {void}
     */
    public toggleSearchBar(): void {
        this.searchBarEnabled = !this.searchBarEnabled;
    }

    /**
     * @function _resizeSessionsListContainer
     * @description
     * @private
     * @returns {void}
     */
    private _resizeSessionsListContainer(): void {
        if (document.getElementById('header') && document.getElementById('footer')) {
            this._goResizeSessionsListContainer();
        } else {
            // Handling to a timeout because #header is not accessible right now in ngAfterViewInit
            setTimeout(() => this._goResizeSessionsListContainer(), 300);
        }
    }

    /**
     * @function _goResizeSessionsListContainer
     * @description
     * @private
     * @returns {void}
     */
    private _goResizeSessionsListContainer(): void {
        const header = document.getElementById('header');
        const footer = document.getElementById('footer');
        if (!footer) {
            return;
        }
        const searchBarHeight = this.searchBar.nativeElement.offsetHeight;
        let windowHeight = 0;
        this._browserService.isIos() ? windowHeight = window.document.body.clientHeight : windowHeight = window.innerHeight;
        this.sessionListContainer.nativeElement.style.height = (windowHeight - (header.offsetHeight + searchBarHeight + footer.offsetHeight)) + 'px';
    }

    /**
     * @function _reformatSessionList
     * @description
     * @private
     * @param {Session[]} sessions
     * @returns {any}
     */
    private _reformatSessionList(sessions: Session[]): any {
        const newSessionsFormat: any = [];
        sessions.forEach((session: Session) => {
            const day: string = this._getDayFromDate(session.expectedStartDate);
            const dayHeaderAlreadyExisting = newSessionsFormat.filter((item: any) => item.day === day);
            // Removing my own session
            // @TODO: remove this condition when presenter mode will be implemented
            if (this._authenticationService.credentials &&
                session.members[0].memberDN !== this._authenticationService.credentials.username
            ) {
                if (!dayHeaderAlreadyExisting.length) {
                    newSessionsFormat.push({day: day, sessions: [this._reformatSessionWithSpeakerNames(session)]});
                } else {
                    const daySessions = dayHeaderAlreadyExisting[0].sessions;
                    daySessions.push(this._reformatSessionWithSpeakerNames(session));
                    // Sorting sessions of each day by date DESC
                    daySessions.sort((a: any, b: any) =>
                      new Date(this._cleanDate(b.expectedStartDate)).getTime() - new Date(this._cleanDate(a.expectedStartDate)).getTime());
                }
            }
        });
        // Sorting each dayHeader by day DESC
        newSessionsFormat.sort((a: any, b: any) => new Date(b.day).getTime() - new Date(a.day).getTime());
        return newSessionsFormat;
    }

    /**
     * @function _sessionAlreadyExists
     * @description Tell if a session already exists into the sessionList
     * @private {Session} sessionToCheck
     * @returns {boolean}
     */
    private _sessionAlreadyExists(sessionToCheck: Session): boolean {
        let res = false;
        this.sessionList.forEach((item: any) => {
            item.sessions.forEach((session: Session) => {
                if (session.id === sessionToCheck.id) {
                    res = true;
                }
            });
        });
        return res;
    }

    /**
     * @function _addSessionToSessionsList
     * @description add a new session into the sessionlist
     * @private {Session} newSession
     * @returns {void}
     */
    private _addSessionToSessionsList(newSession: Session): void {
        const newSessionDay: string = this._getDayFromDate(newSession.expectedStartDate);
        const foundDayHeader = this.sessionList.find((item: any) => item.day === newSessionDay);
        const foundDaySessions = foundDayHeader && foundDayHeader.hasOwnProperty('sessions') ? foundDayHeader['sessions'] : [];
        // Just adding the session
        if (foundDaySessions.length) {
            foundDaySessions.push(this._reformatSessionWithSpeakerNames(newSession));
            // Sorting sessions of each day by date DESC
            this._sortSessionsInDayHeader(foundDaySessions);
        }
        // Or adding the dayHeader and then the session into it
        else {
            this.sessionList.push({day: newSessionDay, sessions: [this._reformatSessionWithSpeakerNames(newSession)]});
            // Sorting each dayHeader by day DESC
            this._sortSessionsList();
        }
    }

    /**
     * @function _removeSessionFromSessionsList
     * @description remove a new session from the sessionlist
     * @private {Notification} notification
     * @returns {void}
     */
    private _removeSessionFromSessionsList(notification: Notification): void {
        this.sessionList.forEach((item: any) => {
            item.sessions.forEach((session: Session, index: number) => {
                if (session.id === notification.sessionId) {
                    const day = this._getDayFromDate(session.expectedStartDate);
                    // Removing the session from the good dayHeader
                    item.sessions.splice(index, 1);
                    if (!item.sessions.length) {
                        // Removing the dayHeader if there is no session anymore into it
                        this.sessionList = this.sessionList.filter((item2: any) => item2.day !== day);
                    }
                }
            });
        });
    }

    /**
     * @function _updateSessionInSessionsList
     * @description update an existing session in the sessionlist
     * @private {Session} sessionToUpdate
     * @returns {void}
     */
    private _updateSessionInSessionsList(updatedSession: Session): void {
        this.sessionList.forEach((item: any) => {
            item.sessions.forEach((session: Session, index: number) => {
                if (session.id === updatedSession.id) {
                    const actualDay = this._getDayFromDate(session.expectedStartDate);
                    const newDay = this._getDayFromDate(updatedSession.expectedStartDate);
                    // If the day of the new expectedStartDate is the same as the actual session info, only updating the session
                    if (newDay === actualDay) {
                        item.sessions[index] = updatedSession;
                    }
                    // Otherwise
                    else {
                        // If the new dayHeader does not exist yet, creating it and push the session into it
                        const dayHeaderAlreadyExists = this.sessionList.filter((item2: any) => item2.day === newDay).length;
                        if (!dayHeaderAlreadyExists) {
                            this.sessionList.push({day: newDay, sessions: [updatedSession]});
                            // Sorting each dayHeader by day DESC
                            this._sortSessionsList();
                        }
                        // Otherwise, pushing only the new session into the existing dayHeader
                        else {
                            const dayHeaderToUpdate = this.sessionList.find((item2: any) => item2.day === newDay);
                            if (dayHeaderToUpdate !== undefined) {
                                dayHeaderToUpdate.sessions.push(updatedSession);
                                // Sorting sessions of each day by date DESC
                                this._sortSessionsInDayHeader(dayHeaderToUpdate.sessions);
                            }
                        }
                        // Removing the former session from its dayHeader
                        item.sessions.splice(index, 1);

                        // If the former session's dayHeader has no longer any session, removing it
                        if (!item.sessions.length) {
                            this.sessionList = this.sessionList.filter((item3: any) => item3.day !== actualDay);
                        }
                    }
                }
            });
        });
    }

    /**
     * @function _getDayFromDate
     * @description
     * @private
     * @param {string} date
     * @returns {string}
     */
    private _getDayFromDate(date: string) {
        return new Date(date.replace(/([+\-]\d\d)(\d\d)$/, '$1:$2')).toISOString().split('T')[0];
    }
    /**
     * @function _cleanDate
     * @description delete timezone from date string (new Date function don't format date with timezone in safari and IE)
     * @private
     * @param {string} date
     * @returns {string}
     */
    private _cleanDate(date: string) {
      return date.replace(/^([^:]*.[^:]*):.*$/, '$1');
    }

    /**
     * @function _sortSessionsList
     * @description
     * @private
     * @returns {void}
     */
    private _sortSessionsList(): void {
        this.sessionList.sort((a: any, b: any) => new Date(b.day).getTime() - new Date(a.day).getTime());
    }

    /**
     * @function _sortSessionsList
     * @description
     * @private
     * @param {Session[]} sessionsToSort
     * @returns {void}
     */
    private _sortSessionsInDayHeader(sessionsToSort: Session[]): void {
        sessionsToSort.sort((a: any, b: any) => new Date(b.expectedStartDate).getTime() - new Date(a.expectedStartDate).getTime());
    }

    /**
     * @function _reformatSessionWithSpeakerNames
     * @description
     * @private
     * @param {Session} session
     * @returns {Session}
     */
    private _reformatSessionWithSpeakerNames(session: Session): Session {
        session.members.forEach((participant: Participant) => {
            if (participant.role !== 'speaker') {
                return;
            }
            // Fetching speaker first name and last name
            this._userService.getUser(participant.memberDN).subscribe(
                (user: User) => {
                    participant.first_name = user.first_name;
                    participant.last_name = user.last_name;
                    // Setting speaker presence //@TODO: to implement
                    participant['isOnline'] = false;
                }
            );
        });
        return session;
    }

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Sessions')
            .subscribe((trans: string) => {
                this._translations['sessions'] = trans;
            });
    }

    /**
     * @function _handleSessionNotification
     * @description
     * @private
     * @param {Notification} message
     * @returns {void}
     */
    private _handleSessionNotification(message: Notification): void {
        switch (message.state) {
            case SessionState.Scheduled:
                this._sessionService.getSession(message.sessionId)
                    .subscribe((session: Session) => {
                        // Adding a new session
                        if (!this._sessionAlreadyExists(session)) {
                            this._addSessionToSessionsList(session);
                        }
                        // Updating an existing session
                        else {
                            this._updateSessionInSessionsList(session);
                        }
                    });
                break;
            case SessionState.Canceled:
                this._removeSessionFromSessionsList(message);
                break;
        }
    }

    /**
     * @function isSessionStarted
     * @description
     * @public
     * @param {Session} session
     * @returns {boolean}
     */
    public isSessionStarted(session: Session): boolean {
        return session.sessionState === SessionState.Started;
    }

    /**
     * @function updateSessionStatus
     * @description
     * @private
     * @param {Session} session
     * @param {SessionState} state
     * @returns {void}
     */
    private _updateSessionStatus(session: Session, state: SessionState): void {
        session.sessionState = state;
    }
}
