import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';

import {TranslateService} from '@ngx-translate/core';
import {FlashService} from '@app/shared/flash/flash.service';
import {UserService} from '@app/shared/service/user.service';
import {HeaderService} from '@app/core/shell/header/header.service';
import {FooterService} from '@app/core/shell/footer/footer.service';

@Component({
    selector: 'app-recover-pwd',
    templateUrl: './recover-pwd.component.html',
    styleUrls: ['./recover-pwd.component.scss']
})
export class RecoverPwdComponent implements OnInit {

    private _translations: string[] = [];
    public recoverForm: FormGroup;

    /**
     * @function Constructor
     * @param {Router} _router
     * @param {HeaderService} _headerService
     * @param {FooterService} _footerService
     * @param {FormBuilder} _fb
     * @param {UserService} _userService
     * @param {TranslateService} _translateService
     * @param {FlashService} _flashService
     */
    constructor(
        private _headerService: HeaderService,
        private _footerService: FooterService,
        private _fb: FormBuilder,
        private _userService: UserService,
        private _translateService: TranslateService,
        private _flashService: FlashService
    ) {
        this._initTranslations();
        this.recoverForm = this._fb.group({
            username: [null]
        });
    }


    /**
     * @function ngOnInit
     */
    ngOnInit() {}

    /**
     * @function _initTranslations
     * @description
     * @private
     * @returns {void}
     */
    private _initTranslations(): void {
        this._translateService.get('Recover password')
            .subscribe((trans: string) => this._translations['recover_password'] = trans);
        this._translateService.get('Your request has been successfully submitted')
            .subscribe((trans: string) => this._translations['recover_pwd_success'] = trans);
        this._translateService.get('An error occured during the submission')
            .subscribe((trans: string) => this._translations['recover_pwd_error'] = trans);
    }

    /**
     * @function updatePwd
     * @description
     * @public
     * @returns {void}
     */
    public submit(): void {
        const username = this.recoverForm.get('username').value;
        const data = { username: username };
        if (username && username !== '') {
            this._userService.recoverPwd(data)
                .subscribe(res => {
                    this.recoverForm.get('username').markAsPristine();
                    this.recoverForm.setValue({ username: null });
                    this._flashService.success(this._translations['recover_pwd_success']);
                }, error => {
                    this._flashService.error(this._translations['recover_pwd_error']);
                });
        }
    }

}
