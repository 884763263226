import {Injectable} from '@angular/core';
import {Logger} from '@app/core/logger.service';

const logger = new Logger('XMPP Stanza Validator');

@Injectable()
export class XmppStanzaValidatorService {
    
    public validateChatBody(body : any) {

            if(typeof body.chat == "undefined"){
                logger.warn("Chat Message : Missing required parameter : chat");
                return;
            }

            if(typeof body.chat.content == "undefined"){
                logger.warn("Chat Message : Missing required parameter : chat.content");
                return;
            }

            if(typeof body.chat.timestamp == "undefined"){
                logger.warn("Chat Message : Missing required parameter : chat.timestamp");
                return;
            }
        
    }
    
    public validateDisplayRelatedContentBody(body : any) {
        if(typeof body.ie == "undefined"){
            logger.warn("Display Related ContentMessage : Missing required parameter : ie");
            return;
        }
        if(typeof body.ie.p == "undefined"){
            logger.warn("Display Related ContentMessage : Missing required parameter : ie.p");
            return;
        }
        if(typeof body.ie.p == "undefined"){
            logger.warn("Display Related ContentMessage : Missing required parameter : ie.r");
            return;
        }
        
    }
    
    public validateDrawActionBody(body: any) {
        if(typeof body.ie == "undefined"){
            logger.warn("Draw Action Message : Missing required parameter : ie");
            return;
        }

        if(typeof body.ie.a == "undefined"){
            logger.warn("Draw Action Message : Missing required parameter : ie.a");
            return;
        }

        if(typeof body.ie.x == "undefined" && body.ie.a !== 3){
            logger.warn("Draw Action Message : Missing required parameter : ie.x");
            return;
        }

        if(typeof body.ie.y == "undefined" && body.ie.a !== 3){
            logger.warn("Draw Action Message : Missing required parameter : ie.y");
            return;
        }
    }

    public validateHideRelatedContentBody(body : any) {
       return;
    }
    
    public validateCommentBoxBody(body: any) {
        if(typeof body.ie == "undefined"){
            logger.error("Load Slide Message : Missing required parameter : ie");
        }
        if(typeof body.ie.p == "undefined"){
            logger.error("Load Slide Message : Missing required parameter : p");
        }
        if(typeof body.ie.p.CommentBox == "undefined"){
            logger.error("Load Slide Message : Missing required parameter : p.CommentBox");
        }
    }

    public validateLoadSlideBody(body: any) {
        if(typeof body.ie == "undefined"){
            logger.warn("Load Slide Message : Missing required parameter : ie");
        }
    }

    public validateLoadSlideBodyOCE(body: any) {
        if(typeof body.PresentationSequenceLoadCommand == "undefined"){
            logger.warn("Load Slide Message : Missing required parameter : PresentationSequenceLoadCommand");
        }
    }

    public validateNotificationBody(body : any) {

        if(typeof body == "undefined"){
            logger.warn("Notification Message : Missing required parameter : body");
        }
        if(typeof body.notify == "undefined"){
            logger.warn("Notification Message : Missing required parameter : notify");
        }

        if(typeof body.notify.state == "undefined"){
            logger.warn("Notification Message : Missing required parameter : notify.state");
        }

        if(typeof body.notify.session == "undefined"){
            logger.warn("Notification Message : Missing required parameter : notify.session");
        }
    }

    public validatePresenceBody(body : any) {
        return;
    }

    public validateSurveyNotificationBody(body : any) {

    }

    public validateTouchGestureBody(body: any) {

        if(typeof body == "undefined" || !body){
            logger.warn("Touch Gesture Message : Missing required parameter : ie");
            return;
        }

        if(typeof body.ie == "undefined"){
            logger.warn("Touch Gesture Message : Missing required parameter : ie");
            return;
        }

        if(typeof body.ie.x == "undefined"){
            logger.warn("Touch Gesture Message : Missing required parameter : ie.x");
            return;
        }

        if(typeof body.ie.y == "undefined"){
            logger.warn("Touch Gesture Message : Missing required parameter : ie.y");
            return;
        }

        if(typeof body.ie.p == "undefined"){
            logger.warn("Touch Gesture Message : Missing required parameter : ie.a");
            return;
        }
    }
    
}

