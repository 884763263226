import {Message} from "@app/core/messaging/message";
import {RelatedContentVisibility} from '@app/core/messaging/display-related-content';

export class RemoteRelatedFile extends Message {

  name: string;
  networkURL: string;
  type: RelatedContentVisibility;
  action: RemoteRelatedFileAction;
}

export enum MandatoryFileStatus {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED'
}

export enum RemoteRelatedFileAction {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE'
}
