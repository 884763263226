export const environment = {
    env: 'preprod-new',
    proxyConfig: false,
    production: true,
    version: '',
    serverUrl: 'https://bo.preprod.ocedetailing.com/kis',
    serverApiUrl: 'https://bo.preprod.ocedetailing.com/api',
    serverApiPrefUrl: 'https://bo.preprod.ocedetailing.com/preferences',
    serverApiOTUrl: 'https://bo.preprod.ocedetailing.com/tokbox',
    resetPwdUrl: 'https://bo.preprod.ocedetailing.com/resetting/request',
    changePwdUrl: 'https://bo.preprod.ocedetailing.com/common-manager/#/change-password',
    authUrl: 'https://bo.preprod.ocedetailing.com/api/oauth',
    unzipContenUrl: 'https://guest.preprod.ocedetailing.com/contents/unzip-content.php',
    contentUrl: 'https://guest.preprod.ocedetailing.com/contents',
    authClientId: '4jej02k21p4w8w48kww08gsg8gwckwsgkcg48oocgwkkwoso4k',
    sdkJsPlayer: 'assets/js/sdk.min.js',
    webSocketUrl: 'wss://xmpp-bo.preprod.ocedetailing.com:443/wss/',
    defaultLanguage: 'en-US',
    supportedLanguages: [
        'en-US',
        'fr-FR',
        'zh-CN'
    ],
    opentokApiKey : '46230622'
};
