export interface User {
    id : number,
    uid: string,
    email: string,
    first_name : string,
    last_name : string,
    licenses_valid : any[], //todo => create class
    roles_bo : UserRole[],
    token_name : string,
    client: UserClient,
    user_groups: UserGroup[],
    phone: string,
    phone_country: string;
}

export enum UserRole {
    INVITED = 'urn:isharing:right:session:assist:invited',
    JOIN_AUTO = 'urn:isharing:right:session:join:auto'
}

export interface UserClient {
    client_id: string;
    name: string;
}

export interface UserGroup {
  name: string;
}
