import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Logger} from '../logger.service';
import {AuthenticationService} from './authentication.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private _router: Router,
              private _authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this._authenticationService.isAuthenticated()) {
      // external user can't access setting page
      this._authenticationService.isExternalUser().then((bool: boolean) => {
          if (bool && state.url === '/settings') {
              this._router.navigate(['/sessions']);
              return false;
          }
      });
      console.log(state);
      return true;
    }

    log.debug('Not authenticated, redirecting...');
    this._router.navigate(['/login'], { replaceUrl: true });
    return false;
  }

}
