import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Flash, FlashType} from '@app/shared/flash/flash';

@Injectable()
export class FlashService {

    private _flashes: Subject<Flash> = new Subject<Flash>();

    constructor() {}

    public info(message: string, millseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.INFO;
        flash.duration = millseconds;
        flash.foreground = isForeground;

        this._flashes.next(flash);
    }

    public success(message: string, millseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.SUCCESS;
        flash.duration = millseconds;
        flash.foreground = isForeground;

        this._flashes.next(flash);
    }

    public warning(message: string, millseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.WARNING;
        flash.duration = millseconds;
        flash.foreground = isForeground;

        this._flashes.next(flash);
    }


    public error(message: string, millseconds: number = 5000, isForeground: boolean = false): void {
        const flash = new Flash();
        flash.message = message;
        flash.type = FlashType.ERROR;
        flash.duration = millseconds;
        flash.foreground = isForeground;

        this._flashes.next(flash);
    }

    get flashes(): Subject<Flash> {
        return this._flashes;
    }
}
