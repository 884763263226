import {Injectable} from "@angular/core";
import {ReplaySubject, Subject, Subscription} from 'rxjs';
import {AbstractMessageProvider} from "@app/core/messaging-provider/abstract-message-provider";
import {Logger} from "@app/core/logger.service";
import {MessageInterface} from "@app/core/messaging/message";
import {AuthenticationService} from "@app/core/authentication/authentication.service";
import {logger} from "codelyzer/util/logger";
import {ScreenSize} from '@app/core/messaging/screen-size';
import {SlideLoadedEvent} from '@app/core/messaging/slide-loaded-event';
import {CurrentSlide} from '@app/core/messaging/current-slide';

const log = new Logger('Messaging Service');

export interface MessagingConnectionStatus {
    connected: boolean;
}

@Injectable()
export class MessagingService {

    private currentChannel: any = null;
    private username: string = null;
    private presenterUid: string = null;
    private _messages: Subject<MessageInterface> = new Subject<MessageInterface>();
    private _replayMessages: ReplaySubject<MessageInterface> = new ReplaySubject<MessageInterface>();
    private logoutSubscription: Subscription;

    constructor(private messageProvider: AbstractMessageProvider, private authService: AuthenticationService) {

        messageProvider.onMessageReceived((message: MessageInterface) => {
            this._messages.next(message);
        });

        messageProvider.onConnected(() => {
            log.info("Connected to messaging system");
            this._connectionStatusChanged.next({connected: true});
        });

        messageProvider.onDisconnect(() => {
            log.info("Disconnected from to messaging system");
            this._connectionStatusChanged.next({connected: false});
        });

        this.logoutSubscription = this.authService.logoutSubject.subscribe(
            () => {
                if (this.currentChannel) {
                    this.leaveRoom(this.currentChannel);
                }
                this.disconnect();
            },
            (err) => logger.error(err)
        )

    }

    private _connectionStatusChanged: Subject<MessagingConnectionStatus> = new Subject();

    get connectionStatusChanged(): Subject<MessagingConnectionStatus> {
        return this._connectionStatusChanged;
    }

    get Messages(): Subject<MessageInterface> {
        return this._messages;
    }

    get ReplayMessages(): ReplaySubject<MessageInterface> {
      return this._replayMessages;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.logoutSubscription.unsubscribe();
    }

    public connect(username: string) {
        this.username = username;
        this.messageProvider.connect();
    }

    public disconnect() {
        this.messageProvider.disconnect();
    }

    public isConnected() {
        return this.messageProvider.isConnected();
    }

    public isConnection() {
        return this.messageProvider.isConnection();
    }

    public resetConnection(callBack?: () => void) {
        return this.messageProvider.resetConnection(callBack);
    }

    public joinChannel(channelId: string, presenterUid: string) {
        this.presenterUid = presenterUid;
        this.currentChannel = channelId;
        this.messageProvider.joinRoom(channelId, this.authService.credentials.username);
    }

    public leaveRoom(channelId: string) {
        const credentials = this.authService.credentials;
        if (credentials && credentials.username) {
            this.messageProvider.leaveRoom(channelId, credentials.username);
        }
    }

    public messageReceived(message: MessageInterface) {
        log.info(message);
        this._messages.next(message);
    }

    public sendMessage(message: MessageInterface) {
        this.messageProvider.sendMessage(message);
    }

    public askForCurrentSlide() {
        this.messageProvider.askForCurrentSlide();
    }

    public changeSlide(slideURI: string , contentVersion: number, contentVersionID: number, size: ScreenSize) {
       this.messageProvider.changeSlide(slideURI, this.authService.credentials.username, contentVersion, contentVersionID, size );
    }

    public sendTouch(eventType: string, currentPositionX: number, currentPositionY: number) {
      this.messageProvider.sendTouch(eventType, currentPositionX, currentPositionY, this.authService.credentials.username);
    }

    public sendVideoAction(eventType: string, index: number, currentTime: number) {
      this.messageProvider.sendVideoAction(eventType, index,  currentTime, this.authService.credentials.username);
    }

    public sendDrawingAction(message: any) {
      this.messageProvider.sendDrawingAction(message);
    }

    public sendEraseDrawingAction() {
      this.messageProvider.sendEraseDrawingAction();
    }

    public askForHand() {
      this.messageProvider.askForHand(this.authService.credentials.username);
    }

    public leaveHand() {
      let presenterUid: string = this.presenterUid.split('@')[0];
      if (presenterUid.indexOf('token_') !== -1) {
          presenterUid = presenterUid.substring(6);
      }
      this.messageProvider.leaveHand(presenterUid);
    }

    // send state of the user content . for the progress bar
    public sendContentState(percent: number) {
        this.messageProvider.sendContentState(percent);
    }

    // send xmpp (submit form of the related content)
    public submitFormRelatedContent(session_id: number) {
        this.messageProvider.submitFormRelatedContent(session_id);
    }

    // send xmpp (submit form of the related content)
    public requestAudioVideoStreaming() {
        this.messageProvider.requestAudioVideoStreaming(this.presenterUid);
    }

    // send xmpp notification for mandatories files
    public mandatoryFileNotification(name: string, status:  string) {
      this.messageProvider.mandatoryFileNotification(name, status);
    }

    // send xmpp notification for slide loading state
    public slideLoadedNotification(slideEvent: SlideLoadedEvent) {
      this.messageProvider.slideLoadedNotification(slideEvent);
    }

    public presentationSlideLoadCommand(slide: CurrentSlide): void {
      this.messageProvider.presentationSlideLoadCommand(slide);
    }

}
