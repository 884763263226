import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from "@env/environment";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Session} from "@app/shared/models/session";
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {Participant} from '@app/shared/models/participant';

@Injectable()
export class SessionService {

  private _speaker: Participant = null;

  constructor(private http: HttpClient) { }

  getSessions(): Observable<Session[]> {
    return this.http.get(environment.serverUrl + "/sessionservice/v2/sessions")
      .pipe(
        map((res) => res['Sessions']['sessionList']),
        map((body : Session[]) => body)
      );
  }

  getSession(sessionsId:number): Observable<Session> {
    return this.http.get(environment.serverUrl + "/sessionservice/v2/sessions/"+sessionsId)
      .pipe(
        map((res) => res['Session']),
        map((body : Session) => body)
      );
  }

  leaveSession(sessionsId:number) {

     const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8'
      })
    };

    return this.http.put(environment.serverUrl + '/sessionservice/sessions/' + sessionsId + '/leave',  {}, httpOptions);
  }

  /**
   * @function setSpeaker
   * @description
   * @public
   * @param {Participant} member
   * @returns {void}
   */
  public setSpeaker(member: Participant): void {
      this._speaker = member;
  }

  /**
   * @function getSpeakerJid
   * @description
   * @public
   * @returns {string}
   */
  public getSpeakerJid(): string {
      return this._speaker.memberDN;
  }

  /**
   * @function isSpeaker
   * @description
   * @public
   * @param {string} jid
   * @returns {boolean}
   */
  public isSpeaker(jid: string): boolean {
      return this._speaker.memberDN === jid;
  }

}
