import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResizableModule } from 'angular-resizable-element';

import { LoaderComponent } from '@app/shared/loader/loader.component';

import { SafeUrlPipe } from '@app/shared/pipes/safeUrl.pipe';
import { SafeHtmlPipe } from '@app/shared/pipes/safeHtml.pipe';
import { FlashComponent } from '@app/shared/flash/flash.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FlashService} from "@app/shared/flash/flash.service";
import {AutofocusDirective} from '@app/shared/directive/AutofocusDirective';
import {ClickOutsideDirective} from '@app/shared/directive/clickOutsideDirective';
import {FilterPipe} from '@app/shared/pipes/filter.pipe';
import {FilterTitlePipe} from '@app/shared/pipes/filterTitle.pipe';
import {IsExpertDirective} from '@app/shared/directive/IsExpertDirective';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ResizableModule
  ],
  declarations: [
    LoaderComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    FlashComponent,
    AutofocusDirective,
    ClickOutsideDirective,
    FilterPipe,
    FilterTitlePipe,
    IsExpertDirective
  ],
  exports: [
    LoaderComponent,
    FlashComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    FilterPipe,
    FilterTitlePipe,
    ResizableModule,
    AutofocusDirective,
    ClickOutsideDirective,
    IsExpertDirective
  ],
  providers: [
      FlashService
  ],
})
export class SharedModule { }
